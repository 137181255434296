var Home = angular.module('HomeCtrl', ['ngIdle']);
Home
.config(['IdleProvider', 'KeepaliveProvider', function(IdleProvider, KeepaliveProvider) {

	// https://github.com/moribvndvs/ng-idle
	// configure Idle settings
	IdleProvider.idle(3600); // in seconds
	IdleProvider.timeout(30); // in seconds
	KeepaliveProvider.interval(60); // in seconds
}])
.run(function(Idle){
	// start watching when the app runs. also starts the Keepalive service by default.
	Idle.watch();
})
.controller('home', ['IdentityFactory', 'toastr', 'SmartXFactory', '$http', '$scope', '$window', '$rootScope', 'USER', 'Dashboard', 'Tabs', 'HCV_storage', 'SweetAlert', 'SMARTX', 'SearchFilters', function(IdentityFactory, toastr, SmartXFactory, $http, $scope, $window, $rootScope, USER, Dashboard, Tabs, HCV_storage, SweetAlert, SMARTX, SearchFilters){

	//  https://smartx.atlassian.net/browse/AS-330
    IdentityFactory.getUser()
    .then(function(res) {
		try
		{
			LogRocket.init('hqtqlf/smartx-advisor-portal', {
				mergeIframes: true
			});
			LogRocket.identify(res.profile.preferred_username, {
				name: res.profile.firstName + ' ' + res.profile.lastName,
				email: res.profile.email
			});
		} catch(e) {
			console.error(e);
		}
    });
	// check to see if smartx-js usable
	// SmartXFactory.ping()
	// .then(function(res){
	// 	var status = res.status ? res.status : (res.success ? res.success : res);
	// 	//var url = res.stats.server;
	// 	if (!status){
	// 		$scope.apiUnreachable = true;
	// 		$scope.apiUnreachableMsg = 'The remote data service is unreachable.';  // Please verify that your network is allowing requests to ' + url;
	// 	}
	// })
	// .catch(function(err){
	// 	console.error(err);
	// });
	
	$scope.$on('IdleStart', function() {
		// the user appears to have gone idle
		console.log('you are idle');
	});

	$scope.$on('IdleWarn', function(e, countdown) {
		// follows after the IdleStart event, but includes a countdown until the user is considered timed out
		// the countdown arg is the number of seconds remaining until then.
		// you can change the title or display a warning dialog from here.
		// you can let them resume their session by calling Idle.watch()
	});

	$scope.$on('IdleTimeout', function() {
		// the user has timed out (meaning idleDuration + timeout has passed without any activity)
		// this is where you'd log them
		// alert('you have timed out');
		IdentityFactory.logout();
	});

	$scope.$on('IdleEnd', function() {
		// the user has come back from AFK and is doing stuff. if you are warning them, you can use this to hide the dialog
	});

	$scope.$on('Keepalive', function() {
		// do something to keep the user's session alive
	});


	jQuery('#hbl-live-chat-wrapper').hide();

	function transformDictionaryToArray(dict){
        var items = [];

        _.each(dict, function(value, key){
            var itemData = {};

            itemData.id = key;
            itemData.name = value;
            itemData.value = value;

            items.push(itemData);
        });

        return items;
    }

	function transformModelCategoriesToArray(categories){
        var items = [];

        _.each(categories, function(category, key){
            var itemData = {};

            itemData.id = key;
            itemData.name = category.name;
            itemData.classification = category.related_model_types.join(', ');
            itemData.value = category.name;
            itemData.selected = false;

            items.push(itemData);
        });

        return items;
    }

	SearchFilters.loadSearchFilterOptions()
	.then(function(response){

		response = response.data;

		SearchFilters.fields = response.fields;

        SearchFilters.fund_style_box_dictionary = response.fund_style_box;
        SearchFilters.fundStyleBox = transformDictionaryToArray(response.fund_style_box);

        SearchFilters.secondary_model_type_dictionary = response.secondary_model_type;
        SearchFilters.secondaryModelType = transformDictionaryToArray(response.secondary_model_type);

				SearchFilters.model_category_2_dictionary = _.mapObject(response.model_category_2, function(category){ return category.name; });
				SearchFilters.modelCategory2 = transformModelCategoriesToArray(response.model_category_2);

        //SearchFilters.model_category_dictionary = response.model_category;
        //SearchFilters.modelCategory = transformDictionaryToArray(response.model_category);

        SearchFilters.account_compatibility_dictionary = response.account_compatibility;
        SearchFilters.accountCompatibility = transformDictionaryToArray(response.account_compatibility);

        // rename requested by Alex T.
        SearchFilters.accountCompatibility = SearchFilters.accountCompatibility.map(function(item){
					if (item.name === 'IRA Compatible') item.name = 'IRA/Cash Account Compatible';
					return item;
        });

        SearchFilters.fund_type_dictionary = response.fund_type;
        SearchFilters.fundType = transformDictionaryToArray(response.fund_type);

        SearchFilters.regions_dictionary = response.regions;
        SearchFilters.regions = transformDictionaryToArray(response.regions);
		
				SearchFilters.asset_class_dictionary = response.asset_class;
        SearchFilters.assetClass = transformDictionaryToArray(response.asset_class);

        SearchFilters.market_capitalization_dictionary = response.market_capitalization;
        SearchFilters.marketCapitalization = transformDictionaryToArray(response.market_capitalization);

        SearchFilters.sector_focus_dictionary = response.sector_focus;
        SearchFilters.sectorFocus = transformDictionaryToArray(response.sector_focus);

        SearchFilters.portfolio_construction_dictionary = response.portfolio_construction;
        SearchFilters.portfolioConstruction = transformDictionaryToArray(response.portfolio_construction);
    });


	if ( angular.isDefined(SMARTX) && SMARTX === null ){

		var sweetHTML = '<p style="color:black; font-size: 1.5em;">Would you like to receive daily performance updates?</p>' +
					'<br/>' +
					'<div class="col-xs-12 sa-button-container">' +
						'<div class="col-xs-12">' +
							'<button class="confirm" style="width: 100%; background-color:#8CD4F5 !important; padding: 1.5em;">Yes! Let\'s do it.</button>' +
						'</div>' +
						'<div class="col-xs-12">' +
							'<span class="pull-right">' +
								'<button class="no-padding" style="background: none;"><a href="#" style="font-size: 12px;">No thanks.</a></button>' +
							'</span>' +
						'</div>' +
					'</div>';

		SweetAlert.swal({
				title: '',
				type: 'info',
				text: sweetHTML,
				html: true,
				showConfirmButton: false
			},

			function(isConfirm){

				if (isConfirm) {

					// update the profile field to yes
					Dashboard.updateDailyEmailOptIn(1)
					.then(function(){
						//console.log(res);
					});

				} else {

					// update the profile field to false
					Dashboard.updateDailyEmailOptIn(0)
					.then(function(){
						//console.log(res);
					});
				}
			}
		);
	}

	//Onload Dialog Message
	if ( angular.isDefined(USER.show_dashboard_dialog) && USER.show_dashboard_dialog === "1" ){

		SweetAlert.swal({
				title: USER.dashboard_dialog_title,
				text: USER.dashboard_dialog_message,
				type: 'info'
			});
	}

	$rootScope.timerCount = 0;

}])
.controller('FooterCtrl', [ '$scope', function($scope) {
	$scope.year = moment().year();
	$scope.version = typeof APP_VERSION !== 'undefined' ? APP_VERSION : ''; 
}]);
