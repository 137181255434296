


angular
	.module('AllocationHoldings', [])
	.controller('AllocationHoldingsCtrl', function($scope, Dashboard, $rootScope){

		var vm = this;
		var parentTab = $scope.tab;

    // console.log(parentTab);
    
    vm.accounts = Dashboard.getUserAccounts();

		vm.setAccount = setAccount;
		$scope.th_account = {
			selected: undefined
    };

		init();

		function init(){
      vm.allocationId = parentTab.data.allocation.id;/*{
        id: '941a0d6f-7266-40d6-9d65-3b3db658ec17',
        type: 'managedPortfolio'
      }; */ 

      vm.selectedAccount = parentTab.data.allocation.account;
      /* {
        id: "E5905777-C1CA-4AA8-A5A4-8A3C0380BCD0",
        permission: 'fullAccess',
        allocations: [{
          id: '941a0d6f-7266-40d6-9d65-3b3db658ec17',
          type: 'managedPortfolio',
          status: 'open',
          account: {
            permission: 'fullAccess'
          }
        }, {
          id: '50e6cccb-51e3-49c5-a7a3-506d2cefe975',
          type: 'protected',
          status: 'open'
        }]
      } 
      */ 
      vm.allocation = parentTab.data.allocation;

      /* vm.allocation = {
        id: "941a0d6f-7266-40d6-9d65-3b3db658ec17",
        type: "managedPortfolio",
        status: 'open',
        account: {
          id: "E5905777-C1CA-4AA8-A5A4-8A3C0380BCD0",
          permission: 'fullAccess',
          allocations: [{
            id: '941a0d6f-7266-40d6-9d65-3b3db658ec17',
            type: 'managedPortfolio',
            status: 'open'
          }, {
            id: '50e6cccb-51e3-49c5-a7a3-506d2cefe975',
            type: 'protected',
            status: 'open'
          }]
        }
      };  */
      
      vm.selectedAllocation = vm.allocation.id; /* _.find(vm.selectedAccount.allocations, function(allocation) {
        return allocation.id === vm.allocation.id;
      }); */
		}

		function setAccount(account){
			vm.selectedAccount = account;
			$scope.$broadcast('account-changed', vm.selectedAccount);
		}

		$scope.$on('change-editor-account', function(event, account){
			setAccount(account);
    });

    $scope.$on('update-allocation-' + vm.selectedAccount.id, function(e, allocation){
      vm.selectedAllocation = allocation.id;
    });
    
    $scope.$watch('vm.selectedAllocation', function(newVal, oldVal){
      if (newVal != oldVal) {
        vm.allocation =  _.find(vm.selectedAccount.allocations, function(allocation) {
          return newVal === allocation.id;
        });

        if (vm.allocation) vm.allocation.account = vm.selectedAccount;

        $scope.$broadcast('allocation-changed', newVal);
      }
    });


		$scope.th_select_account = function($item, $model, $label, $event){
      setAccount($item);
    };

    // this will make the new protected sleeve appear in the allocation holdings tab select dropdown
    document.addEventListener('smx-protected-sleeve-created', function(e){
      console.log("New protected sleeve detected in controller");

      // if there are multiple allocation holdings tabs open
      // we only want to update the allocations list for the one whose account id matches the account id of the newly created sleeve
      if (_.property(['detail', 'account'])(e)) {
        if (e.detail.account.id === vm.selectedAccount.id) {
          var existingSleeve = _.find(vm.selectedAccount.allocations, function(sleeve) {
            return sleeve.id === e.detail.id;
          });
          
          if (!existingSleeve) {
            vm.selectedAccount.allocations.unshift(e.detail);
            $rootScope.$broadcast('refresh-account-details', e.detail.account.id);
          }
        }
      }
    });
  })
  .directive('allocationHoldings', allocationHoldings);

  function allocationHoldings ($templateCache) {
    return {
      restrict: 'E',
      template: $templateCache.get('allocation-holdings.tpl.html'),
      controller: AllocationHoldingsCtrl,
      scope: true,
      controllerAs: 'vm',
      bindToController: {
        allocation: '='
      }
    };
  }

  // make highcharts a jquery plugin
  // import $ from 'jquery';
  // import jQueryBridget from 'jquery-bridget';
  // import Highcharts from 'highcharts';
  // jQueryBridget('highcharts', Highcharts, $);


  function AllocationHoldingsCtrl($rootScope, $timeout, NgTableParams, ngTableEventsChannel, $scope, toastr, Allocations, Dashboard, $filter, FeatureFlags) {
    var vm = this;
    var parentTab = $scope.tab;
    var apiHost = _.property(['apiPath', window.ENV['environment']])(window.ENV);

    var tableConfig = {
      count: 10,
    };


    var protectedPositionsTableConfig = {
      sorting: {
        requestTimestamp: "desc"
      }
    };

    var assetTypes = {
      equity: 'Equities',
      mutualFund: 'Mutual Funds',
      bond: 'Bonds',
      option: 'Options',
      Cash: 'Cash'
    };

    vm.groupings = [
      { value: 'industry', text: 'Sector' },
      { value: 'assetType', text: 'Asset Class'},
      { value: 'side', text: 'Side'}
    ];
    vm.selectedGrouping = vm.groupings[0];

    //methods
    vm.init = init;
    vm.refresh = refresh;
    vm.getTransactions = getTransactions;
    vm.exportData = exportData;
    vm.disabled = disabled;
    vm.open = open;
    vm.filterActivities = filterActivities;
    vm.applyCustomFilter = applyCustomFilter;
    vm.getPositionsTableFilterValue = getPositionsTableFilterValue;
    vm.isEnabled = FeatureFlags.isEnabled;

    // props
    vm.adjustments = [];
    vm.lmv = 0;
    vm.smv = 0;
    vm.netExp = 0;
    vm.grossExp = 0;
    vm.showPositionFilters = true;

    // for sleeve transfer component
    vm.sleeveTransferEnabled = FeatureFlags.isEnabled('sleeveTransfer');
    vm.token = SmartX.Router.getToken();
    vm.apiUrl = apiHost ? 'https://' + apiHost + '/3.1' : 'https://api.gtw.qa.smartx.us/api/3.1';

    // for transition scheduler component
    vm.transitionSchedulerEnabled = FeatureFlags.isEnabled('taxTransition');

    // picker config
    vm.formats = ['MM/dd/yyyy', 'shortDate', 'dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy'];
    vm.format = vm.formats[0];
    vm.opened = {
      start: false,
      end: false
    };
    vm.minDate = '';
    vm.maxDate = moment().format();
    vm.dateOptions = {
      formatYear: 'yy',
      startingDay: 7
    };

    $scope.$on('smx-token-updated', function(e, token){
      vm.token = token;
    });

    // date range filter listener
    $scope.$watch('vm.selectedRange', function(newVal, oldVal){
      if (!newVal) return; 
      
      var selectedRange = newVal;
      var start = selectedRange.start();
      var end = selectedRange.end();


      if (selectedRange.id == 'custom') {

        if (oldVal.id == 'all') {
          start = _.first(vm.activities).timestamp.timestamp;
          end = _.last(vm.activities).timestamp.timestamp;
        } else {
          start = oldVal.start();
          end = oldVal.end();
        }
        
        vm.customStart = new Date(start * 1000);
        vm.customEnd = new Date(end * 1000);
      }

      filterActivities(start,end);
    });

    // vm.selectedRange = vm.ranges[0] // default to all
    vm.selectedDataView = 'position';

    vm.activityCodeFilter = {
      'activity': {
        id: 'select',
        placeholder: 'Select Activity'
      }
    };

    vm.activityCodeOptions = [{
      id: '',
      title: '- Select Activity -'
    }];

    vm.activityTypeFilter = {
      'type': {
        id: 'select',
        placeholder: 'Select Type'
      }
    };

    vm.activityTypeOptions = [{
      id: '',
      title: '- Select Activity -'
    }];

    var pieChart;
    var chart;

    vm.lineChartConfig = {
      chart: {
        type: "column"
      },
      credits: {
        enabled: false
      },
      yAxis: {
        title: { text: 'Sleeve Value'}
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          date: '%b %d, %Y'
        }
      },
      plotOptions: {
        series: {
          // stacking: "",
          marker: {
            enabled: false
          }
        }
      },
      title: {
        text: ""
      },
      tooltip: {
        formatter: function() {
          var formattedDate = Highcharts.dateFormat('%b %d, %Y', new Date(this.x));
          if (angular.isDefined(this.y)){
            return "<span>" + formattedDate + "</span><br/>" + $filter('currency')(this.y, '$', 2); /*  + '%'; */
          } else {
            return this.point ? "<span>" + formattedDate + "</span><br/>" + this.point.text : formattedDate;
          }
        }
      }
    };

    $scope.$watch('vm.selectedGrouping', function(newVal, oldVal) {
      if (newVal && oldVal != newVal) {
        Promise.resolve(initPie()).then(buildChart);
      }
    });

    document.addEventListener('smx-protected-sleeve-created', function(e){
      console.log("New protected sleeve detected in directive");
      if (_.property(['detail', 'account'])(e)) {
        if (e.detail.account.id === vm.allocation.account.id) {

          var existingSleeve = _.find(vm.allocation.account.allocations, function(sleeve) {
            return sleeve.id === e.detail.id;
          });
          
          if (!existingSleeve) {
            vm.allocation.account.allocations.unshift(e.detail);
            $rootScope.$broadcast('refresh-account-details', e.detail.account.id);
          }
        }
      }
    });

    document.addEventListener('smx-refresh-allocation-holdings', function(e){
      if (e.detail.accountId === vm.allocation.account.id) {
        // refetch holdings when dialog is closed after a successful transfer
        vm.init();
        $rootScope.$broadcast('refresh-account-details', e.detail.accountId);
      }
    });

    init();
    
    function setupSleeves () {
      if (vm.allocation.type === 'managedPortfolio') {
        var protectedSleeve = vm.allocation.account.allocations.find(function(allocation){
          return allocation.type === 'protected';
        });
        vm.sleevePair = {
          source: {
            id: vm.allocation.id,
            type: vm.allocation.type
          },
          target: {
            id: protectedSleeve ? protectedSleeve.id : null,
            type: 'protected'
          }
        };
      }
  
      if (vm.allocation.type === 'protected' || vm.allocation.type === 'transition') {
        var defaultSleeve = vm.allocation.account.allocations.find(function(allocation){
          return allocation.type === 'managedPortfolio';
        });
        vm.sleevePair = {
          source: {
            id: vm.allocation.id,
            type: vm.allocation.type
          },
          target: defaultSleeve ? {
            id: defaultSleeve.id,
            type: defaultSleeve.type
          } : null
        };
      }
    }

    function open (picker){
      vm.opened[picker] = true;
    }

    function disabled(date, mode) {
      return ( mode === 'day' && ( date.getDay() === 0 || date.getDay() === 6 ) );
    }

    function createDateRanges (activities) {
      var ranges = [
        {
          id: 'all',
          text: 'All',
          start: function() { 
            var firstRow = _.first(activities).timestamp.timestamp;
            return firstRow;
           },
          end: function() { 
            var lastRow = _.last(activities).timestamp.timestamp;
            return lastRow;
          }
        },
        {
          id: 'currentMonth',
          text: 'Current Month',
          start: function () {
            var month = moment().month() + 1;
            var year = moment().year();
            return moment(month + '/01/' + year).unix();
          },
          end: function () {
            return moment().add(1, 'day').unix();
          }
        },
        {
          id: 'previousMonth',
          text: 'Previous Month',
          start: function () {
            var lastMonth = moment().subtract(1, 'month');
            var year = lastMonth.year();
            var month = lastMonth.month() + 1;
            return moment(month + '/01/' + year).unix();
          },
          end: function () {
            var lastMonth = moment().subtract(1, 'month');
            var daysInMonth = lastMonth.daysInMonth();
            return moment(this.start() * 1000).add(daysInMonth, 'days').unix();
          }
        },
        {
          id: 'last6',
          text: 'Last 6 Months',
          start: function () {
            var lastMonth = moment().subtract(6, 'month');
            var year = lastMonth.year();
            var month = lastMonth.month() + 1;
            return moment(month + '/01/' + year).unix();
          },
          end: function () {
            return moment().add(1, 'day').unix();
          }
        },
        {
          id: 'last12',
          text: 'Last 12 Months',
          start: function () {
            var lastMonth = moment().subtract(12, 'month');
            var year = lastMonth.year();
            var month = lastMonth.month() + 1;
            return moment(month + '/01/' + year).unix();
          },
          end: function () {
            return moment().add(1, 'day').unix();
          }
        },
        {
          id: 'currentYear',
          text: 'Current Year',
          start: function () {
            var yearStart = moment().startOf('year').unix();
            return yearStart;
          },
          end: function () {
            return moment().add(1, 'day').unix();
          }
        },
        {
          id: 'previousYear',
          text: 'Previous Year',
          start: function () {
            var yearStart = moment().startOf('year').subtract(1, 'year').unix();
            return yearStart;
          },
          end: function () {
            return moment(this.start() * 1000).add(1, 'year').unix();
          }
        },
        {
          id: 'custom',
          text: 'Custom',
          start: function() { return moment(vm.customStart).unix(); },
          end: function() { return  moment(vm.customEnd).unix(); }
        }
      ];

      return ranges;
    }

    function applyCustomFilter(start, end) {
      if (start instanceof Date) start = moment(start).unix();
      if (end instanceof Date) end = moment(end).unix();

      filterActivities(start, end);
    }

    function filterActivities (start, end) {
      if (!end){
        end = moment().unix();
      }
      if (!start) {
        start = moment().subtract(30, 'days').unix();
      }

      var activitiesByDate = vm.activities.filter(function(activity){
        return activity.timestamp.timestamp >= start && activity.timestamp.timestamp <= end;
      });

      var tableFilters = vm.transactionTableParams.filter();

      var filteredActivities = $filter('filter')(activitiesByDate, tableFilters);

      vm.transactionTableParams.settings().dataset = activitiesByDate; // filteredActivities
      vm.transactionTableParams.page(1);
      vm.transactionTableParams.reload();
    }

    function addToFilterOptions (filter) {
      return function (option) {
        filter.push({
          id: option,
          title: $filter('formatStatus')(option)
        });
      };
    }

    function getTransactions (allocationId) {

      Allocations.getHistory(allocationId || vm.allocation.id)
      .then(function(res){
        console.log("Allocation history", res);
        vm.activities = _.sortBy(res.data.activity, function(activity) {
          return activity.timestamp.timestamp;
        });

        vm.ranges = createDateRanges(vm.activities);

        vm.selectedRange = vm.ranges[0];
        
        // create the list for the activity code and type dropdown filter
        buildDropdownList(vm.activities, 'activity', null, vm.activityCodeOptions);
        buildDropdownList(vm.activities, 'type', null, vm.activityTypeOptions);
        
        vm.transactionTableParams = new NgTableParams({
          sorting: {
            'timestamp.timestamp': "desc"
          }
        }, {
          dataset: vm.activities
        });
      })
      .catch(function(err){
        // console.error(err);
        // toastr.error(err.message);
        Dashboard.toastError(err.message, err);
      });
    }

    function exportData (data) {
      var sleeveName = vm.allocationName.replace(/ /g, '_');
      var filters = vm.transactionTableParams.filter();
      var filteredAcitivites = $filter('filter')(vm.transactionTableParams.settings().dataset, filters);
      var data = _.chain(filteredAcitivites)
                  .sortBy(function(activity){
                    return -activity.timestamp.timestamp;
                  })
                  .map(function(activity){
                    return {
                      'Date': $filter('date')(activity.timestamp.timestamp * 1000, 'MM/dd/yyyy', 'utc'),
                      'Type': $filter('formatStatus')(activity.type),
                      'Activity Code': $filter('formatStatus')(activity.activity),
                      'Ticker': activity.ticker || '$CASH',
                      'Quantity': activity.quantity,
                      'Price': activity.price,
                      'Proceeds': activity.proceeds
                    };
                  })
                  .value(); 

      Dashboard.JSONtoCSV(data, sleeveName + '_sleeve_transactions_' + moment().format('YYYY_MM_DD') + '.csv');
    }

    function getEquityByProperty (property, valueToMatch) {

      if (valueToMatch.toLowerCase() === 'mutual fund'){
        return vm.holdings.filter(function(position){
          return position.ticker !== '$CASH' && (position.assetType === 'mutualFund');
        })
        .reduce(function(total, position){
          return total += position.value;
        }, 0);
      } else {
        return vm.holdings.filter(function(position){
          return position.ticker !== '$CASH' && (position[property].toLowerCase() === valueToMatch.toLowerCase());
        })
        .reduce(function(total, position){
          return total += position.value;
        }, 0);
      }
    }

    function init() {

      console.log(vm.selectedAccount);

      //var portfolioId = vm.selectedAccount.id;
      var allocationId = vm.allocation.id;
      getPortfolio(allocationId);
      setupSleeves();
    }

    function refresh(){
      $scope.$broadcast('account-changed', vm.selectedAccount);
    }

    $scope.$on('allocation-changed', function(event, allocationId){
      // reset the grids back to page 1 when switching between sleeves
      console.log(vm.tableParams);
      vm.tableParams.page(1);
      vm.transactionTableParams.page(1);
      getPortfolio(allocationId);
      getTransactions(allocationId);
    });

    $scope.$watch('vm.allocation', function(newVal, oldVal){
      if (oldVal.id && oldVal != newVal) {
        jQuery('#pieChartInfoText-' + oldVal.id).remove();
        jQuery('#addText-' + oldVal.id).attr('id', 'addText-' + newVal.id);
      }
      vm.allocationName = newVal.model ? newVal.model.name : $filter('formatStatus')(newVal.type);
      setupSleeves();
    });

    $scope.$watch('tab.active', function(newVal, oldVal){
      if (newVal && newVal != oldVal && chart){
        chart.redraw();
      }
    });

    function setInnerText (chart, e) {
      try {
        // var chart = this;
        jQuery('#pieChartInfoText-' + vm.allocation.id).remove();
        if (e.seriesOptions){
          var seriesId = e.seriesOptions.id;
          var seriesName = e.seriesOptions.name;
        } else { 
          if (e.target && e.target.series.length){
            var seriesId = e.target ? e.target.series[0].userOptions.id : (e.seriesOptions ? e.seriesOptions.id : 'home');
            var seriesName = e.target ? e.target.series[0].userOptions.name : (e.seriesOptions ? e.seriesOptions.name : 'Portfolio');
          } else return;       
        }
        
        var totalEquity = seriesId == 'home' ? vm.equity : getEquityByProperty(vm.selectedGrouping.value, seriesId);
        var padding = parseInt(jQuery('#holdingsPie-' + vm.allocation.id).parent().css('padding'));
        // padding was empty on IE and FF
        if (!padding) padding = 15;
        // var groupingSelectorHeight = jQuery('#select-container').height() || 0
        var textX = padding + chart.plotLeft + (chart.plotWidth  * 0.5);
        var textY = /* groupingSelectorHeight + */ padding + chart.plotTop  + (chart.plotHeight * 0.5);

        var span = '<span id="pieChartInfoText-' + vm.allocation.id + '" style="position:absolute; text-align:center;">';
        span += '<span style="font-size: 19.416px; font-weight:bold;">' + $filter('currency')(totalEquity, '$') + '</span><br>';
        span += '<span class="text-muted" style="font-size: 12px">' + seriesName + '</span>';
        span += '</span>';

        jQuery("#addText-" + vm.allocation.id).append(span);
        span = jQuery('#pieChartInfoText-' + vm.allocation.id);
        span.css('left', textX + (span.width() * -0.5));
        span.css('top', textY + (span.height() * -0.5));
      } catch (e) {
        console.error(e);
        return;
      }
    }

    function initPie () {
      vm.chartConfig = {
        chart: {
          type: 'pie',
          // renderTo: 'holdingsPie-' + vm.allocation.id,
          events: {
            load: function(e) {
              console.log(e);
              chart = this;
              $timeout(function(){
                setInnerText(chart, e);
              });                
            },
            redraw: function(e) {
              console.log(e);
              chart = this;
              setInnerText(chart,e);
            },
            drilldown: function(e) {
              console.log(e);
              chart = this;
              setInnerText(chart,e);
            },
            drillup: function (e) {
              console.log(e);
              chart = this;
              setInnerText(chart,e);
            }
          }
        },
        title: {
          text: '',
        },
        subtitle: {
          text: ''
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
              shadow: false
          },
          series: {
            events: {
              legendItemClick: function () {
                this.visible = !this.visible;
                return false;
              }
            }
          }
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          width: '20%',
          layout: 'vertical',
          x: -80,
          y: 100
        },
        tooltip: {
          formatter: function() {
            return '<b>'+ this.point.name +'</b>: '+ this.y.toFixed(2) +' %';
          }
        }
      };
    }

    function getPortfolio(allocationId) {

      vm.loadingPortfolio = true;

      initPie();

      Allocations.getHoldings(allocationId)
        .then(handlePortfolioResponse)
        .then(buildChart)
        .catch(function(err) {
          // console.error(err);
          // toastr.error(err.message);
          Dashboard.toastError(err.message, err);
        })
        .finally(function(){
          vm.loadingPortfolio = false;
          $scope.$apply();
        });

      Allocations.getPerformance(allocationId, 'weekly')
      .then(handlePerformanceResponse)
      .then(buildPerformanceChart)
      .catch(function (err) {
        // console.error(err);
        // toastr.error(err.message);
        Dashboard.toastError(err.message, err);
      });

      Allocations.getStatistics(allocationId)
      .then(handleStatisticsResponse)
      .catch(function(err){
        // console.error(err);
        // toastr.error(err.message);
        Dashboard.toastError(err.message, err);
      });
    }

    function handleStatisticsResponse (response) {
      $timeout(function() {
        vm.statistics = response.data.statistics;
      });
    }

    function handlePerformanceResponse (response) {
      vm.performance = response.data.performance.performance.filter(function(period){
        return period.pnl.dollar || period.summary.beginningMarketValue !== 0 || period.summary.endingMarketValue !== 0;
      });
      return vm.performance;
    }

    function buildPerformanceChart (performance) {
      var series = [{
        name: vm.allocationName || (vm.allocation.model ? vm.allocation.model.name : $filter('formatStatus')(vm.allocation.type)),
        // data: [vm.performance[0].summary.beginningMarketValue]
        // id: 'performanceLine',
        data: []
      }];

      var startingValue = 0; // performance[0].pnl.percent; // performance[0] ? performance[0].summary.beginningMarketValue + (performance[0].summary.increaseByAmount + performance[0].summary.decreaseByAmount) : 0;
      var currentValue = startingValue;
      var flagSeries = [{
        type: 'flags',
        data: [],
        shape: 'flag',
        showInLegend: false,
        clip: false
        // onSeries: 'performanceLine',
      }];

      // if (performance[0]) {
      //   var firstDate = moment(performance[0].range.start.timestamp * 1000).subtract(1, 'days').unix()
      //   series[0].data.push([firstDate * 1000, performance[0].endingMarket])
      // }

      performance.forEach(function(period) {
        var cashFlows = period.summary.increaseByAmount + period.summary.decreaseByAmount;
        //var startingValue = (period.summary.beginningMarketValue + cashFlows)
        var pnlPercent = period.pnl.percent; // (period.summary.endingMarketValue - (period.summary.beginningMarketValue + cashFlows)) / (startingValue > 0 ? startingValue : period.summary.beginningMarketValue)
        currentValue = currentValue + (currentValue * pnlPercent);
        // var currentPnl = currentValue - startingValue
        // var currentPnlPercent = (currentPnl / startingValue) * 100
        series[0].data.push([period.range.start.timestamp * 1000, period.summary.endingMarketValue]);

        if (cashFlows){
          flagSeries[0].data.push({
            x: period.range.start.timestamp * 1000,
            title: cashFlows > 0 ? 'I' : 'O',
            text: (cashFlows > 0 ? 'Inflow of ' : 'Outflow of ') + $filter('currency')(cashFlows, '$')
          });
        }
      });

      if (!performance.length){
        var d = new Date().getTime();
        series[0].data.push([d, 0]);
      }

      // fix chopped off flag when it's at the end of the series
      // if (flagSeries[0].data){
      //   var lastWeekStart = moment(_.last(series[0].data)[0]).subtract(7, 'days').unix() * 1000
      //   if (_.last(flagSeries[0].data).x >= lastWeekStart){
      //     var extraPeriod = moment(_.last(series[0].data)[0]).add(7, 'days').unix()
      //     var numPeriodsToAdd = 2
      //     _.times(numPeriodsToAdd, function() {
      //       series[0].data.push([
      //         extraPeriod * 1000, _.last(series[0].data)[1]
      //       ])
      //     })
      //   }
      // }

      $timeout(function(){
        vm.lineChartConfig.series = flagSeries[0].data.length ? series.concat(flagSeries) : series;
      });
    }

    function buildChart (holdings) {
      holdings = holdings || vm.holdings;
      var cashPercentage = (vm.accountAvailableCash / vm.equity) * 100;
      var securitiesPercentage = ((vm.equity - vm.accountAvailableCash) / vm.equity) * 100;
      var slices = [
        {
          name: 'Cash',
          value: cashPercentage
        }
      ];
      var shortPositions = [];
      var longPositions = [];

      holdings.forEach(function(position){
        if (position.value < 0){
          shortPositions.push(position);
        } else if (position.value > 0){
          longPositions.push(position);
        }
      });

      var drilldown = {
        breadcrumbs: {
          buttonTheme: {
            fill: '#f7f7f7',
            padding: 8,
            stroke: '#cccccc',
            'stroke-width': 1,
            style: {
              color: '#000'
            }
          },
          showFullPath: false,
          position: {
            align: 'right'
          },
          floating: true
        },
        series: []
      };

      var series = [{
        name: 'Portfolio',
        id: 'home',
        data: [],
        size: '70%',
        innerSize: '70%',
        showInLegend:true,
        dataLabels: {
            enabled: false
        }
      }];
      
      var groupedAssets = _.groupBy(vm.holdings.filter(function(position){return position.ticker != '$CASH';}), vm.selectedGrouping.value);

      if (securitiesPercentage) {

        _.each(groupedAssets, function(positions, name){
          name = name; // positions[0].assetType === 'mutualFund' ? 'Mutual Fund' : name
          var obj = {
            name: vm.selectedGrouping.value === 'assetType' ? assetTypes[name] : name,
            id: name,
            data: [],
            size: '70%',
            innerSize: '70%',
            showInLegend:true,
            dataLabels: {
                enabled: false
            }
          };

          var slice = {
            name: vm.selectedGrouping.value === 'assetType' ? assetTypes[name] : name,
            value: _.reduce(positions, function(total, position){
              return Math.abs(total += position.percentOfPortfolio);
            }, 0) * 100,
            drilldown: name
          };

          positions.forEach(function(position){
            if (position.ticker != '$CASH'){

              var totalPercentage = _.reduce(positions, function(total, position){
                return total += position.percentOfPortfolio;
              }, 0);
  
              obj.data.push([position.ticker, Math.abs(position.percentOfPortfolio / totalPercentage) * 100]);
            }
          });

          console.log("slice: ", slice);
          console.log("series: ", obj);
  
          slices.push(slice);
          drilldown.series.push(obj);
        });
      }

      // _.each(groupedAssets, function(positions, name){

      //   var slice = {
      //     name: name,
      //     value: _.reduce(positions, function(total, position){
      //       return total += position.percentOfPortfolio
      //     }, 0) * 100,
      //     drilldown: name
      //   }

      //   slices.push(slice)
      // })

      slices.forEach(function(slice){
        if (slice.value){
          series[0].data.push({ 
            name: slice.name, 
            y: slice.value, 
            drilldown: slice.drilldown 
          });
        }
      });

      vm.chartConfig.drilldown = drilldown;
      vm.chartConfig.series = series;

      // if (pieChart) {
      //   pieChart.update({
      //     drilldown
      //   });

      //   pieChart.update({
      //     series
      //   });

      //   pieChart.redraw();
      // } else {

        $timeout(function(){
          var chartContainer = jQuery('#holdingsPie-' + vm.allocation.id);
          if (chartContainer.length) {
            // pieChart = $('#holdingsPie-' + vm.allocation.id).highcharts(vm.chartConfig);
            pieChart = Highcharts.chart('holdingsPie-' + vm.allocation.id, vm.chartConfig);
          }
        });
      // }
    }

    function handlePortfolioResponse(response) {

      $timeout(function(){
        vm.loadingPortfolio = false;
      });

      var holdings = _.map(response.data.positions, function(holding) {
        holding.value = Number(holding.marketValue || holding.value);
        holding.percentOfPortfolio = (holding.value / response.data.equity);
        holding.side = holding.value >= 0 ? 'Long' : 'Short';
        // if (holding.assetType === 'equity' && holding.industry.toLowerCase() === 'not available') holding.industry = 'Unknown'
        return holding;
      });

      vm.accountAvailableCash = response.data.cash;
      vm.equity = response.data.equity;

      // vm.chartConfig.title.text = $filter('currency')(vm.equity, '$');

      var cashPosition = {
        ticker: '$CASH',
        value: vm.accountAvailableCash,
        percentOfPortfolio: vm.equity ? (vm.accountAvailableCash / vm.equity) : 0,
        assetType: '--'
      };

      vm.holdings = _.sortBy(holdings, 'value').reverse();
      vm.holdings.unshift(cashPosition);
      vm.originalHoldings = angular.copy(holdings);

      if (vm.tableParams) {
        vm.tableParams.settings().dataset = vm.holdings;
        vm.tableParams.reload();
      } else {
        vm.tableParams = new NgTableParams({
          filter: vm.tableParams ? vm.tableParams.filter() : {},
          sorting: vm.tableParams ? vm.tableParams.sorting() : {
            requestTimestamp: "desc"
          }
        },{
          dataset: vm.holdings
        });
      }

      return vm.holdings;
    }

    $scope.$watch('vm.transactionTableParams', function(newVal){
      if (newVal) createListener(newVal);
    });

    function getPositionsTableFilterValue (key) {
      if (vm.tableParams) {
        return vm.tableParams.filter()[key];
      } else {
        return '';
      }
    }

    function buildDropdownList (data, field, filters, list) {
      list.splice(1);

      if (filters) {
        data = _.chain(data)
                .filter(function(activity){
                  return filters.type == "" ? true : activity.type === filters.type;
                })
                .value();
      }

      filteredActivities = _.chain(data).pluck(field).uniq().value();

      _.each(filteredActivities, addToFilterOptions(list));
    }

    function createListener(tableInstance) {
      ngTableEventsChannel.onAfterReloadData(function(tableParams, filteredData){

        var dataset = tableParams.settings().dataset;
        var filters = tableParams.filter();

        console.log(filteredData);

        // filter codes list by the type
        if (angular.isDefined(filters.type) && filters.type != null){
          buildDropdownList(dataset, 'activity', filters, vm.activityCodeOptions);
        } else {
          buildDropdownList(dataset, 'activity', null, vm.activityCodeOptions);
        }

        buildDropdownList(dataset, 'type', null, vm.activityTypeOptions);
                           

      }, $scope, tableInstance);
    }
  }