angular.module('TargetWeightGroups', [])
  .directive('editTargetWeightGroup', ['$templateCache', 'Models', function($templateCache, Models) {
    return {
      restrict: 'E',
      scope: {
        models: '=',
        parentTab: '='
      },
      template: $templateCache.get('editTargetWeightGroup.tpl.html'),
      link: link,
      controller: editTargetWeightGroupCtrl,
      controllerAs: 'vm'
    };

    function link(scope, elem, attr) {}


    function editTargetWeightGroupCtrl(Amplitude, Tabs, EnterpriseFactory, $http, $interpolate, $scope, $rootScope, $modal, Dashboard, TargetWeightFactory, USER, NgTableParams, $filter, $templateCache, toastr, SweetAlert, $timeout, FavoriteModels, DRUPAL_API_URL, SmartXFactory) {
      var vm = this;
      vm.FavoriteModels = FavoriteModels;
      FavoriteModels.getFavoriteModels()
      .then(() => {
        $scope.$apply();
      });
      var chart;
      // var colors = TargetWeightFactory.pieColors;
      var mutualFundsData = [];
      var tabType = $scope.parentTab.type;
      var accessModels = Models.getModelList();
      fetchMutualFundsData();
      var defaultEnterpriseModels = accessModels.filter(function(model){ 
        return model.type && model.type.toLowerCase() != 'sma'; 
      })
      .map(function(model){
        return {
            id: model.id,
            assetType: 'model',
            companyName: '',
            industry: null,
            multiplier: null,
            ticker: model.name,
            static: model.isStatic,
            type: model.type ? model.type.toLowerCase() : null
        };
      });

      var emptyGuid = '00000000-0000-0000-0000-000000000000';

      var formatAssetType = TargetWeightFactory.formatAssetType;
      var userEnterprise = EnterpriseFactory.getUserEnterprise();

      var equityData = [];
      var typeData = [];

      vm.logEvent = Amplitude.logEvent;

      vm.apmDisabled = USER.apmDisabled;

      vm.symbols = [].concat(defaultEnterpriseModels);

      vm.formattedModels = [];

      vm.targets = [];
      vm.breadcrumbs = [];

      vm.tree_data = [];
      vm.expanding_property = "Name";
      vm.col_defs = TargetWeightFactory.expandableTreeColumns;
      vm.my_tree = {};
      vm.targetDefaults = {};
      vm.formDefaults = {};
      vm.userDefaults = {};
      vm.twgChart = {};

      vm.hasUnsavedChanges = false;
      vm.cleanTarget = null;
      vm.retryCount = 0;


      vm.isFiltersVisible = false;
      vm.advanceMode = false;
      vm.advanceModeTerm = 'Advanced Mode';
      vm.statuses = ['Active', 'Inactive', 'Pending'];
      vm.equityTemplateURL = "equityLookupTemplate.tpl.html";
      vm.equityItemTemplateURL = "equityLookupItemTemplate.tpl.html";
      vm.tableParams = new NgTableParams({
        count: 25,
        sorting: {
          name: "asc"
        },
        group: "assetType"
      }, {
        dataset: []
      });
      vm.activeTarget = null;
      vm.advisorTargets = [];
      vm.matches = [];
      vm.pageTitle = null;
      vm.minimumAllocationForTarget = 100000;
      vm.totalTargetPercent = 0;
      vm.editTargetName = false;

      vm.totalCashPercent = 0;
      vm.isGroupHeaderRowVisible = false;
      vm.isTreeExpanded = false;
      vm.isLoadingAssets = false;
      vm.isLoadingTarget = false;

      vm.accessAccounts = Dashboard.getAccessAccounts();
      vm.accessAccountNames = Object.keys(vm.accessAccounts);
      vm.enterpriseAccess = EnterpriseFactory.getEnterpriseAccess();
      vm.foreignEnterpriseAccess = EnterpriseFactory.getForeignEnterpriseAccess();
      vm.enterpriseTargetWeightGroupRelationships = EnterpriseFactory.getEnterpriseRelationships('enterpriseTargetWeightGroup');
      vm.targetEnterpriseOptions = _.sortBy(_.uniq(
                                    _.flatten([EnterpriseFactory.getUserEnterprise(), vm.enterpriseAccess, vm.foreignEnterpriseAccess, vm.enterpriseTargetWeightGroupRelationships])
                                      .filter(function(item){
                                        // exclude options that are not enterprise or enterpriseTarget
                                        if (item.relationshipType && !_.contains(['enterprise', 'enterpriseTargetWeightGroup'], item.relationshipType)) return false;
                                        
                                        // can only create targets in other enterprises if we have full access
                                        return item && (item.permission == 'fullAccess' || item.permissionName == 'fullAccess');
                                      })
                                    , function(item){
                                      return item.id;
                                    }
                                  ), 'name');


      vm.targetEnterpriseOptions.unshift({
        id: '',
        name: '-- Select Target Enterprise --'
      });

      vm.selectedEnterprise = vm.targetEnterpriseOptions[0];

      vm.add = add;
      vm.edit = edit;
      vm.deleteRow = deleteRow;
      vm.save = save;
      vm.close = close;
      vm.toggleFilters = toggleFilters;
      vm.toggleAdvanceMode = toggleAdvanceMode;
      vm.scaleCash = scaleCash;
      vm.sum = TargetWeightFactory.sum;
      vm.isLastPage = isLastPage;
      vm.loadSubTarget = loadSubTarget;
      vm.toggleSubTargets = toggleSubTargets;
      vm.init = init;
      vm.toggleExpandedTree = toggleExpandedTree;
      vm.addToTarget = addToTarget;
      vm.calculateTotalPercent = calculateTotalPercent;
      vm.blurTargetInputHandler = blurTargetInputHandler;
      vm.exportToPDF = exportToPDF;
      vm.generateProposal = generateProposal;
      vm.openPerformance = openPerformance

      vm.addTab = Tabs.addTab;

      $scope.$watch('vm.selectedEnterprise', function(newVal, oldVal){
        if (newVal.id && newVal.id != userEnterprise.id) getEnterpriseModels(newVal.id);
        else if (newVal.id === '') {
          vm.formattedModels = defaultEnterpriseModels;
        }
      });

      $scope.$on('update-targets', function(event, args) {

        if(angular.isDefined(args) && args.newAccount){
          addCashComponent();
        }

        if( $scope.parentTab.data && $scope.parentTab.data.type != 'existing'){

          if (vm.hasUnsavedChanges){
            var callback = function() {
              if ($scope.parentTab.data.type != 'new') vm.payloadFromPortfolioBuilder = $scope.parentTab.data;
              init();
            };

            showSaveReminder($scope.parentTab.data, callback);
          } else {
            if ($scope.parentTab.data.type != 'new') vm.payloadFromPortfolioBuilder = $scope.parentTab.data;
            init();
          }
          
        } else {
          if (vm.hasUnsavedChanges) return;
          calculateTotalPercent();
        }
      });

      // $scope.$watch('vm.activeTarget', function(oldVal, newVal){
      //   console.log("Old target: ", oldVal);
      //   console.log("New Target: ", newVal);
      // });

      $scope.$on('$destroy', function() {
        activeTargetListener();
        advisorTargetListener();
        dashboardAcctAccessListener();
        TargetWeightFactory.setActiveTarget(null);
        TargetWeightFactory.resetBreadcrumbs();
      });

      // will run every time we switch to this tab
      $scope.$on('tabSwitched::' + tabType, function(event, tab) {

        if (tab === $scope.parentTab) {
          $timeout(function() {
            if (!vm.twgChart || !Object.keys(vm.twgChart).length) {
              updateChart(vm.targets);
            }
            //vm.twgChart.reflow();
          }, 100);
        }

      });

      var dashboardAcctAccessListener = $rootScope.$on('dashboard-account-access-set', function(event, args) {
        vm.accessGroups = args.groups;
        vm.accessAccounts = args.accounts;
        vm.accessAccountNames = Object.keys(vm.accessAccounts);
      });


      var advisorTargetListener = $rootScope.$on('set-advisor-targets', function(event, args) {
        vm.advisorTargets = TargetWeightFactory.getAdvisorTargets();
      });


      var activeTargetListener = $rootScope.$on('set-active-target', function(event, args) {

        var newActiveTarget = TargetWeightFactory.getActiveTarget();

        if (vm.hasUnsavedChanges){

          if (typeof args != 'undefined') {
            TargetWeightFactory.setActiveTarget(args);
            return;
          }

          vm.previousTarget = angular.copy(vm.activeTarget); // previousTarget is used to reset the vm.activeTarget if the user chooses to continue working
          vm.activeTarget = TargetWeightFactory.getActiveTarget();

          showSaveReminder(vm.activeTarget);

        } else {
          if (typeof args != 'undefined') {
            TargetWeightFactory.setActiveTarget(args);
            return;
          }

          vm.activeTarget = TargetWeightFactory.getActiveTarget();

          if (vm.activeTarget == null) {
            clearTable();
            init();
          } else if (typeof vm.activeTarget.details == 'undefined') {

            if (vm.activeTarget.isDefaultTarget){
              TargetWeightFactory.resetBreadcrumbs();
              init();
            } else {
              getTargetsForActiveAccount();
            }
          } else if (vm.payloadFromPortfolioBuilder) {
            buildTargetsFromPortfolioBuilder();
          } else {
            setPageTitle();
            if (typeof vm.activeTarget.isClone != 'undefined' && vm.activeTarget.isClone == true) {
              vm.activeTarget.name = 'Clone of ' + vm.activeTarget.name;
              vm.activeTarget.accessLevel = 'fullAccess';
              vm.hasUnsavedChanges = true;
              delete vm.activeTarget.id;
            }
            if (vm.activeTarget && vm.activeTarget.details) {
              vm.proposalDetails = checkDetails(vm.activeTarget.details);
              var mfCheck = false;
              var mfNumber = 0;
              for(let target of vm.activeTarget.details) {
                if (target.target.id === '51f267df-c792-4bf6-9c6e-e3e743b6b483') // FATRX id
                  mfCheck = true;

                if (target.target.type === 'symbol') {
                  mfNumber++;
                }
              }
              if (mfCheck && mfNumber === 1) {
                vm.canGenerateProposal = vm.proposalDetails.length + 1;
              } else {
                vm.canGenerateProposal = vm.proposalDetails.length;
              }
            }
            targetDetailCallbackHandler([vm.activeTarget]);
          }
        }

        
      });

      init();

      ///////////////////
      // https://stackoverflow.com/questions/4187146/truncate-number-to-two-decimal-places-without-rounding
      function truncate(num, fixed) {
        var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
        return num.toString().match(re)[0];
      }

      /**
       * Update an item in the advisor targets list with new information (threshold/precision values, name, etc)
       * @param  {array} targetsList   An array of advisor targets
       * @param  {object} updatedTarget The single updated target we want in the list
       * @return {array}               Returns the updated list of advisor targets
       */
      function updateTargetInfo(targetsList, updatedTarget){
        var index = _.findIndex(targetsList, function(target) { return target.id == updatedTarget.id; });
        if (index !== -1) targetsList[index] = updatedTarget;

        return targetsList;
      }

      // TODO: show confirmation dialog so they can choose to continue working or load the new target over the currently active one
      function showSaveReminder(target, cb){

        var modalInstance = $modal.open({
          animation: true,
          template: $templateCache.get('unsavedChangesReminder.tpl.html'),
          controller: function($scope, $uibModalInstance){
            $scope.ok = function () {
              $uibModalInstance.close();
            };
          
            $scope.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };
          }
        });

        modalInstance.result.then(function() {

          if (!cb){
            vm.activeTarget = target;
            vm.changeTarget();
          } else {
            cb();
          }          
        })
        .catch(function(err){
          vm.payloadFromPortfolioBuilder = null;
          $scope.parentTab.data = null;
          if (vm.previousTarget) {
            vm.activeTarget = angular.copy(vm.previousTarget);
            TargetWeightFactory.setActiveTarget(vm.activeTarget, true); // sync the active target in the service without triggering the update-targets event
            vm.previousTarget = null;
          }
        });
      }

      function init() {

        vm.multiEnterprise = EnterpriseFactory.isMultiEnterpriseUser();

        // get the passed in payload from the portfolio builder / search page
        vm.payloadFromPortfolioBuilder = (angular.isDefined($scope.parentTab.data) && $scope.parentTab.data != null && !angular.isDefined($scope.parentTab.data.type)) ? $scope.parentTab.data : undefined;

        vm.advisorTargets = TargetWeightFactory.getAdvisorTargets();
        vm.activeTarget = TargetWeightFactory.getActiveTarget();
        vm.userDefaults = TargetWeightFactory.getUserDefaults(vm.activeTarget);



        // // Get the list of models that the enterprise the target belongs to has access to through its platform relationship
        // // Default to the users enterprise if there is no enterprise set for the active target (new targets)
        // getEnterpriseModels(vm.activeTarget.enterprise ? vm.activeTarget.enterprise.id : userEnterprise.id);

        if (!vm.advisorTargets.length || vm.userDefaults == undefined || angular.equals(vm.userDefaults, {})) {

          vm.loading = true;
          TargetWeightFactory.getTargets()
            .then(function(response) {

              var temp = [];
              var targets = response.data.data;
              try {
                var activeTargetData = _.findWhere(targets, {id: vm.activeTarget.id});
              }
              catch (e) {
                console.error(e);
                var activeTargetData = null;
              }

              var userDefaults = vm.userDefaults = TargetWeightFactory.getUserDefaults(activeTargetData);

              // Save targets to service
              TargetWeightFactory.setAdvisorTargets(targets);
              TargetWeightFactory.setUserDefaults(userDefaults);

              if (vm.activeTarget == null && vm.payloadFromPortfolioBuilder == undefined) {
                addCashComponent();

                vm.targetDefaults.DefaultLowerThreshold = vm.userDefaults.threshold.lower * 100; // * 100;
                vm.targetDefaults.DefaultUpperThreshold = vm.userDefaults.threshold.upper * 100;//DefaultUpperThreshold * 100;
                vm.targetDefaults.DefaultLowerPrecision = vm.userDefaults.precision.lower * 100;//DefaultLowerPrecision * 100;
                vm.targetDefaults.DefaultUpperPrecision = vm.userDefaults.precision.upper * 100;//DefaultUpperPrecision * 100;

                vm.formDefaults = vm.targetDefaults;
              } else {

                vm.formDefaults = {
                  DefaultLowerThreshold: userDefaults.threshold.lower * 100,
                  DefaultUpperThreshold: userDefaults.threshold.upper * 100,
                  DefaultLowerPrecision: userDefaults.precision.lower * 100,
                  DefaultUpperPrecision: userDefaults.precision.upper * 100
                };
              }
              
              vm.originalFormDefaults = angular.copy(vm.formDefaults);
            })
            .catch(function(err){
              // toastr.error(err.message);
              Dashboard.toastError(err.message, err);
            })
            .finally(function(){
              $timeout(function(){
                vm.loading = false;
                updateChart(vm.targets);
              }, 300);
            });        

        } else {
          if (vm.activeTarget == null && vm.payloadFromPortfolioBuilder == undefined) {
            getEnterpriseModels((vm.activeTarget && vm.activeTarget.enterprise && vm.activeTarget.enterprise.id != emptyGuid) ? vm.activeTarget.enterprise.id : userEnterprise.id);
            clearTable();
            addCashComponent();
            setPageTitle();

            vm.targetDefaults.DefaultLowerThreshold = vm.userDefaults.threshold.lower * 100;// * 100;
            vm.targetDefaults.DefaultUpperThreshold = vm.userDefaults.threshold.upper * 100;//DefaultUpperThreshold * 100;
            vm.targetDefaults.DefaultLowerPrecision = vm.userDefaults.precision.lower * 100;//DefaultLowerPrecision * 100;
            vm.targetDefaults.DefaultUpperPrecision = vm.userDefaults.precision.upper * 100;//DefaultUpperPrecision * 100;

            vm.formDefaults = vm.targetDefaults;
            vm.originalFormDefaults = angular.copy(vm.formDefaults);

            vm.cleanTarget = null;

            return false;
          } else {

            if (vm.userDefaults.threshold.lower <= 1) {
              vm.targetDefaults.DefaultLowerThreshold = vm.userDefaults.threshold.lower * 100;//DefaultLowerThreshold * 100;
            }
            if (vm.userDefaults.threshold.upper <= 1) {
              vm.targetDefaults.DefaultUpperThreshold = vm.userDefaults.threshold.upper * 100;//DefaultUpperThreshold * 100;
            }
            if (vm.userDefaults.precision.lower <= 1) {
              vm.targetDefaults.DefaultLowerPrecision = vm.userDefaults.precision.lower * 100;//DefaultLowerPrecision * 100;
            }
            if (vm.userDefaults.precision.upper <= 1) {
              vm.targetDefaults.DefaultUpperPrecision = vm.userDefaults.precision.upper * 100;//DefaultUpperPrecision * 100;
            }
            vm.formDefaults = vm.targetDefaults;
            vm.originalFormDefaults = angular.copy(vm.formDefaults);
          }
        }

        getTargetsForActiveAccount();
      }

      function getEnterpriseModels(enterprise) {
        vm.loadingEnterpriseModels = true;

        EnterpriseFactory.getEnterpriseModels(enterprise, { type: 'file,web,composite' })
        .then(function(res){
          var models = res.data;
          vm.formattedModels = models.map(function(model){
            return {
              id: model.id,
              assetType: 'model',
              companyName: '',
              industry: null,
              multiplier: null,
              ticker: model.name,
              static: model.isStatic,
              type: model.type
            };
          });
        })
        .catch(function(err){
          // console.error(err.message);
          // toastr.error(err.message);
          Dashboard.toastError(err.message, err);
        })
        .finally(function(){
          $timeout(function(){
            vm.loadingEnterpriseModels = false;
          });
        });
      }

      function exportToPDF(){
        var pieHTML = jQuery('#twgChart').parent().html();
        var targetsTableHTML = $interpolate(jQuery('#targetsTable').html())(vm);

        var targetData = {
          ria_firm: Drupal.settings.ria_firm,
          templateName: 'target-weights-report.twig',
          templateHeader: 'blend-report-header.twig',
          templateFooter: 'model-report-footer.twig',
          createdAt: moment(new Date()).format('MM-DD-YYYY hh:mm A'),
          logoPath: (jQuery('#smartx-pdf-logo').length >= 1) ? jQuery('#smartx-pdf-logo').attr('src') : window.location.origin + jQuery('#logo').attr('src'),
          platform: Dashboard.getPlatform(),
          includeCover: false,
          pieHTML: pieHTML,
          targetName: vm.targetName,
          targetsTableHTML: targetsTableHTML,
          targetWeightGroups: _.groupBy(vm.targets.map(function(target){ 
                                if (target.type == 'cash') target.assetType = 'cash';
                                return target;
                              }), 'assetType'),
          equityData: equityData,
          typeData: typeData,
          user: Drupal.settings.fullName,
          riaFirm: Drupal.settings.ria_firm,
          baseUrl: window.location.origin
        };

        var totals = _.reduce(targetData.targetWeightGroups, function(totals, group, assetType){
          var total = TargetWeightFactory.sum(group, 'percentTarget');
          totals[assetType] = total;
          
          return totals;
        }, {});

        targetData.totals = totals;

        vm.creatingPDF = true;
        $http
          .post(`${DRUPAL_API_URL}/ng_api/pdf_generator`, targetData, {
            headers: {
              'Content-Type': 'application/json'
            },
            responseType: 'arraybuffer'
          })
          .then(function(res) {
            vm.creatingPDF = false;
            var blob = new Blob([res.data], {
              type: "application/pdf"
            });
            var filename = vm.targetName + '-target-weights-' + moment().format('DD/MM/YY') + '.pdf';

            saveAs(blob, filename);
          })
          .catch(function(err) {
            vm.creatingPDF = false;
            console.error(err.message);
          });

        Amplitude.logEvent('Target Editor:Export PDF');
      }

      function toggleAdvanceMode() {

        Amplitude.logEvent('Target Editor:Toggle Advanced Mode');
        vm.advanceMode = !vm.advanceMode;
        if (vm.advanceMode)
          vm.advanceModeTerm = 'Basic Mode';
        else
          vm.advanceModeTerm = 'Advanced Mode';
      }

      function fetchMutualFundsData() {
        return $http.get('https://analytics.gtw.qa.smartx.us/mutual-funds', {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + SmartXFactory.getToken()
          }
        })
        .then(function(response) {
          mutualFundsData = response.data;
        })
        .catch(function(error) {
          console.error('Error fetching mutual funds data:', error);
          mutualFundsData = [];
        });
      }

      function addToTarget(security) {

        if (!security) return;

        console.log('this is mutual fund data', mutualFundsData)
        vm.selectedSymbol = '';

        var mutualFundMatch = mutualFundsData.find(fund => fund.ModelId === security.id);

        if (mutualFundMatch) {
          security.id = mutualFundMatch.MutualFundId;
          security.ticker = mutualFundMatch.Cusip;
          security.assetType = 'Mutual Fund'
          security.companyName = mutualFundMatch.CompanyName;
        }

        var obj = {
          id: security.id,
          isNew: 1,
          leverage: 1,
          companyName: security.companyName,
          matchModelLeverage: true,
          name: (security.AssetName || security.ticker),
          newPercentTarget: 0,
          percentTarget: 0,
          percentLowerThreshold: vm.formDefaults.DefaultLowerThreshold,
          percentUpperThreshold: vm.formDefaults.DefaultUpperThreshold,
          percentLowerPrecision: vm.formDefaults.DefaultLowerPrecision,
          percentUpperPrecision: vm.formDefaults.DefaultUpperPrecision
        };
        
        var assetType = security.assetType ? security.assetType.trim() : security.type.trim();
        switch (assetType.toLowerCase().replace(/ /g, '')) {
          case 'equity':
            obj.type = 'Symbols';
            obj.assetType = 'Equity';
            obj.symbolId = security.id;
            obj.companyName = security.companyName || '';
            break;
          case 'mutualfund':
            obj.type = 'Symbols';
            obj.assetType = 'Mutual Fund';
            obj.symbolId = security.id;
            break;
          case 'model':
            obj.type = 'model';
            obj.assetType = 'Model';
            obj.modelId = security.id;
            obj.static = security.static;
            break;
          case 'targetweightgroup':
            obj.type = 'targetweightgroup';
            obj.assetType = 'Targets';
            obj.twgId = security.id;
            break;
          default:
            obj.type = 'symbols';
        }

        var existingComp = vm.targets.filter(function(elem, i, array) {

          switch (elem.type.toLowerCase()) {
            case 'model':
              if (!elem.detailId && !elem.modelId) return false;
              var id = elem.detailId || elem.modelId;
              return id.toLowerCase() == security.id.toLowerCase();
            case 'targets':
              if (!elem.twgId) return false;
              return elem.twgId.toLowerCase() == security.id.toLowerCase();
            case 'symbols':
            case 'symbol':
            case 'equity':
            case 'mutualfund':
              if (!elem.symbolId) return false;
              return elem.symbolId.toLowerCase() == security.id.toLowerCase();
            default:
              return false;
          }
        });
        console.log('this is added object', obj)
        if (existingComp.length == 0) vm.targets.push(obj);

        calculateTotalPercent();
      }

      function initUserDefaults(defaults) {

        if (typeof defaults.threshold != 'undefined') {

          if (defaults.threshold.lower <= 1) {
            vm.targetDefaults.DefaultLowerThreshold = defaults.threshold.lower * 100;
          } else {
            vm.targetDefaults.DefaultLowerThreshold = defaults.threshold.lower;
          }

          if (defaults.threshold.upper <= 1) {
            vm.targetDefaults.DefaultUpperThreshold = defaults.threshold.upper * 100;
          } else {
            vm.targetDefaults.DefaultUpperThreshold = defaults.threshold.upper;
          }

          if (defaults.precision.lower <= 1) {
            vm.targetDefaults.DefaultLowerPrecision = defaults.precision.lower * 100;
          } else {
            vm.targetDefaults.DefaultLowerPrecision = defaults.precision.lower;
          }

          if (defaults.precision.upper <= 1) {
            vm.targetDefaults.DefaultUpperPrecision = defaults.precision.upper * 100;
          } else {
            vm.targetDefaults.DefaultUpperPrecision = defaults.precision.upper;
          }

          vm.formDefaults = vm.targetDefaults;
        } else {
          vm.formDefaults = vm.userDefaults;
        }
        vm.originalFormDefaults = angular.copy(vm.formDefaults);
      }


      function buildTargetsFromPortfolioBuilder() {

        var target = vm.payloadFromPortfolioBuilder,
          temp = [],
          targets = target.details,
          groups = targets.length ? _.groupBy(targets, function(target){ return target.target ? target.target.type : target.type; }) : targets,
          groupKeys = Object.keys(groups),
          defaults = TargetWeightFactory.getUserDefaults();

        if (target.hasOwnProperty('id') && !target.isDefaultTarget){ target.id = undefined; }

        vm.targetDefaults = {};

        if (target.isDefaultTarget) {}

        initUserDefaults(defaults);

        TargetWeightFactory.resetBreadcrumbs();

        vm.breadcrumbs = TargetWeightFactory.addBreadcrumb({
          name: vm.targetName || target.name,
          id: target.id
        });

        if (target.name == 'undefined' || typeof vm.targetName == 'undefined' || vm.targetName == null) {
          vm.isNew = true;
        }

        vm.targets = TargetWeightFactory.formatTargetDetails(targets);

        // Add Cash row if it does not exist
        if (typeof targets.cash == 'undefined' || targets.cash == null) {
          addCashComponent();
        }

        vm.cleanTarget = angular.copy(vm.targets);

        vm.pageTitle = 'Create New Target';
        vm.targetName = 'Target-' + new Date().getTime();

        calculateTotalPercent();
        scaleCash();

        vm.activeTarget = target;
        delete $scope.parentTab.data; // clear it out when we're done so it doesn't load the same stored data during the next init
        delete vm.payloadFromPortfolioBuilder; // same as above

        vm.hasUnsavedChanges = true;
        setPageTitle();
        
        // reset tree data that may have been loaded from a previous target
        vm.tree_data = [];

        return;
      }

      function getTargetName (target) {
        return target.length ? target[0].name : target.name;
      }

      function getTargetId (target) {
        return target[0].twgId ? target[0].twgId : target[0].id;
      }

      function getDefaultName (target) {
        target = (target && target[0]) || target;
        var accountNumber = $filter('sanitizeSmartXAccountName')(target.accountNumber, 'number') || '--';
        var brokerage = $filter('formatStatus')(target.brokerage || '--');
        return brokerage + ': ' + accountNumber;
      }

      function setBreadcrumbs(target) {
        var isDefault = target[0].isDefault || target[0].isDefaultTarget || false;
        if (vm.breadcrumbs.length && target[0].id != _.last(vm.breadcrumbs).id){
          vm.breadcrumbs = TargetWeightFactory.addBreadcrumb({
            name: isDefault ? getDefaultName(target) : getTargetName(target),
            id: getTargetId(target),
            isDefault: isDefault,
            accessLevel: target[0].accessLevel,
            precision: target[0].precision,
            threshold: target[0].threshold
          });
        } else {
          vm.breadcrumbs = TargetWeightFactory.addBreadcrumb({
            name: isDefault ? getDefaultName(target) : getTargetName(target),
            id: getTargetId(target),
            isDefault: isDefault,
            accessLevel: target[0].accessLevel,
            precision: target[0].precision,
            threshold: target[0].threshold
          });
        }
      }

      function targetDetailCallbackHandler(response) {

        var currentTarget = response,
            targets = currentTarget[0].details.map(function(detail){
              detail.target.isNew = vm.activeTarget && vm.activeTarget.isClone ? true : false;
              return detail;
            }),
            defaults = {
              threshold: currentTarget[0].threshold || vm.userDefaults.threshold,
              precision: currentTarget[0].precision || vm.userDefaults.precision
            };
        
        initUserDefaults(defaults);

        vm.tree_data = TargetWeightFactory.buildTreeData(currentTarget, true);

        setBreadcrumbs(currentTarget);

        var detailGroups = _.groupBy(targets, function(target){
          return target.target.type;
        });

        vm.targets = TargetWeightFactory.formatTargetDetails(targets);
        vm.cleanTarget = angular.copy(vm.targets);

        // Add Cash row if it does not exist
        if (typeof detailGroups.cash == 'undefined' || detailGroups.cash == null) {
          SweetAlert.swal({
            title: "Warning",
            text: 'This target does not add up to 100% or the cash component was not detected. Please save your target again.',
            type: "warning"
          });
          addCashComponent();
        }

        vm.loading = false;
        calculateTotalPercent();
      }

      function checkDetails (details){
        var flattenedDetails = TargetWeightFactory.flattenDetails(angular.copy(details));            

        if (flattenedDetails) {
          // group by detail type and order each group by percent weight
          var groups = _.chain(flattenedDetails)
                        .groupBy(function(detail){ return detail.target.type; })
                        .mapObject(function(group){
                          return _.sortBy(group, function(item) {
                            return -item.percent;
                          });
                        })
                        .value();

          if (groups.symbol) {
            return [];
          }

          if (groups.symbol) {
            var symbols = _.chain(groups.symbol)
                            .groupBy(function(symbol){
                              return symbol.target.ticker;
                            })
                            .mapObject(function(symbols){
                              symbols = _.reduce(symbols, function(total, symbol){
                                if (total.percent){
                                  total.percent += symbol.percent;
                                  return total;
                                } else {
                                  total = symbol;
                                  return total;
                                }
                              }, {});

                              return symbols;
                            })
                            .value();
            
            // return to array format
            groups.symbol = _.values(symbols);
          }

          if (groups.model){
            var models = _.chain(groups.model)
                            .groupBy(function(model){
                              return model.target.name;
                            })
                            .mapObject(function(models){
                              models = _.reduce(models, function(total, model){
                                if (total.percent){
                                  total.percent += model.percent;
                                  return total;
                                } else {
                                  total = model;
                                  return total;
                                }
                              }, {});

                              return models;
                            })
                            .value();
            
            // return to array format
            groups.model = _.values(models);
          } else {
            return [];
          }

          var modelTotal = _.reduce(groups.model, function(total, entry){ return total += entry.percent; }, 0);
          var symbolTotal = _.reduce(groups.symbol, function(total, entry){ return total += entry.percent; }, 0);
          var targetsTotal = _.reduce(groups.targetWeightGroup, function(total, entry){ 
            return total += entry.percent; 
          }, 0);
          
          var totalAllocated = Number(targetsTotal.toPrecision(14)) + Number(modelTotal.toPrecision(14)) + Number(symbolTotal.toPrecision(14));

          var cashTotal = 1 - ( totalAllocated ) >= 0 
                          ? 1 - ( totalAllocated ) 
                          : 0;

          if (groups.cash) groups.cash[0].percent = cashTotal;

          vm.group_data = [].concat(groups.model, groups.symbol, groups.targetWeightGroup, groups.cash ? groups.cash[0] : groups.cash).filter(function(group){ return angular.isDefined(group);});

          var cash = vm.group_data.filter(function(elem, i, array) {
            return elem.target.type.toLowerCase() == 'cash';
          });
          var oldIdx = vm.group_data.indexOf(cash[0]);
          vm.group_data.move(oldIdx, vm.group_data.length - 1);

          var allModels = _.every(vm.group_data, function(detail){
            return detail.target.type === 'model' || detail.target.type === 'cash';
          });

          if (groups.model.length && allModels) {
            return vm.group_data;
          } else {
            return [];
          }
        } else {
          return [];
        }
      }

      function generateProposal () {
        Tabs.addTab('portfolio-builder');
        for(let detail of vm.activeTarget.details) {
          if (detail.target.id === '51f267df-c792-4bf6-9c6e-e3e743b6b483') {
            detail.target.id = '0b3e7ea5-6163-4bc6-ac89-49976a4e509e';
            detail.target.type = 'model'
          }
        }
        vm.proposalDetails = checkDetails(vm.activeTarget.details);
        TargetWeightFactory.setProposalDetails(angular.copy(vm.proposalDetails));

        $timeout(function() {
          $rootScope.$broadcast('editPortfolio', {name: vm.targetName || vm.activeTarget.name, type: 'target', data: vm.group_data, pdf: true});
        });

        Amplitude.logEvent("Target Editor:Generate Proposal");
      }

      function openPerformance() {
        console.log(vm.activeTarget)
        for(let detail of vm.activeTarget.details) {
          if (detail.target.id === '51f267df-c792-4bf6-9c6e-e3e743b6b483') {
            detail.target.id = '0b3e7ea5-6163-4bc6-ac89-49976a4e509e';
            detail.target.type = 'model'
          }
        }
        vm.addTab('target-portfolio', null, null, vm.activeTarget); 
        vm.logEvent('Target Editor:View performance');
      }

      // debug detail changes
      // $scope.$watch('vm.activeTarget.details', function(newVal, oldVal){
      //   console.log(newVal, oldVal)
      //   if (newVal && newVal !== oldVal){

      //   }
      // }, true)      

      function getTargetsForActiveAccount(hideLoader) {

        getEnterpriseModels((vm.activeTarget && vm.activeTarget.enterprise && vm.activeTarget.enterprise.id != emptyGuid) ? vm.activeTarget.enterprise.id : userEnterprise.id);

        if (vm.payloadFromPortfolioBuilder) {
          buildTargetsFromPortfolioBuilder();
          return;
        }

        if (vm.activeTarget == null ){
          setPageTitle();
          calculateTotalPercent();
          scaleCash();
          vm.isLoadingTarget = false;
        } else {

          var _id = (typeof vm.activeTarget.twgId != 'undefined') ? vm.activeTarget.twgId : vm.activeTarget.id;

          var originalTarget = vm.activeTarget;
          var currentTarget;
          vm.originalTarget = originalTarget;
          vm.isLoadingTarget = true;
          if (!hideLoader) vm.loading = true;
          
          // if (vm.activeTarget.enterprise) {
            // Get the list of models that the target's enterprise to has access to through its platform relationship
            // Default to the users enterprise if there is no enterprise set for the active target (new targets)
            // getEnterpriseModels(vm.activeTarget.enterprise ? vm.activeTarget.enterprise.id : userEnterprise.id);
          // }

          TargetWeightFactory.getTargetDetailByGUID(_id)
            .then(async function(response) {

              if (typeof response.data != 'undefined' && response.data.length) {

                var targetDetails = response.data;

                var performanceApi = SmartXFactory.getPerformanceAPI();
                
                var isAllowed = false;
                var res = await performanceApi.modelsSummary();
                
                for (const [key, _] of Object.entries(res.data.payload)) {
                  if (key === '0b3e7ea5-6163-4bc6-ac89-49976a4e509e') {
                    isAllowed = true;
                    break;
                  }
                }

                vm.proposalDetails = checkDetails(targetDetails);
                var mfCheck = false;
                var mfNumber = 0;
                for(let target of targetDetails) {
                  if (target.target.id === '51f267df-c792-4bf6-9c6e-e3e743b6b483') // FATRX id
                    mfCheck = true;

                  if (target.target.type === 'symbol') {
                    mfNumber++;
                  }
                }
                
                if (mfCheck && mfNumber === 1 && isAllowed) { // currently checking for 1 mf, this will be included in lambda that would return a list of MF models
                  vm.canGenerateProposal = vm.proposalDetails.length + 1;
                } else {
                  vm.canGenerateProposal = vm.proposalDetails.length;
                }

                if (originalTarget.isClone) {
                  targetDetails = response.data.map(function(detail){
                    delete detail.id;
                    return detail;
                  });
                }

                vm.activeTarget.details = targetDetails;
                currentTarget = vm.activeTarget;

                TargetWeightFactory.setActiveTarget(currentTarget, true);

                targetDetailCallbackHandler([currentTarget]);

                if (typeof originalTarget.isClone != 'undefined' && originalTarget.isClone == true) {
                  vm.activeTarget.name = 'Clone of ' + vm.activeTarget.name;
                  vm.activeTarget.accessLevel = 'fullAccess';
                  vm.hasUnsavedChanges = true;
                  delete vm.activeTarget.id;
                }
              } else {
                toastr.info('No target details found...');
                vm.loading = false;
                vm.activeTarget.details = [];
                currentTarget = vm.activeTarget;
                TargetWeightFactory.setActiveTarget(currentTarget, true);
                targetDetailCallbackHandler([currentTarget]);
              }

              setPageTitle();
              vm.isLoadingTarget = false;
            })
            .catch(function(err) {
              vm.loading = false;
              // console.error(err);
              // toastr.error(err.message);
              Dashboard.toastError(err.message, err);
              updateChart(vm.targets);
            })
            .finally(function(){
              vm.changingTarget = false;
            });
        }
      }

      vm.toggleEditTargetName = function() {

        var currentName = vm.activeTarget ? vm.activeTarget.name : vm.targetName;
        vm.editTargetName = !vm.editTargetName;

        // if we are done editing and the name was changed...
        if (!vm.editTargetName && (currentName != vm.targetName)) {
          vm.activeTarget.name = vm.targetName;
          setPageTitle();

          var payload = angular.extend({name: vm.targetName}, {
            defaultPrecision: vm.activeTarget.precision,
            defaultThreshold: vm.activeTarget.threshold
          });

          if (vm.activeTarget.id){
            if (vm.activeTarget.enterprise) payload.enterpriseId = vm.activeTarget.enterprise.id;
            TargetWeightFactory.updateTarget(vm.activeTarget.id, payload)
            .then(function(){
              toastr.success("Name updated");
            })
            .catch(function(err){
              // toastr.error(err.message);
              // console.error(err);
              Dashboard.toastError(err.message, err);
              if (vm.activeTarget) vm.activeTarget.name = currentName;
              vm.targetName = currentName;
            });
          }
        }
      };

      function setPageTitle() {

        if (vm.activeTarget != null && (vm.activeTarget.isDefaultTarget || vm.activeTarget.isDefault)) {
          vm.pageTitle = 'Edit Target';
          vm.targetName = vm.activeTarget.brokerage ? ($filter('formatStatus')(vm.activeTarget.brokerage || '--') + ': ' + ($filter('sanitizeSmartXAccountName')(vm.activeTarget.accountNumber, 'number'))) : vm.activeTarget.name; // 'Default Target';
        } else if (vm.activeTarget == null) {
          vm.pageTitle = 'Create New Target';
          vm.targetName = 'Target-' + new Date().getTime();
        } else {
          vm.pageTitle = 'Edit Target';
          vm.targetName = vm.activeTarget.name;
        }
      }

      function toDecimal(num){
        return (num > 1 ? math.format(num / 100, {precision:14}) : num);
      }

      function loadSubTarget(component) {
        try {
          component.accessLevel = component.accessLevel ? component.accessLevel : TargetWeightFactory.getAccessLevel(component);
        } catch (e) {
          console.error(e);
          component.accessLevel = 'view'; // default to view if the subtarget is not in our list
        }
        if (vm.hasUnsavedChanges) {
          showSaveReminder(component, function(){
            TargetWeightFactory.setActiveTarget(component, true);
            vm.twgChart = null;
            init();
          });
        } else {
          TargetWeightFactory.setActiveTarget(component, true);
          vm.twgChart = null;
          init();
        }
      }

      function clearTable() {
        if(vm.isLoadingTarget == true){ return; }

        vm.activeTarget = null;
        vm.twgChart = null;
        vm.targets = [];
        vm.tree_data = [];
        vm.breadcrumbs = [];

        TargetWeightFactory.resetBreadcrumbs();
        setPageTitle();
        calculateTotalPercent();
      }


      vm.setTarget = function(target) {

        Amplitude.logEvent('Target Editor:Target changed');

        if (vm.hasUnsavedChanges){

          showSaveReminder(target);

        } else {
          vm.activeTarget = target;
          vm.changeTarget();
        }
      };

      vm.changeTarget = function() {
        vm.twgChart = null;
        TargetWeightFactory.resetBreadcrumbs();
        getTargetsForActiveAccount(true); // hide the loader when switching targets
        vm.changingTarget = true;
      };

      function add(type) {

        if (type === 'targetWeightGroup'){
          Amplitude.logEvent('Target Editor:Sub-target icon clicked');
        }

        var modalInstance = $modal.open({
          animation: true,
          template: $templateCache.get('targetWeightModal.tpl.html'),
          resolve: {
            component: function() {
              return {
                type: type,
                defaults: vm.formDefaults
              };
            },
            activeTarget: function() {
              return vm.activeTarget;
            },
            targetName: function () {
              return vm.activeTarget ? vm.activeTarget.name : (vm.targetName || 'New Target');
            },
            symbols: function() {
              return vm.symbols;
            },
            models: function() {
              return vm.models;
            },
            enterpriseId: function () {
              return vm.selectedEnterprise.id != '' ? vm.selectedEnterprise.id : userEnterprise.id;
            }
          },
          controller: 'TargetWeightModalCtrl',
          controllerAs: 'vm'
        });

        modalInstance.result.then(function(payload) {
          processComponent(payload);
        });
      }

      function detailChanged(cleanTarget, detail){
        var index = _.findIndex(cleanTarget, function(cleanDetail) { 
          return _.isEqual(cleanDetail, detail); 
        });

        return index == -1;
      }

      function getNewDetails(targetDetails){
        return (vm.activeTarget && vm.activeTarget.isClone) ? targetDetails : targetDetails.filter(function(target){ return target.isNew || !angular.isDefined(target.id); });
      }

      function getTargetDetailUpdates(targetDetails, cleanTarget){
        var updates = targetDetails
                        .map(function(target){
                          target.newPercentTarget = +target.newPercentTarget;
                          target.percentTarget = +target.percentTarget;
                          return target;
                        })
                        .filter(function(target){
                          var _target = angular.copy(target);
                          // ignore new target components and any component whose details haven't changed
                          if (!target.isNew && angular.isDefined(target.id || target.detailId) && _.map(cleanTarget, 'id').indexOf(target.id) == -1){
                            return false;
                          } else if (!target.isNew && angular.isDefined(target.id || target.detailId) && detailChanged(cleanTarget, _target)) {
                            return true;
                          }
                        });
        
        return updates;
      }

      function getDefaultThresholds(defaults){
        return {
          threshold: {
            upper: defaults.DefaultUpperThreshold / 100,
            lower: defaults.DefaultLowerThreshold / 100
          },
          precision: {
            upper: defaults.DefaultUpperPrecision / 100,
            lower: defaults.DefaultLowerPrecision / 100
          }
        };
      }

      function getUpdatedThresholds(activeTarget, userDefaults){
        return {
          threshold: activeTarget.threshold ? activeTarget.threshold : userDefaults.threshold,
          precision: activeTarget.precision ? activeTarget.precision : userDefaults.precision
        };
      }

      function getNewTargetPayload(targetName, formDefaults){
        return {
          "name": targetName,
          "defaultPrecision": {
            "upper": formDefaults.DefaultUpperPrecision / 100,
            "lower": formDefaults.DefaultLowerPrecision / 100
          },
          "defaultThreshold": {
            "upper": formDefaults.DefaultUpperThreshold / 100,
            "lower": formDefaults.DefaultLowerThreshold / 100
          }
        };
      }

      function getUnauthorizedModels () {
        var enterpriseModels = vm.formattedModels.length ? vm.formattedModels : defaultEnterpriseModels;
        if (enterpriseModels.length) {
          var modelDetails = vm.targets.filter(function(detail) { return detail.assetType && (detail.assetType.trim().toLowerCase() === 'model' ||  detail.assetType.trim().toLowerCase() === 'models'); });
          var enterpriseModelIds = _.pluck(enterpriseModels.concat(), 'id');

          var unauthorizedModels = _.filter(modelDetails, function(model){
            if (model.newPercentTarget == 0 || model.percentTarget == 0) return false;
            return !_.contains(enterpriseModelIds, model.id || model.detailId);
          });
          // console.log(unauthorizedModels);
          // console.log(enterpriseModels);
          // console.log(modelDetails);
          return unauthorizedModels;
        } else return [];
      }

      function getDeletedDetails (details){
        return details.filter(function(detail){
          return detail.id && detail.newPercentTarget == 0 && detail.percentTarget == 0;
        });
      }


      function save() {
        var reqs = [];
        var newDetails = getNewDetails(vm.targets);
        var updates = getTargetDetailUpdates(vm.targets, vm.cleanTarget);
        var modelDetails = newDetails.concat(updates).filter(function(detail) { return detail.type == 'model'; });
        var unauthorizedModels = modelDetails.length ? getUnauthorizedModels() : [];

        Amplitude.logEvent('Target Editor:Target saved', {
          "Model Detail Count": modelDetails ? modelDetails.length : 0
        });

        // prevent them from saving new details with 0 weight
        newDetails = newDetails.filter(function(detail){
          if (detail.type != 'cash' && (detail.newPercentTarget == 0 || isNaN(detail.percentTarget) || detail.percentTarget == 0)) return false;
          return true;
        });

        if (unauthorizedModels.length){
          // show alert that not all models in the target are available to the user
          SweetAlert.swal({
            title: "Unauthorized",
            text: "<div class='text-left col-xs-12'>The following models are not part of the " +  ( vm.selectedEnterprise.id !== '' ? vm.selectedEnterprise.name : userEnterprise.name ) + " enterprise:<br/>" +
                  "<ul style='padding: 10px; margin-bottom: 0;'>" + unauthorizedModels.map(function(model){ return "<li>" + model.name + "</li>";}).join("") + "</ul>" +
                  "They must be removed or replaced before this target can be saved.</div>",
            html: true,
            customClass: 'unauthorized-models-alert',
            type: "warning"
          });
          return false;
        }

        var promise = new Promise(function(resolve, reject){

          if (vm.activeTarget && !vm.activeTarget.isClone && !vm.hasUnsavedChanges) return false;

          if (typeof vm.targetName == 'undefined' || vm.targetName == '') {
            SweetAlert.swal({
              title: "Missing Target Name",
              text: 'Please give this target a name.',
              type: "warning"
            });
            return;
          }

          if (vm.totalAllocated > 100) {
            SweetAlert.swal({
              title: "Over Allocated",
              text: 'You are over allocated. Please scale down the components in this target.',
              type: "warning"
            });
            return;
          } else if (vm.totalAllocated < 100) {
            SweetAlert.swal({
              title: "Under Allocated",
              text: 'You are over under allocated. Would you like to assign the unallocated amount to $CASH?',
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#DD6B55",
              confirmButtonText: "Yes",
              closeOnConfirm: true
            }, function(isConfirm) {
              if (isConfirm) {
                scaleCash();
              }
            });
            return;
          }

          var newDetailsPayload = vm.targets.map(function(target){
            var obj = {
              "type": target.type.toLowerCase().replace(/s$/g, ''),
              "target": {
                "id": target.type.toLowerCase() == 'cash' ? null : (target.id || target.twgId || target.detailId),
                "leverage": target.leverage || null,
                "matchModelLeverage": target.matchModelLeverage
              },
              "percent": math.format(+target.newPercentTarget / 100, {precision: 14}),
              "precision": {
                "upper": math.format(target.percentUpperPrecision / 100, {precision: 14}),
                "lower": math.format(target.percentLowerPrecision / 100, {precision: 14})
              },
              "threshold": {
                "upper": math.format(target.percentUpperThreshold / 100, {precision: 14}),
                "lower": math.format(target.percentLowerThreshold / 100, {precision: 14})
              }
            };

            if (target.detailId) {
              // if the percent is 0 and the detail is not the cash detail, then delete it.
              obj.action = obj.percent > 0 ? 'modify' : (obj.type === 'cash' ? 'modify' : 'delete');
              obj.id = target.detailId;
            } else {
              obj.action = 'create';
            }

            return obj;
          });

          if (newDetailsPayload.length){
            newDetailsPayload = newDetailsPayload.filter(function(detail){
              if (detail.action == 'create' && detail.type != 'cash' && detail.percent == 0) return false;
              return true;
            });
          }

          if (typeof vm.activeTarget != 'undefined' && vm.activeTarget != null && (typeof vm.activeTarget.twgId != 'undefined' || typeof vm.activeTarget.id != 'undefined')) {

            var _id = (typeof vm.activeTarget.twgId != 'undefined') ? vm.activeTarget.twgId : vm.activeTarget.id;

            // resync if we get to this point with a twgId and no id
            if (angular.isDefined(vm.activeTarget.twgId)) vm.activeTarget.id = vm.activeTarget.twgId;
            
            vm.savingTarget = true;

            if (typeof _id == 'undefined' && _id == null) {
              SweetAlert.swal({
                title: "Missing Target Id",
                text: 'Please contact an administrator.',
                type: "warning"
              });
              vm.savingTarget = false;
              return;
            }

            var newDetails = getNewDetails(vm.targets);
            var updates = getTargetDetailUpdates(vm.targets, vm.cleanTarget);

            // details that were somehow saved with a 0 percent weight and need to be cleared out BUG-2111
            var deletedDetails = getDeletedDetails(vm.targets);

            if (!newDetails.length && !updates.length && !formDefaultsChanged() && !deletedDetails.length) {
              vm.savingTarget = false;
              return false;
            }

            var newThresholds = getDefaultThresholds(vm.formDefaults);
            var currentThresholds = getUpdatedThresholds(vm.activeTarget, vm.userDefaults);

            if (!angular.equals(currentThresholds, newThresholds)){
              var payload = angular.extend({name: vm.targetName}, {
                defaultPrecision: newThresholds.precision,
                defaultThreshold: newThresholds.threshold
              });

              if (angular.isDefined(vm.selectedEnterprise) && vm.selectedEnterprise.id !== ''){
                payload.enterpriseId = vm.selectedEnterprise.id;
              } else {
                payload.enterpriseId = EnterpriseFactory.getUserEnterprise().id;
              }

              TargetWeightFactory.updateTarget(vm.activeTarget.id, payload)
              .then(function(res){
                toastr.success("Target Updated!", 'success');
                vm.activeTarget = res.data.data;
                
                // update the target info for the item in the targets dropdown
                vm.advisorTargets = updateTargetInfo(vm.advisorTargets, vm.activeTarget);


                TargetWeightFactory.setActiveTarget(vm.activeTarget);
                initUserDefaults({precision: vm.activeTarget.precision, threshold: vm.activeTarget.threshold });

                $rootScope.$broadcast('updateAdvisorTargets', vm.advisorTargets);

              })
              .catch(function(err){
                // toastr.error(err.message);
                // console.error(err);
                Dashboard.toastError(err.message, err);
              })
              .finally(function(){
                vm.savingTarget = false;
                
                $scope.$apply();
              });
            }

            TargetWeightFactory.updateDetails(vm.activeTarget.id, newDetailsPayload)
            .then(function(res){
              return resolve(res);
            })
            .catch(function(err){
              // toastr.error(err.message);
              // console.error(err);
              Dashboard.toastError(err.message, err);
              reject(err);
            })
            .finally(function(){
              vm.savingTarget = false;
              $rootScope.$broadcast('refresh-rebalancer-data');
              // vm.twgChart = null;
              vm.cleanTarget = null;
              vm.hasUnsavedChanges = false;

              init();
              $rootScope.$broadcast('dashboard-check-rebalance');
            });
          } else {

            var payload = getNewTargetPayload(vm.targetName, vm.formDefaults);

            if (angular.isDefined(vm.selectedEnterprise) && vm.selectedEnterprise.id !== ''){
              payload.enterpriseId = vm.selectedEnterprise.id;
            } else {
              payload.enterpriseId = EnterpriseFactory.getUserEnterprise().id;
            }

            vm.savingTarget = true;
            TargetWeightFactory.createTarget(payload)
            .then(function(response) {

              // add the details to newly created target
              var newTarget = response.data.data;
              var newTargetId = newTarget.id;
              var newDetails = vm.targets;
              // var reqs = newDetails.map(buildNewDetailRequests(newTarget));

              // add new target to list of advisor targets
              var advisorTargets = TargetWeightFactory.getAdvisorTargets();
              var updatedAdvisorTargets = advisorTargets.concat([newTarget]);
              TargetWeightFactory.setAdvisorTargets(updatedAdvisorTargets);

              TargetWeightFactory.updateDetails(newTargetId, newDetailsPayload)
              .then(function(res){
                $rootScope.$broadcast('dashboard-check-rebalance');
                toastr.success("Target Details Added", "Success");
                vm.savingTarget = false;
                resolve();
              })
              .catch(function(err){
                // toastr.error(err.message);
                // console.error(err);
                Dashboard.toastError(err.message, err);
                reject(err);
              })
              .finally(function(){
                $timeout(function(){
                  vm.savingTarget = false;
                  $rootScope.$broadcast('refresh-rebalancer-data');
                  TargetWeightFactory.resetBreadcrumbs();
                  TargetWeightFactory.setActiveTarget(response.data.data, true);
                  //vm.twgChart = null;
                  vm.cleanTarget = null;
                  vm.hasUnsavedChanges = false;
                  //buildChartData();
                  init();
                  $rootScope.$broadcast('dashboard-check-rebalance');
                });
              }); 
            })
            .catch(function(err) {
              // toastr.error(err.message);
              Dashboard.toastError(err.message, err);
              reject(err);
              vm.savingTarget = false;
              updateChart(vm.targets);
            });
          }
        });
        console.groupEnd();
        return promise;
      }

      function canDelete(){
        if (vm.activeTarget && vm.activeTarget.isClone) {
          return true;
        }

        if (vm.activeTarget && vm.activeTarget.accessLevel === 'fullAccess') return true;

        if (vm.isNew) {
          return true;
        }

        return false;
      }

      function deleteRow(component) {

        var idPropName;
        var componentId;
        var existingComp;
        var selectedIdx = null;
        var componentType = component.type;
        if (component.detailId && !canDelete()) return false;

        switch (componentType) {
          case 'Models':
          case 'model':
            idPropName = 'modelId';
            break;
          case 'Mutual Fund':
            idPropName = 'symbolId';
            break;
          case 'Symbols':
          case 'Symbol':
          case 'symbol':
            idPropName = 'symbolId';
            break;
          case 'Targets':
          case 'targetWeightGroup':
            idPropName = 'twgId';
            break;
          default:
            //console.warn("Unrecognized component type. Defaulting to 'id' for idPropName");
            idPropName = 'detailId';
        }

        // if the detail already exists and was somehow saved with a 0 percent, this will delete it BUG-2111
        if (component.detailId && component.id && Number(component.percentTarget) === 0 && Number(component.newPercentTarget) === 0) {
          vm.hasUnsavedChanges = true;
        } else {
          componentId = component[idPropName] || component.id;

          existingComp = vm.targets.filter(function(elem, i, array) {

            var elemId = elem[idPropName] || elem.id;
            if (elemId == componentId) {
              selectedIdx = i;
              return true;
            }
            return false;
          });

          if (vm.targets[selectedIdx].isNew || !angular.isDefined(vm.targets[selectedIdx].id)) vm.targets.splice(selectedIdx, 1);
          else vm.targets[selectedIdx].newPercentTarget = 0;
          calculateTotalPercent();
        }
      }

      function edit(component) {

        var modalInstance = $modal.open({
          animation: true,
          template: $templateCache.get('targetWeightModal.tpl.html'),
          resolve: {
            component: function() {
              var input = component;
              input.defaults = vm.formDefaults;
              return input;
            },
            activeTarget: function() {
              return vm.activeTarget;
            },
            targetName: function() {
              return vm.targetName || 'New Target';
            },
            symbols: function() {
              return vm.symbols;
            },
            models: function() {
              return vm.models;
            },
            enterpriseId: function () {
              return vm.selectedEnterprise.id != '' ? vm.selectedEnterprise.id : userEnterprise.id;
            }
          },
          controller: 'TargetWeightModalCtrl',
          controllerAs: 'vm'
        });

        modalInstance.result.then(function(payload) {
          processComponent(payload);
        });
      }

      function processComponent(payload) {

        if (typeof payload.percentTarget == 'undefined') {
          return;
        }

        var obj = {
          leverage: isNaN(payload.leverage) ? null : payload.leverage,
          matchModelLeverage: payload.matchModelLeverage,
          name: payload.type == 'cash' ? 'cash' : (payload.name || payload.symbol),
          newPercentTarget: payload.percentTarget,
          percentLowerThreshold: payload.percentLowerThreshold,
          percentUpperThreshold: payload.percentUpperThreshold,
          percentLowerPrecision: payload.percentLowerPrecision,
          percentUpperPrecision: payload.percentUpperPrecision,
          type: payload.type,
        };

        obj.assetType = formatAssetType(payload.assetType || payload.type);

        switch (obj.type.toLowerCase()) {
          case 'model':
            obj.modelId = payload.id;
            break;
          case 'mutualfund':
            obj.symbolId = payload.id;
            break;
          case 'targetweightgroup':
            obj.twgId = payload.id;
            break;
          case 'symbols':
          case 'symbol':
            obj.symbolId = payload.id;
            break;
        }

        var existingComp = TargetWeightFactory.getExistingDetails(vm.targets, payload);

        existingComp = existingComp.map(function(component) {
          if (component.type == 'cash'){
            component.name = 'cash';
          }

          return component;
        });

        if (existingComp.length == 0) vm.targets.push(obj);
        else {
          
          var existingComponentIndex = _.findIndex(existingComp, {"name": obj.name});

          if (existingComponentIndex >= 0){

            existingComp[existingComponentIndex].newPercentTarget = +payload.percentTarget;
            existingComp[existingComponentIndex].percentLowerThreshold = payload.percentLowerThreshold;
            existingComp[existingComponentIndex].percentUpperThreshold = payload.percentUpperThreshold;
            existingComp[existingComponentIndex].percentLowerPrecision = payload.percentLowerPrecision;
            existingComp[existingComponentIndex].percentUpperPrecision = payload.percentUpperPrecision;

            if (typeof payload.leverage != 'undefined') {
              existingComp[existingComponentIndex].leverage = payload.leverage;
            }

            if (typeof payload.matchModelLeverage != 'undefined') {
              existingComp[existingComponentIndex].matchModelLeverage = payload.matchModelLeverage;
            }
          } else {
            obj.isNew = true;
            vm.targets.push(obj);
          }
        }

        calculateTotalPercent();
      } 

      vm.onFormDefaultChanged = function () {	
        // check to see if default threshold and precision match the original values	
        vm.hasUnsavedChanges = hasPendingChanges();	
      };	

      function formDefaultsChanged () {	
        var originalFormDefaults = _.map(angular.copy(vm.originalFormDefaults), function(v) { return Number(v);});	
        var formDefaults = _.map(angular.copy(vm.formDefaults), function(v){ return Number(v); });	
        if (_.some(formDefaults, isNaN)) return false;	
        return !angular.equals(originalFormDefaults, formDefaults);	
      }

      function hasPendingChanges() {
        var originalPortfolio = vm.cleanTarget.map(TargetWeightFactory.normalizeDetail);
        var currentPortfolio = vm.targets.map(TargetWeightFactory.normalizeDetail);

        // to prevent false positives after adding the cash detail
        // var cashPosition = _.find(currentPortfolio, {'security': 'Cash'});
        // if (cashPosition){
        //   if (cashPosition.percent == 0) {
        //     var cashPositionIndex = currentPortfolio.indexOf(cashPosition);
        //     currentPortfolio.splice(cashPositionIndex,1)
        //   }
        // }

        var match = angular.equals(originalPortfolio, currentPortfolio);

        var defaultsChanged = formDefaultsChanged();

        if (!match){
          // debugger
        }

        return !match || defaultsChanged;
      }

      function calculateTotalPercent(refresh) {
        refresh = (typeof refresh == 'undefined') ? true : refresh;

        if (vm.targets == null || vm.targets.length == 0) {
          return;
        }

        if(vm.cleanTarget != null && !hasPendingChanges()) vm.hasUnsavedChanges = false;
        else vm.hasUnsavedChanges = true;

        if (refresh) {
          var paginationOpts = (vm.targets.length > 25) ? [25, 50, 100] : [];
          vm.tableParams = new NgTableParams({
            count: 25,
            sorting: {
              name: "asc"
            },
            group: "assetType"
          }, {
            dataset: vm.targets
          });
          vm.tableParams.settings({
            counts: paginationOpts
          });
        }


        var t = 0;
        for (var i = 0; i < vm.targets.length; i++) {
          var item = vm.targets[i];

          if (item.type != 'cash') {

            if (!isNaN(item.newPercentTarget)) {
              t += parseFloat(item.newPercentTarget);
            } else {
              item.newPercentTarget = parseFloat(item.newPercentTarget);
              t += parseFloat(item.newPercentTarget);
            }
            if (isNaN(item.percentLowerPrecision)) {
              item.percentLowerPrecision = 0;
            }
            if (isNaN(item.percentLowerThreshold)) {
              item.percentLowerThreshold = 0;
            }
            if (isNaN(item.percentUpperPrecision)) {
              item.percentUpperPrecision = 0;
            }
            if (isNaN(item.percentUpperThreshold)) {
              item.percentUpperThreshold = 0;
            }
            if (isNaN(item.leverage) && item.type === 'model') {
              item.leverage = 1;
            }

          } else {
            item.percentTarget = parseFloat(item.percentTarget); //parseFloat(item.newPercentTarget);
            item.newPercentTarget = parseFloat(item.newPercentTarget);
            vm.cashObj = item;
            var totalPercent = !isNaN(item.newPercentTarget) ? item.newPercentTarget : item.percentTarget;
            vm.totalCashPercent = parseFloat(totalPercent.toFixed(2));
          }

        }

        vm.totalTargetPercent = parseFloat(parseFloat(t).toFixed(2));
        vm.totalAllocated = vm.totalTargetPercent + vm.totalCashPercent;

        if (vm.totalAllocated > 100) {
          vm.errorMsg = 'Over allocated by ' + Math.abs(100 - vm.totalAllocated).toFixed(2) + '%. ';
        } else if (vm.totalAllocated < 100) {
          vm.errorMsg = 'Under allocated by ' + (100 - vm.totalAllocated).toFixed(2) + '%. ';
        } else {
          vm.errorMsg = null;
        }

        buildChartData(vm.targets);
      }

      function toggleFilters() {
        Amplitude.logEvent('Target Editor:Toggle filters');
        vm.isFiltersVisible = !vm.isFiltersVisible;
      }

      function addCashComponent() {
        // check for existing cash detail
        var existingCashDetail = TargetWeightFactory.getCashComponent(vm.targets);

        if (existingCashDetail.length > 0) {
          calculateTotalPercent();
          return false;
        }

        if (vm.targets.length){

          // sum targets to find what cash should be
          var totalAllocated = _.reduce(vm.targets, function(total, detail){
            return total += (detail.percent || detail.percentTarget);
          }, 0);
        }

        vm.targets.push({
          type: 'cash',
          name: "Cash",
          newPercentTarget: totalAllocated ? +math.format(100 - totalAllocated, {precision: 14}) : 100,
          percentTarget: totalAllocated ? +math.format(100 - totalAllocated, {precision: 14}) : 100,
          percentLowerPrecision: 4,
          percentLowerThreshold: 20,
          percentUpperPrecision: 4,
          percentUpperThreshold: 20
        });

        calculateTotalPercent();
      }

      function scaleCash() {

        var existingCashDetail = TargetWeightFactory.getCashComponent(vm.targets);

        if (existingCashDetail.length == 0) {
          return false;
        }
        existingCashDetail = existingCashDetail[0];

        if (parseFloat(vm.totalTargetPercent) < 100) {

          // added to fix issues where cash wasn't adjusting properly when manually scaling
          var difference = math.format(parseFloat((100 - parseFloat(vm.totalTargetPercent)).toFixed(2)), {precision:14});
          var newPercent = Math.abs(difference);
          existingCashDetail.newPercentTarget = truncate( math.format(newPercent, {precision:14}), 2);
          calculateTotalPercent();
        } else if (parseFloat(vm.totalTargetPercent) >= 100) {
          existingCashDetail.newPercentTarget = 0;
          calculateTotalPercent();
        }
      }



      function isLastPage() {
        return vm.tableParams.page() === totalPages();
      }

      function totalPages() {
        return Math.ceil(vm.tableParams.total() / vm.tableParams.count());
      }

      function toggleSubTargets(component) {
        if (typeof component.expanded == 'undefined') {
          component.expanded = true;
        } else {
          component.expanded = !component.expanded;
        }
      }

      function toggleExpandedTree() {
        vm.isTreeExpanded = !vm.isTreeExpanded;

        if (vm.isTreeExpanded) {
          vm.my_tree.expand_all();
        } else {
          vm.my_tree.collapse_all();
        }
      }

      function organizeTargetsForChart(targetDetails, totalAllocated) {

        var pie = TargetWeightFactory.formatDataForPie(targetDetails, totalAllocated);

        typeData = pie.assetTypes.sort(sortByType);
        equityData = pie.assets.sort(sortByType);
      }


      function sortByType(a, b) {

        if (a.type < b.type)
          return -1;
        if (a.type > b.type)
          return 1;
        return 0;
      }

      function buildChartData(targetDetails) {
        // Build the data arrays
        organizeTargetsForChart(targetDetails, vm.totalAllocated);
        updateChart(targetDetails);
      }

      function initHighCharts() {

        if ($scope.parentTab.active) {
          var pieChartConfig = TargetWeightFactory.getPieChartConfig(typeData, equityData);

          $timeout(function() {
            if( !document.getElementById('twgChart') ){ return; }
            vm.twgChart = Highcharts.chart('twgChart', pieChartConfig);
          }, 300);
        }
      }


      function updateChart(targetDetails) {

        if (vm.loading || !targetDetails.length || !typeData.length || !equityData.length) {
          return;
        }

        if (typeof vm.twgChart == 'undefined' || vm.twgChart == null || angular.equals(vm.twgChart, {})) {
          $timeout(function (){
            initHighCharts();
          });
        }

        $timeout(function() {
          if(/*!document.getElementById('twgChart') ||*/ typeof vm.twgChart == 'undefined' || vm.twgChart == null){ return; }

          if (vm.twgChart.series){
            vm.twgChart.series[0].setData(typeData);
            vm.twgChart.series[1].setData(equityData);
          }
        }, 500);
      }


      function blurTargetInputHandler(component) {
        component.newPercentTarget = parseFloat(component.newPercentTarget).toFixed(2);
        vm.calculateTotalPercent(false);
      }

    }
  }])
  /* .filter('toArray', function() {
    return function(obj) {
      if (!(obj instanceof Object)) return obj;
      return _.map(obj, function(val, key) {
        return Object.defineProperty(val, '$key', {
          __proto__: null,
          value: key
        });
      });
    };
  }) */
  .directive('inputEnter', function() {
    return function(scope, element, attrs) {
      element.bind("keydown keypress", function(event) {

        if (event.which === 13 && scope.vm.selectedSecurity) {

          scope.$apply(function() {
            // scope.$eval(attrs.myEnter);
            if (angular.isDefined(scope.vm.addToTarget)) {
              scope.vm.addToTarget(scope.vm.selectedSecurity);
              scope.vm.selectedSecurity = undefined;
            }
          });

          event.preventDefault();
        }
      });
    };
  });