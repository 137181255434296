angular.module('TargetWeightService', [])
  .factory('TargetWeightFactory', ['$http', '$rootScope', 'SmartXFactory', 'Dashboard', function($http, $rootScope, SmartXFactory, Dashboard) {

    var proposalDetails = [];
    var advisorTargets = [];
    var breadcrumbs = [];
    var activeTarget = null;
    var decimalLimit = 4;
    var colors = ['#3366CC', '#DC3912', '#FF9900', '#990099', '#109618', '#3B3EAC', '#0099C6', '#DD4477', '#66AA00', '#B82E2E', '#316395', '#994499', '#22AA99', '#AAAA11', '#6633CC', '#E67300', '#8B0707', '#329262', '#5574A6', '#3B3EAC'];
    var expandableTreeColumns = [{
        field: "Leverage",
        cellTemplate: "<span ng-if='row.branch[col.field]'>{{row.branch[col.field]}}</span>"
      }, {
        field: "TrackLeverage",
        displayName: 'Track Model Leverage',
        cellTemplate: "<span ng-if='row.branch[col.field]'>{{row.branch[col.field]}}</span>"
      }, {
        field: "Percent",
        displayName: 'Percent Allocated',
        cellTemplate: "<span class='text-right' ng-if='row.branch[col.field]'>{{row.branch[col.field] * 100 | number : 2 }}%</span>"
      }
    ];
    var userDefaults = {
      precision: {
        upper: 0.04, 
        lower: 0.04
      },
      threshold: {
        upper: 0.2,
        lower: 0.2
      }
    };

    var targetWeightGroupApi = SmartXFactory.getTargetWeightGroupAPI();
    // var enterpriseApi = SmartXFactory.getEnterprisesAPI();

    // function getEnterpriseModels (enterpriseId){
    //   return enterpriseApi.getModels(enterpriseId)
    // }

    function setProposalDetails (details) {
      proposalDetails = details;
    }

    function getProposalDetails (details) {
      return proposalDetails;
    }
    
    function getPieChartConfig (typeData, equityData) {
      return {
        chart: {
          type: 'pie'
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        plotOptions: {
          pie: {
            shadow: false,
            center: ['50%', '50%']
          },
          series: {
            states: {
              inactive: {
                opacity: 1
              }
            }
          }
        },
        tooltip: {
          valueSuffix: '%'
        },
        series: [{
          name: 'Type',
          data: typeData,
          size: '60%',
          dataLabels: {
            formatter: function() {
              return this.y > 5 ? this.point.name : null;
            },
            color: '#ffffff',
            distance: -30
          }
        }, {
          name: 'Position',
          data: equityData,
          size: '80%',
          innerSize: '60%',
          dataLabels: {
            formatter: function() {
              // display only if larger than 1
              return this.y > 1 ? '<b>' + this.point.name + ':</b> ' +
                this.y + '%' : null;
            }
          },
          id: 'versions'
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 400
            },
            chartOptions: {
              series: [{
                id: 'versions',
                dataLabels: {
                  enabled: false
                }
              }]
            }
          }]
        }
      };
    }

    function flattenDetails(details, flattenedDetails){

      if (!flattenedDetails) var flattenedDetails = [];
    
      details.forEach(function(detail){

        // ignore targetWeightGroups types if they have no details
        if (detail.target.type === 'targetWeightGroup' && !detail.details) return;

        // process nested details
        if (detail.details) {
          if (detail.target.type === 'targetWeightGroup'){
            
            // scale
            var scaledDetails = detail.details.map(function(subTargetDetail){
              subTargetDetail.percent *= detail.percent;
              return subTargetDetail;
            });
          }
          flattenDetails(scaledDetails || details.details, flattenedDetails);
        } 
        else flattenedDetails.push(detail);
      });
    
      return flattenedDetails;
    }

    function getTargetPermission(target){

      var user = Dashboard.getReplicatorUser();

      try {
        var environment = ENV.environment;
        var api = ENV.apiPath[environment];
        var url = "https://" + api.replace("/api", "") + "/target-permission.php";
      
        console.log("Getting target permissions for: ", user.id);
        //return $http.get("https://api-test.smartxadvisory.com/target-permission.php?user_guid=6ECCAB47-E039-4CEC-8809-00864A2834F7&target_guid=5B669D67-63A2-49D1-9E7F-111AC3C348EB");
        return $http.get(url, { "params": { "user_guid": user.id, "target_guid": target.id } });
      } catch (e) {
        console.error(e);
        return false;
      }
    }

    function createTarget(payload){
      return targetWeightGroupApi.create(payload);
    }

    function addDetail(id, payload){
      return targetWeightGroupApi.addDetail(id, payload);
    }

    function updateDetail(targetId, detailId, payload){
      return targetWeightGroupApi.updateDetail(targetId, detailId, payload);
    }

    function deleteDetail(targetId, detailId){
      return targetWeightGroupApi.deleteDetail(targetId, detailId);
    }

    function getDetail(targetId, detailId, query){
      return targetWeightGroupApi.getDetail(targetId, detailId, query);
    }

    function updateTarget(id, payload){
      return targetWeightGroupApi.update(id, payload);
    }

    function deleteTargetWeight(guid) {
      return targetWeightGroupApi.delete(guid);
    }

    function getAllTargetWeights(id, data) {
      resetBreadcrumbs();
      return targetWeightGroupApi.get(id, data || {});
    }

    function getTargetById(id){
      return targetWeightGroupApi.get(id);
    }

    function getTargetDetailByGUID(guid) {
      return targetWeightGroupApi.getDetails(guid);
    }

    function setAdvisorTargets(targets, broadcast) {
      advisorTargets = targets;
      if (broadcast !== false) $rootScope.$broadcast('set-advisor-targets', targets);
    }

    function getAdvisorTargets() {
      return advisorTargets;
    }

    function getAccessLevel (target) {
      var target = _.find(advisorTargets, {'id': target.id });
      // default to 'view only' if we can't find the requested target in the user's list
      return target ? target.accessLevel : 'view';
    }

    function setActiveTarget(target, stopBroadcast) {
      
      activeTarget = target;
      if (!stopBroadcast) {
        $rootScope.$broadcast('set-active-target');
      }

      if (activeTarget == null) return;

      // getTargetPermission(activeTarget)
      // .then(function(res){
      //   activeTarget.editable = res.data.access_granted == 'yes' ? true : false;
      // })
      // .catch(function(err){
      //   console.error(err);
      // });
    }

    function getActiveTarget() {
      return activeTarget;
    }

    function addBreadcrumb(crumb) {
      // console.log(breadcrumbs, crumb, containsObject(crumb, breadcrumbs));

      if (!containsObject(crumb, breadcrumbs)) {
        breadcrumbs.push(crumb);
        // 
      } else {

        var selectedIdx;
        var existingCrumb = breadcrumbs.filter(function(elem, i, array) {
          if (elem.id == crumb.id) {
            selectedIdx = i;
            return true;
          }
          return false;
        });

        if (selectedIdx + 1 < breadcrumbs.length) {
          breadcrumbs.splice(selectedIdx + 1);
        }
      }
      return breadcrumbs;
    }

    function resetBreadcrumbs() {
      breadcrumbs = [];
    }

    function getBreadcrumbs() {
      return breadcrumbs;
    }

    function setBreadcrumbs(crumbs) {
      breadcrumbs = crumbs;
      return breadcrumbs;
    }

    function containsObject(obj, list) {
      var i;
      for (i = 0; i < list.length; i++) {
        if (list[i].id === obj.id) {
          return true;
        }
      }
      return false;
    }

    function getUserDefaults(activeTarget) {

      if (!activeTarget) return userDefaults;

      // active target may come in as a flat or nested structure
      // comes in flat when clicking a sub-target link
      if (activeTarget.precision){
        return {
          precision: activeTarget.precision,
          threshold: activeTarget.threshold
        };
      } else if (activeTarget.percentUpperPrecision != undefined) {
        return {
          precision: {
            upper: activeTarget.percentUpperPrecision,
            lower: activeTarget.percentLowerPrecision
          },
          threshold: {
            upper: activeTarget.percentUpperThreshold,
            lower: activeTarget.percentLowerThreshold
          }
        };
      }

      // return hardcoded presets if nothing is defined for the active target
      return userDefaults;
    }

    function setUserDefaults(defaults) {
      userDefaults = defaults;
    }

    function getTotalPercentageForDetail(items) {

      if (items && items.length) {

        var total = items.reduce(function(totPercent, item) {
          return totPercent += item.percent;
        }, 0);

        return total.toFixed(4) * 1;
      } else {
        return null;
      }
    }

    function sumByType(items) {

      return items.reduce(function(a, b) {
        a = parseFloat(a);
        b = (typeof b.newPercentTarget != 'undefined') ? parseFloat(b.newPercentTarget) : parseFloat(b.percentTarget);
        return a + b;
      }, 0);
    }

    function sum(items, prop) {
      return items.reduce(function(a, b) {
        return parseFloat(a) + parseFloat(b[prop]);
      }, 0);
    }

    function getCashComponent(targets) {
      var existingComp = targets.filter(function(elem, i, array) {
        var type = (elem.type ? elem.type : elem.target.type).toLowerCase();
        if (type == 'cash') {
          selectedIdx = i;
          return true;
        }
      });

      return existingComp;
    }

    function formatAssetType(assetType){

      if (assetType == null) return assetType;
      //split camelCase status into words
      var words = assetType.match(/([A-Z]?[^A-Z]*)/g).slice(0,-1);

      // capitalize first letter of first word
      words[0] = words[0].charAt(0).toUpperCase() + words[0].substr(1);

      var formattedStatus = words.join(' ');

      // add an s
      if (!_.last(formattedStatus) == 's') formattedStatus + 's';

      return formattedStatus;
    }

    function getExistingDetails(details, payload) {
      return details.filter(function(elem, i, array) {

        switch (elem.type.toLowerCase()) {
          case 'model':
            return (elem.id || elem.modelId) == payload.id;
            break;
          case 'mutualfund':
          case 'equity':
          case 'symbols':
          case 'symbol':
            return (elem.id || elem.symbolId) == payload.id;
            break;
          case 'targetweightgroup':
            return (elem.id || elem.twgId) == payload.id;
            break;
          case 'cash':
            return elem.type == payload.type;
            break;
          default:
            return false;
        }
        // return elem.name == payload.name;
      });
    }

    function normalizeDetail(target) {
      var payload = {
        security: target.name || target.type,
        percent: parseFloat(target.newPercentTarget),
        leverage: target.leverage,
        percentUpperPrecision: target.percentUpperPrecision,
        percentUpperThreshold: target.percentUpperThreshold,
        percentLowerPrecision: target.percentLowerPrecision,
        percentLowerThreshold: target.percentLowerThreshold,
        matchModelLeverage: target.matchModelLeverage
      };

      return payload;
    }

    function buildTreeData(target, includeCash) {
      var treeData = [];

      target.forEach(function(target) {
        var obj = {};

        obj['Name'] = target.name || "Default Target";
        obj['Leverage'] = target.leverage;
        obj['TrackLeverage'] = null;
        obj['Percent'] = target.details.reduce(function(totPercent, item) {
          if (includeCash || item.target.type != 'cash') return totPercent += item.percent;
          else return totPercent;
        }, 0).toFixed(4);

        obj.children = [];

        addDetails(target.details, obj);
        treeData.push(obj);
      });

      return treeData;
    } 

    function addDetails(details, parentObj) {

      var detailGroups = _.groupBy(details, function(target){
        return target.target.type;
      });

      _.each(detailGroups, function(details, key) {

        var detailsObj = {};
        detailsObj['Name'] = details[0].target.type.capitalize();
        detailsObj['Leverage'] = null;
        detailsObj['TrackLeverage'] = null;
        detailsObj['Percent'] = getTotalPercentageForDetail(details);
        detailsObj.children = [];

        if (key == 'cash') return;

        if (details != null && details.length) {

          details.forEach(function(item) {
            var obj = {};

            if (item.target.type == 'cash'){
              obj['Name'] = '$CASH';
              obj['Leverage'] = null;
              obj['TrackLeverage'] = null;
              obj['Percent'] = item.percent;

            } else {
              if (item.name == null) {
              }
              obj['Name'] = (typeof item.target.name != 'undefined' && item.target.name != null) ? item.target.name.capitalize() : '';
              obj['Leverage'] = item.target.leverage;
              obj['TrackLeverage'] = null;
              obj['Percent'] = item.percent;
              obj.icons = {
                iconLeaf: 'glyphicon'
              };
              obj.children = [];

              if (item.target.type.toLowerCase() == 'model') {
                obj['TrackLeverage'] = item.target.matchModelLeverage ? 'Yes' : 'No';
              }
            }

            if (item.details) {
              addDetails(item.details, obj);
            }
            detailsObj.children.push(obj);
          });

          parentObj.children.push(detailsObj);

        } else {
          // if (detail && detail.percent) {
            var obj = {
              "Name": key.capitalize(),
              "Leverage": details[0].target.leverage,
              "TrackLeverage": details[0].target.matchModelLeverage,
              "Percent": details[0].percent
            };

            if (key == 'cash') {
              obj.icons = {
                iconLeaf: 'glyphicon glyphicon-usd'
              };
            }
            parentObj.children.push(obj);
          //}
        }

      });

      // add cash separately so it always appears at the bottom of the tree/table
      var obj = {
        "Name": 'Cash',
        "Leverage": null,
        "TrackLeverage": null,
        "Percent": detailGroups.cash ? detailGroups.cash[0].percent : 0,
        "icons": {
          iconLeaf: 'glyphicon glyphicon-usd'
        }
      };

      parentObj.children.push(obj);

      
    }

    function formatDataForPie (targetDetails, totalAllocated) {
      var _payload = [],
          _groups = [],
          _models = {},
          _symbols = {},
          _targets = {};

      _groups = [];
      typeData = [];
      equityData = [];

      var fModels = targetDetails.filter(function(elem, i, array) {
          var term = (typeof elem.assetType != 'undefined') ? elem.assetType : elem.type;
          return term.toLowerCase().match(/model/g);
        }),
        fFunds = targetDetails.filter(function(elem, i, array) {
          var term = (typeof elem.assetType != 'undefined') ? elem.assetType : elem.type;
          return term.toLowerCase().replace(' ', '') == 'mutualfund';
        }),
        fSymbols = targetDetails.filter(function(elem, i, array) {
          var term = (typeof elem.assetType != 'undefined') ? elem.assetType : elem.type;
          return (term.toLowerCase().match(/symbol/g) !== null || term.toLowerCase() == 'equity');
        }),
        fTargets = targetDetails.filter(function(elem, i, array) {
          var term = (typeof elem.assetType != 'undefined') ? elem.assetType : elem.type;
          return term.toLowerCase().match(/target/g); // == 'target weight group';
        });

      for (var i = 0; i < targetDetails.length; i++) {
        var _target = targetDetails[i];

        var term = (typeof _target.assetType != 'undefined') ? _target.assetType : _target.type;
        switch (term.toLowerCase().replace(/ /g, '')) {
          case 'cash':
            if (_target.newPercentTarget == null || isNaN(_target.newPercentTarget) || _target.newPercentTarget === "") {
              continue;
            }

            if (_groups.indexOf(_target.type) === -1) {
              var obj = {
                type: 'ycash',
                name: _target.type.capitalize(),
                y: parseFloat(_target.newPercentTarget),
                color: colors[4] || '#2c557f'
              };
              _groups.push(_target.assetType);
              typeData.push(obj);
              equityData.push(obj); // ensure there is only one entry for cash
            }
            // equityData.push(obj);
            break;
          case 'symbols':
          case 'symbol':
            if (_target.newPercentTarget == null || isNaN(_target.newPercentTarget) || _target.newPercentTarget === "") {
              continue;
            }

            var brightness = 0.2 - (i / vm.targets.length) / 5;
            equityData.push({
              name: _target.name,
              type: _target.assetType ? _target.assetType.toLowerCase().trim() : _target.type.toLowerCase().trim(),
              y: parseFloat(_target.newPercentTarget),
              color: new Highcharts.Color(colors[0]).brighten(brightness).get()
            });


            if (_groups.indexOf(_target.assetType) === -1) {
              var obj = {
                name: 'Equities',
                type: _target.type.toLowerCase().trim(),
                y: sumByType(fSymbols),
                color: colors[0] || '#2c557f'
              };
              _groups.push(_target.assetType);
              typeData.push(obj);
            }
            break;
          case 'equity':
            if (_target.newPercentTarget == null || isNaN(_target.newPercentTarget) || _target.newPercentTarget === "") {
              continue;
            }

            var brightness = 0.2 - (i / targetDetails.length) / 5;
            equityData.push({
              name: _target.name,
              type: _target.assetType.toLowerCase().trim(),
              y: parseFloat(_target.newPercentTarget),
              color: new Highcharts.Color(colors[0]).brighten(brightness).get()
            });


            if (_groups.indexOf(_target.assetType) === -1) {
              var obj = {
                name: 'Equities',
                type: _target.assetType.toLowerCase().trim(),
                y: sumByType(fSymbols),
                color: colors[0] || '#2c557f'
              };
              _groups.push(_target.assetType);
              typeData.push(obj);
            }
            break;

          case "model":
            if (_target.newPercentTarget == null || isNaN(_target.newPercentTarget) || _target.newPercentTarget === "") {
              continue;
            }

            var brightness = 0.2 - (i / targetDetails.length) / 5;
            equityData.push({
              name: _target.name,
              type: _target.type.toLowerCase().trim(),
              y: parseFloat(_target.newPercentTarget),
              color: new Highcharts.Color(colors[1]).brighten(brightness).get()
            });

            if (_groups.indexOf(_target.type) === -1) {

              var obj = {
                name: 'Models',
                assetType: _target.assetType,
                type: _target.type.toLowerCase().trim(),
                y: sumByType(fModels),
                color: colors[1] || '#2c557f'
              };
              _groups.push(_target.type);
              typeData.push(obj);
            }

            break;

          case 'mutualfund':
            if (_target.newPercentTarget == null || isNaN(_target.newPercentTarget) || _target.newPercentTarget === "") {
              continue;
            }

            var brightness = 0.2 - (i / targetDetails.length) / 5;
            equityData.push({
              name: _target.ticker,
              type: _target.assetType.toLowerCase().trim(),
              y: parseFloat(_target.newPercentTarget),
              color: new Highcharts.Color(colors[2]).brighten(brightness).get()
            });


            if (_groups.indexOf(_target.assetType) === -1) {
              var obj = {
                name: 'Mutual Funds',
                type: _target.assetType.toLowerCase().trim(),
                y: sumByType(fFunds),
                color: colors[2] || '#2c557f'
              };
              _groups.push(_target.assetType);
              typeData.push(obj);
            }
            break;

          case 'targetweightgroup':
            if (_target.newPercentTarget == null || isNaN(_target.newPercentTarget) || _target.newPercentTarget === "") {
              continue;
            }

            var brightness = 0.2 - (i / targetDetails.length) / 5;
            equityData.push({
              name: _target.name,
              type: _target.type.toLowerCase().trim(),
              y: parseFloat(_target.newPercentTarget),
              color: new Highcharts.Color(colors[3]).brighten(brightness).get()
            });

            if (_groups.indexOf(_target.type) === -1) {
              var obj = {
                name: "Targets",
                type: _target.type.toLowerCase().trim(),
                y: sumByType(fTargets),
                color: colors[3] || '#2c557f'
              };
              _groups.push(_target.type);
              typeData.push(obj);
            }
            break;
        }
      }

      if (totalAllocated != 100) {

        var obj = {
          name: totalAllocated > 100 ? 'Over Allocated' : 'Under Allocated',
          type: 'zAvailable', //Show as last pie chart item
          y: Math.abs(100 - totalAllocated),
          color: totalAllocated > 100 ? '#8b0000' : '#000000'
        };
        typeData.push(obj);
        equityData.push(obj);
      }

      return {
        assetTypes: typeData,
        assets: equityData
      };
    }

    function formatTargetDetails(details) {
      var temp = [];
      
      details.forEach(function(component){
        var targetKey = component.target ? component.target.type : component.type;
        
        switch (targetKey) {
          case 'model':
          case 'targetWeightGroup':
          case 'symbol':

            if (typeof component == null) {
              break;
            }

            var element = _.extend({}, component.target);
            
            if (component.id) {
              element.detailId = component.id;
            }

            element.assetType = formatAssetType(element.assetType || element.type);

            if (element.processed == true) {
              temp.push(element);
              break;
            }

            element.companyName = targetKey === 'symbol' ? component.target.companyName : component.target.name;
            element.percentTarget = +(component.percent * 100).toFixed(2);
            element.newPercentTarget = +element.percentTarget;
            if (targetKey === 'model') element.leverage = component.target.leverage; //component.leverage ? +component.leverage : (component.leverage || 1);
            element.percentLowerThreshold = component.threshold.lower * 100;
            element.percentUpperThreshold = component.threshold.upper * 100;
            element.percentLowerPrecision = component.precision.lower * 100;
            element.percentUpperPrecision = component.precision.upper * 100;
            element.precision = component.precision;
            element.threshold = component.threshold;
            element.processed = true;
            
            temp.push(element);
            break;
          case 'cash':

            if (component != null) {
              var element = _.extend({}, component.target);
              var multiplier = (component.processed == true) ? 1 : 100;            

              if (component.id) {
                element.detailId = component.id;
                element.id = component.id;
              }

              element.type = targetKey;
              element.assetType = component.target.assetType ? component.target.assetType : component.target.type;
              element.percentTarget = +(component.percent * 100).toFixed(2);
              element.newPercentTarget = +element.percentTarget;
              element.percentLowerThreshold = component.threshold.lower * multiplier;
              element.percentUpperThreshold = component.threshold.upper * multiplier;
              element.percentLowerPrecision = component.precision.lower * multiplier;
              element.percentUpperPrecision = component.precision.upper * multiplier;
              element.precision = component.precision;
              element.threshold = component.threshold;
              element.processed = true;

              temp.push(element);
            }

            break;
          case 'information':
            
            // vm.accountsSubscribed = group.subscribedAccountCount;
            break;
          default:
            console.log('Default Block');
        }
      });

      return temp;
    }

    function getTargets (id, useCache, params){
      if (useCache && advisorTargets && advisorTargets.length){
        return Promise.resolve({
          data: {
            data: advisorTargets
          }
        });
      } else if (id) {
        return targetWeightGroupApi.getTargets(id);
      } else {
        return targetWeightGroupApi.getTargets(null, params || {});
      }
    }

    // gets the list of targets that are used by accounts
    function getUsedTargets () {
      return targetWeightGroupApi.getTargets(null, {isUsed: true});
    }

    function getTargetsAccounts () {
      return targetWeightGroupApi.getTargetsAccounts();
    }

    function addTargetDetails (details) {
      return targetWeightGroupApi.addDetails(details);
    }

    function updateDetails (id, details) {
      return targetWeightGroupApi.updateDetails(id, details);
    }

    function getFlattenedDetails (id) {
      return targetWeightGroupApi.getFlattenedDetails(id);
    }

    function getAccountsByTarget (id) {
      return targetWeightGroupApi.getAccountsByTarget(id);
    }

    return {
      getUsedTargets: getUsedTargets,
      formatTargetDetails: formatTargetDetails,
      getPieChartConfig: getPieChartConfig,
      formatDataForPie: formatDataForPie,
      expandableTreeColumns: expandableTreeColumns,
      buildTreeData: buildTreeData,
      normalizeDetail: normalizeDetail,
      getExistingDetails: getExistingDetails,
      getCashComponent: getCashComponent,
      sum: sum,
      // sumByType: sumByType,
      getTotalPercentageForDetail: getTotalPercentageForDetail,
      formatAssetType: formatAssetType,

      addBreadcrumb: addBreadcrumb,
      resetBreadcrumbs: resetBreadcrumbs,
      getBreadcrumbs: getBreadcrumbs,
      setBreadcrumbs: setBreadcrumbs,

      getUserDefaults: getUserDefaults,
      setUserDefaults: setUserDefaults,

      getTargetPermission: getTargetPermission,

      flattenDetails: flattenDetails,

      getAdvisorTargets: getAdvisorTargets,
      setAdvisorTargets: setAdvisorTargets,
      getActiveTarget: getActiveTarget,
      setActiveTarget: setActiveTarget,
      getAccessLevel: getAccessLevel,

      //assign: assignToAccounts,
      
      // target crud methods
      get: getAllTargetWeights,
      createTarget: createTarget,
      updateTarget: updateTarget,
      getTargetById: getTargetById,
      deleteTargetWeight: deleteTargetWeight,

      // getEnterpriseModels: getEnterpriseModels,

      // target detail crud methods
      addDetail: addDetail,
      getDetail: getDetail,
      updateDetail: updateDetail,
      deleteDetail: deleteDetail,
      getTargetDetailByGUID: getTargetDetailByGUID,
      getTargets: getTargets,
      getTargetsAccounts: getTargetsAccounts,
      addTargetDetails: addTargetDetails,
      updateDetails: updateDetails,

      getProposalDetails: getProposalDetails,
      setProposalDetails: setProposalDetails,

      getFlattenedDetails: getFlattenedDetails,
      getAccountsByTarget: getAccountsByTarget
    };

  }]);
