import $ from 'jquery';
import jQueryBridget from 'jquery-bridget';
import 'fizzy-ui-utils';
import 'desandro-matches-selector';
import 'outlayer';
import 'get-size';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

// make Masonry and imagesLoaded jQuery plugins
jQueryBridget( 'masonry', Masonry, $ );
jQueryBridget( 'imagesLoaded', imagesLoaded, $);

angular
  .module('ModelCtrl', ['modelTree'])
  .filter('percent', ['$filter', function($filter) {
    return function(input, decimals) {

      if (typeof input === 'undefined' || input === '') {
        return '';
      }

      input = (input && typeof input === 'string') ? input.replace(/,/g, '') : input; // in case strings come in with a separator

      if (!_.isEmpty(input) && !isNaN(input)) {
        if (input.indexOf("%") == -1) {
          input = Number(input).toFixed(2) + '%';
        }
      } else {
        input = '--';
      }

      return input;

    };
  }])
  .filter('substitutePlatformName', function(USER) {
    return function(text) {
      // var platformName = (typeof Drupal.settings != 'undefined') ? /* Drupal.settings.white_label_name */ USER.enterprise : 'SMArtX';
      var platformName = USER.enterprise ? USER.enterprise.title : 'SMArtX Advisory Solutions LLC';
      text = (typeof text !== 'undefined') ? text.replace(/##SITE_PLATFORM##/g, platformName) : '';
      text = text.replace(/s’s/g, "s’"); // solutions’s -> solutions’
      return text;
    };
  })
  .filter('removeNbsp', function() {
    return function(text, length) {
      text = (typeof text !== 'undefined') ? text.replace(/<div>&nbsp;<\/div>/g, '').replace(/<p>&nbsp;<\/p>/g, '') : '';
      return text;
    };
  })
  .filter('limitHtml', function() {
    return function(text, limit) {

      var changedString = String(text).replace(/<[^>]+>/gm, '');
      var length = changedString.length;

      return changedString.length > limit ? (changedString.substr(0, limit - 1) + ' ...') : changedString;
    };
  })
  .controller('models', ['$scope', '$window', '$http', '$timeout', 'Dashboard', 'Tabs', 'Models', 'SearchFilters', 'USER', '$q', 'Builder', 'toastr', '$sce', '$uibModal', '$templateCache', '$location', '$anchorScroll', 'SmartXFactory', 'Allocations', '$rootScope', 'EnterpriseFactory', 'FavoriteModels', 'DRUPAL_API_URL', function($scope, $window, $http, $timeout, Dashboard, Tabs, Models, SearchFilters, USER, $q, Builder, toastr, $sce, $uibModal, $templateCache, $location, $anchorScroll, SmartXFactory, Allocations, $rootScope, EnterpriseFactory, FavoriteModels, DRUPAL_API_URL) {

    var vm = this,
      parentTab = $scope.tab,
      comparisonModels = [],
      modelNames = {},
      tabType = parentTab.type,
      currentYear = moment().year(),
      currentMonth = moment().month(),
      redrawTree,
      loadedModel = Models.currentModel,
      platform = USER.enterprise, /* Drupal.settings.white_label_name, */
      reportedModelApi = SmartXFactory.getReportedModelAPI(),
      benchmarkApi = SmartXFactory.getBenchmarkAPI(),
      modelApi = SmartXFactory.getModelAPI(),
      performanceApi = SmartXFactory.getPerformanceAPI(),
      modelList = Models.getModelList(),
      reportedModelList = Models.getReportedModelList(),
      modelMinimumsDict = Models.getMinimums(),
      modelGuid,
      smartxGuid,
      mailToBase,
      topLevelEnterpriseId = EnterpriseFactory.getTopLevelEnterpriseId(),
      userEnterprise = EnterpriseFactory.getUserEnterprise();

    vm.topLevelDrupalEnterprise = EnterpriseFactory.getTopLevelDrupalEnterprise();
    vm.FavoriteModels = FavoriteModels;
    vm.FavoriteModels.getFavoriteModels()
    .then(() => {
      $scope.$apply();
    });

    vm.toggleFavoriteModel = function() {
      vm.FavoriteModels.addModelToFavorites(vm.model.smartx_guid)
      .then(() => {
        $scope.$apply();
      });
    };

    vm.hasMutualFunds = function() {
      return loadedModel.asset_class && loadedModel.asset_class.includes('Mutual Funds');
    };

    try {
      // loadedModel = angular.isDefined(Models.currentModel.data.node) ? Models.currentModel.data.node : parentTab.model;
      modelGuid = (typeof Models.currentModel !== 'undefined' && typeof Models.currentModel.reported_guid !== 'undefined') ? Models.currentModel.reported_guid : (Models.currentModel.id/* || Models.currentModel.smartx_guid*/);
      smartxGuid = Models.currentModel.smartx_guid || Models.currentModel.id || Models.currentModel.guid;
      vm.model = angular.copy(loadedModel);
      vm.modelMetrics = undefined;
      vm.loading = true;

      // Models.getFlags(smartxGuid)
      // .then(function(res){
      //   var filteredItems = res.data.data.filter(function(item){
      //     return item.statusDefinition.definition
      //   })

      //   $scope.ddItems = _.sortBy(filteredItems, function(item){
      //     return item.statusDefinition.sequence
      //   })
      // })
      // .catch(function(err){
      //   console.error(err)
      // })

      SmartXFactory.getCMSModelById(modelGuid, modelGuid == smartxGuid ? 'smartx_guid' : 'reported')
        .then(function(res) {

          // console.log('SmartXFactory.getCMSModelById', res, vm.model);
          vm.modelInfo = res;
          vm.model = _.extend(vm.model, res);
          vm.loading = false;
          $scope.$apply();
          
          vm.initModel();

          return SmartXFactory.getCommentaryForModel(Models.currentModel.nid || vm.model.nid, 25);
        })
        .then(function(res) {

          vm.model.commentary = res;
          //  console.log('SmartXFactory.getCommentaryForModel', res, vm.model);

        })
        .catch(function(err) {
          console.error(err);
          showModelNotFound();
        });

      if (typeof smartxGuid !== 'undefined' && smartxGuid !== '') {
        initSmartXModel();
      } else if (typeof smartxGuid == 'undefined' && typeof modelGuid == 'undefined') {
        console.error('SmartX Guid and Reported Model Guid not found');
        showModelNotFound();
      }

      if (typeof Models.currentModel.nid !== 'undefined') {

        SmartXFactory.getCommentaryForModel(Models.currentModel.nid, 25)
          .then(function(res) {

            vm.model.commentary = res;              
            // console.log('SmartXFactory.getCommentaryForModel', res, vm.model);

          });
      }
      // console.log(Models.currentModel);

    } catch (e) {
      modelGuid = parentTab.model.reported_guid;
      loadedModel = parentTab.model;
      vm.model = {};
    }

    // loadedModel.title = parentTab.title;

    // console.log("Model tab loaded with model: ", loadedModel);

    // side tabs becomes a dropdown on smaller screens
    vm.dropdown = {
      isOpen: false
    };

    vm.USER = USER;
    vm.envIsDev = Drupal.settings.pantheon_environment !== 'live' && Drupal.settings.pantheon_environment !== 'test' && Drupal.settings.pantheon_environment !== 'smartx-v2';
    vm.basePath = window.location.origin + '/sites/default/files/styles/thumbnail/public/';

    vm.modelName = parentTab.title || Models.currentModel.title;

    vm.currentTime = new Date().getTime();

    // default value so top holdings tables don't error when checking length of holdings
    vm.longHoldings = [];
    vm.shortHoldings = [];
    vm.defaultDisclaimerReadMore = false;
    vm.hideDueDiligenceTab = false;
    vm.defaultDisclaimerLimit = 200;
    vm.modelFound = true;

    $timeout(function(){
      vm.holdingsTreeWidth = jQuery('#model-overview-' + vm.model.guid).width();
      console.log("Initialized Tree Width: ", vm.holdingsTreeWidth);
    }, 1000);

    var masonryLoaded = false;
    $scope.$watch('vm.sideMenu[5].active', function(newVal, oldVal){
      // console.log(newVal, oldVal);
      if (masonryLoaded || !newVal) return; 

      $timeout(function () { 
        masonryLoaded = true;
        $rootScope.$broadcast('masonry.reload'); 
      }, 100);
    });

    vm.model.tree = {
      level: 0,
      drilled: false,
      drillup: undefined
    };

    if (vm.model.treeMap || vm.model.modelChart) {
      vm.model.treeMap = vm.model.modelChart = undefined;
    }

    if (parentTab.active) {
      vm.visited = true;
    } else {
      vm.visited = false;
    }

    vm.model.modelChart = {
      title: {
        text: null,
        style: {
          display: 'none'
        }
      }
    };

    vm.modelDisclaimerPopover = {
      content: "This model is a static model. In a static model, the model manager sets a portfolio weight for each holding, and the portfolio weight will remain the same until updated by the model manager. Because the assets in the underlying portfolio stay at a static weight, SmartX cannot calculate a performance stream that would accurately represent the performance of a client portfolio had the client been invested in the strategy over the same time frame. Therefore, all performance data on static models are provided directly from the model manager.",
      templateUrl: 'modelDisclaimerPopoverTemplate.html'
    };

    vm.errorLoadingModelData = false;

    vm.model.currentStep = 1;

    vm.modelPerformanceMsg = "Loading Model...";

    vm.windowHeight = jQuery(window).height();

    vm.sideMenu = [{
        title: 'Overview',
        active: true
      },
      {
        title: 'Performance',
        active: false
      },
      {
        title: 'Statistics',
        active: false
      },
      {
        title: 'About Us',
        active: false
      },
      {
        title: 'Recent News',
        active: false
      },
      {
        title: 'Viewpoints',
        active: false
      },
      {
        title: 'Disclosure',
        active: false
      },
      {
        title: 'Powerpoint',
        active: false,
        empty: true
      }
    ];

    vm.currentPage = vm.sideMenu[0].title;

    vm.generatingReport = false;

    vm.models = Models.getModelList();

    var platform = USER.enterprise/* Drupal.settings.white_label_name */;
    var platformFromAccess = Dashboard.getPlatform();

    vm.platform = platformFromAccess.name;

    vm.isDeployModel = (vm.model.field_deploy_model && vm.model.field_deploy_model.und) ? Boolean(+vm.model.field_deploy_model.und[0].value) : false;

    var modelData = {
      'model_ids': smartxGuid,
      'percentages': 100,
      'leverages': 1
    };
    
    // get the custom cc address if it exists on the top level enterprise
    var modelEmailCC = vm.topLevelDrupalEnterprise ? vm.topLevelDrupalEnterprise.model_email_cc : null;
    mailToBase = "support@smartxadvisory.com?subject=Requesting information about " + vm.modelName;
    vm.mailTo = modelEmailCC ? mailToBase + '&cc=' + modelEmailCC : mailToBase;

    function showModelNotFound() {

      $timeout(function() {
        vm.modelFound = false;
        vm.loading = false;
      }, 0);
    }

    function isStatic (id) {
      var model = _.findWhere(reportedModelList, {id: id});
      if (model){
        return model.isStatic;
      }

      return false;
    }

    function initSmartXModel() {
      SmartXFactory.getModelById(smartxGuid.toLowerCase())
        .then(function(resp) {
          vm.modelMetrics = resp;
          vm.isStaticModel = isStatic(resp.reportedModelId);
          getPortfolioPerformanceFromDashboard();
      });
    }


    function modelHasRecordedWebinar() {

      var nid = vm.modelInfo.nid;
      if (typeof nid != 'undefined') {

        var url = `${DRUPAL_API_URL}/ng_api/v2/webinar/videos/${nid}`,
          req = {
            method: 'GET',
            url: url,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          };

        $http(req)
          .then(function(result) {
            if (typeof result.data.recordedWebinars != 'undefined') {
              vm.viewpointWebinar = result.data.recordedWebinars[0];
            }
          })
          .catch(function(err){
            console.error(err.message);
          });
      }
    }

    vm.gotoBottom = function(hash) {
      $window.scrollTo(0, jQuery(document).height());
    };


    vm.convertArticleLink = function(link) {
      if (link.indexOf('commentary/') !== -1) {
        var uriComponents = link.split('commentary/');
        // handle filenames with unfriendly characters. ex: Q1+2021+SMid+Dividend+Value+Brochure.pdf
        uriComponents[1] = encodeURIComponent(uriComponents[1]);
        link = uriComponents.join('commentary/');
      }
      link += '#view=FitH';
      return $sce.trustAsResourceUrl(link.replace('s3://', location.protocol + '//managerfiles.s3.amazonaws.com/'));
    };

    function formatModelBodyText(bodyText, platformName) {

      bodyText = bodyText.replace(/<p>&nbsp;<\/p>/g, '').replace(/HedgeCoVest/g, platformName);
      bodyText = bodyText.replace(/HedgeCoVest/g, 'SMArtX');
      return bodyText;
    }

    function getYTD(monthlyReturns) {

      var YTD;

      // make sure the values are in the right order
      var values = _.sortBy(monthlyReturns, function(num, key) {
        return key;
      });

      var percentAsFloat = values.reduce(function(currentTotal, newValue) {
        if (angular.isDefined(newValue)) {
          return currentTotal * ((newValue / 100) + 1);
        } else {
          return currentTotal * 1;
        }
      }, 1);

      YTD = (percentAsFloat - 1) * 100;

      return YTD;
    }

    vm.months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    vm.colorReturns = function(monthlyReturn) {
      if (monthlyReturn < 0) {
        return "#f26203";
      } else {
        return "black";
      }
    };

    function calculateYTDValues(monthlyPerformance){
      // calculate the YTD values for each year in the model monthly_performance
      var ytdValues = {};

      _.each(monthlyPerformance, function(returns, year) {
        ytdValues[year] = getYTD(returns);
      });

      return ytdValues;
    }

    function getMaxDrawdown(drawdowns){

      // drawdown.max is a negative Number
      // so the min of those max drawdowns becomes our max
      return _.min(_.map(drawdowns, function(drawdown) {
        return drawdown.max * 100;
      }));
    }

    function getPortfolioPerformanceFromDashboard() {

      performanceApi.portfolioPerformance(modelData)
        .then(function(result) {

          if (result.data.status == 'fail' || _.isEmpty(result.data.models)) {
            toastr.error('Error getting performance data');
            vm.gotPerformanceData = false;
            return false;
          }

          // debugger
          // console.log("model performance ", result);
          vm.gotPerformanceData = true;
          vm.modelPerformance = result;

          if (smartxGuid) { smartxGuid = smartxGuid.toLowerCase(); }

          var payload = result.data.models[smartxGuid];
          var monthlyPerformance = payload.monthly_performance;
          var monthlyModelPerformance = monthlyPerformance.simple_hist;
          var monthlyModelStatistics = payload.monthly_statistics;
          var monthlyBenchmarkPerformance = monthlyPerformance.benchmark_hist;
          var monthlyBenchmarkStatistics = payload.monthly_benchmark_statistics;
          var dailyPerformanceStream = payload.windows["-1"].model.performance;

          var mtdValue = _.last(_.values(monthlyModelPerformance)) * 1;
          var ytdValue = +payload.ytd;
          var todayValue =  payload.information.today; //_.last(_.values(dailyPerformanceStream));
          var modelMinimumForId = modelMinimumsDict[smartxGuid];
          var modelName;

          vm.performanceOverview = {};
          vm.YTDvalues = calculateYTDValues(monthlyPerformance.model);

          if (typeof payload.information == 'undefined' || payload.information == null) {
            toastr.error("Model Information Not Found");
            return false;
          }

          modelName = payload.information.name || loadedModel.title;

          try {
            vm.benchmarkName = payload.information.benchmarks[0].name;
          } catch (e) {
            vm.benchmarkName = 'N/A';
          }

          vm.model.performance = monthlyModelPerformance;
          vm.model.benchmark = monthlyBenchmarkPerformance;

          vm.modelTotalReturns = monthlyModelStatistics.annualized;
          vm.benchmarkTotalReturns = monthlyBenchmarkStatistics.annualized;

          vm.modelMaxDrawdowns = _.map(monthlyModelStatistics.max_drawdowns, function(drawdown, index) {
            return drawdown;
          });

          vm.performanceOverview.maxDrawdown = getMaxDrawdown(vm.modelMaxDrawdowns);

          vm.performanceOverview.symbolCount = payload.information.symbolCount;
          vm.performanceOverview.totalFees = payload.information.totalFees;
          vm.performanceOverview.minimumAlloc = modelMinimumForId ? modelMinimumForId.modelMinimum : payload.information.minimumAllocation;

          vm.lastDailyReturnDate = payload.information.asOf * 1000; // _.last(Object.keys(dailyPerformanceStream)) * 1000;
          vm.lastMonthlyReturnDate = _.last(Object.keys(monthlyModelPerformance)) * 1000;

          vm.firstMonthlyReturnDate = _.first(Object.keys(monthlyModelPerformance)) * 1000;

          vm.performanceOverview.mtd = isNaN(mtdValue) ? '--' : mtdValue; // get the last value in the monthly performance stream
          vm.performanceOverview.ytd = isNaN(ytdValue) ? '--' : ytdValue;
          vm.performanceOverview.today = isNaN(todayValue) ? '--' : todayValue;

          vm.turnoverStatistic = vm.model.turnover; //vm.model.field_turnover_statistic['und'] ? vm.model.field_turnover_statistic['und'][0].value : null;

          vm.statistics = {
            "model": monthlyModelStatistics,
            "benchmark": monthlyBenchmarkStatistics
          };

          Builder.getPerformanceTableData(vm.statistics.model, vm.statistics.benchmark)
          .then(function(stats){
            vm.statisticsTable = stats;
          });

          vm.modelMonthlyReturns = monthlyPerformance.model;
          vm.years = _.keys(monthlyPerformance.model).reverse(); // descending years so the current year becomes the first row in performance table

          vm.startedTracking = payload.information.startedTracking.timestamp || payload.information.startedTracking;
          vm.totalFees = payload.information.totalFees;

          vm.modelMetrics.getHoldings()
          .then(function(data) {
            vm.modelMetrics.holdings = data.data.data;
            vm.holdings = vm.modelMetrics.holdings;

            vm.model.loadingTreeData = false;

            if (!_.isEmpty(vm.holdings)) {
              vm.gotModelData = true;
              vm.model.loadingTreeData = false;
              $timeout(function() {
                buildTreev2(vm.holdings);
              }, 100);
            } else {
              vm.gotModelData = false;
            }

            // console.log('SmartXFactory.getModelById/getHoldings', vm.modelMetrics);
          })
          .catch(function(err){
            // console.error(err);
            // toastr.error(err.message);
            Dashboard.toastError(err.message, err);
          });

        });
    }


    vm.dateStrToTimestamp = function(date) {

      date = date.replace(/-/g, '/');

      var d = new Date(date).getTime();
      return moment(d).format();
    };

    vm.getVimeoURLFromId = function(url) {
            if (url.indexOf('vimeo') >= 0) {
        var a = url.split('/');
        return $sce.trustAsResourceUrl('https://player.vimeo.com/video/' + a[a.length - 1]);
      }
      else if(url.indexOf('youtube') >= 0) {
        var y = (typeof url == 'string') ? url.split('=') : url[0].value.split('=');
    		return $sce.trustAsResourceUrl('https://www.youtube.com/embed/'+y[y.length - 1]);
      }
      return $sce.trustAsResourceUrl(url);
    };

    vm.formatBodyText = function(text) {
      var platformName = Drupal.setting.white_label_name;
      var bodyText = bodyText.replace(/HedgeCoVest/g, 'SMArtX');

      return bodyText;
    };

    vm.monthHasReturn = function(month) {
      if (month == null || month == undefined || month == 'NaN') {
        return false;
      } else {
        return true;
      }
    };

    vm.makePDF = function() {

      vm.generatingReport = true;

      var deferred = $q.defer(),
        model = vm.model,
        modelInfo = vm.modelInfo,
        rid = vm.model.data_id ? vm.model.data_id : null,
        guid = smartxGuid || vm.model.id || vm.model.guid;

      var pdfData = {
        model: {
          value: model.node.body.und[0].value,
          title: vm.modelName || model.name,
          summary: model.node.body.und[0].summary ? model.node.body.und[0].summary.replace('SUMMARY - ', '') : 'N/A',
          performanceTable: jQuery('.model-performance-table-body-' + vm.model.nid).html(),
          fee: vm.totalFees != null && vm.totalFees != undefined ? vm.totalFees : 'N/A',
          benchmarkName: vm.benchmarkName,
          disclosures: vm.isStaticModel ? $templateCache.get('staticModelDisclaimer.html') : modelInfo.default_disclosure,
          customDisclosure: model.custom_disclosure
        },
        enterpriseId: topLevelEnterpriseId || userEnterprise.id,
        templateName: 'model-report.twig',
        templateHeader: 'blend-report-header.twig',
        templateFooter: 'model-report-footer.twig',
        logoPath: (jQuery('#smartx-pdf-logo').length >= 1) ? jQuery('#smartx-pdf-logo').attr('src') : jQuery('#logo').attr('src'),
        includeCover: false,
        user: Drupal.settings.fullName,
        riaFirm: Drupal.settings.ria_firm,
        static: vm.isStaticModel
      };

      // console.log(pdfData)
          
      var result = vm.modelPerformance; // result from the initial call to get the model performance and statistics

      var payload = result.data.models[guid];
      var monthlyPerformance = payload.monthly_performance;
      var monthlyModelPerformance = monthlyPerformance.simple_hist;
      var monthlyModelStatistics = payload.monthly_statistics;
      var monthlyBenchmarkPerformance = monthlyPerformance.benchmark_hist;
      var monthlyBenchmarkStatistics = payload.monthly_benchmark_statistics;

      monthlyModelStatistics.ytd = vm.performanceOverview.ytd;

      vm.model.performance = monthlyModelPerformance;

      try {
        vm.model.benchmark = monthlyBenchmarkPerformance;
      } catch (e) {
        console.error(e);
        vm.gotPerformanceData = false;
        vm.model.benchmark = undefined;
      }

      if (angular.isDefined(payload) && payload.monthlyPerformance !== null && vm.model.performance && vm.model.benchmark) {

        vm.gotPerformanceData = true;

      } else {
        vm.gotPerformanceData = false;
      }
      var performanceData = {
        model: vm.model.performance,
        benchmark: vm.model.benchmark
      };

      pdfData.model.statistics = monthlyModelStatistics;
      pdfData.model.benchmarkStatistics = monthlyBenchmarkStatistics;
      pdfData.model.performance = performanceData;

      pdfData.performanceStats = Models.formatStatistics(pdfData.model.statistics, pdfData.model.benchmarkStatistics);

      result = vm.buildChart(vm.model.performance, vm.model.benchmark);
      pdfData.model.chartData = {
        benchmarkData: result.benchmarkData,
        modelData: result.modelData,
        modelName: model.name
      };

      debugger;

      Models.makePDF(pdfData)
      .then(function(res) {
        vm.generatingReport = false;
        var blob = new Blob([res.data], {
          type: "application/pdf"
        });
        saveAs(blob, model.name.trim() + '.pdf' || 'model_report.pdf');
      })
      .catch(function(res) {
        vm.generatingReport = false;
        vm.errorGeneratingReport = true;
        var msg = _.isEmpty(res) ? 'An unexpected error occurred while generating the fact sheet.' : res;
        toastr.error('An unexpected error occurred while generating the document.');
      });
    };

    vm.selectModelForComparison = function(model) {
      var rid = model.guid.toLowerCase();
      modelNames[rid] = model.name;
      comparisonModels.push(rid);
    };

    vm.model.setStep = function(step) {

      vm.model.currentStep = step;

      if (step === 1) {
        if (angular.isDefined(vm._holdings) && vm._holdings !== null && vm.model.tree.level === 0) {
          $timeout(function() {
            drawChart(vm._holdings);
          }, 100);
        }
      } else if (step === 2) {

        if (vm.model.modelChartInstance) {
          $timeout(function() {
            vm.chartWidth = jQuery('#total-returns-table-' + vm.model.guid).width() + 'px'; // use id with unique model guid so that other model tabs aren't affected
            // console.log("Line Chart Width: ", vm.chartWidth)
            vm.model.modelChartInstance.reflow();
          }, 100);
        } else {
          $timeout(function(){
            vm.chartWidth = jQuery('#total-returns-table-' + vm.model.guid).width() + 'px'; // use id with unique model guid so that other model tabs aren't affected
            // console.log("Line Chart Width: ", vm.chartWidth)
            vm.buildPerformanceChart(vm.model.performance, vm.model.benchmark);
          }, 500);
        }
      }
    };

    // checks to see if it's time to show the tree based on scroll position
    vm.model.checkElement = function() {

      var tree = document.getElementById('tree-' + vm.model.nid);

      if (parentTab.active && angular.isDefined(tree) && tree !== null) {

        var treeTopPosition = tree.getBoundingClientRect().top;

        // should become true when top of the tree is at the bottom of the viewport
        // this prevents the animation from occuring until the tree container is scrolled into view
        if (treeTopPosition < vm.windowHeight) {

          $timeout(function() {
            drawChart(vm._holdings);

          }, 100);

        } else {
          return false;
        }
      }
    };

    $scope.$on("tabSwitched::" + tabType, function(event, tab) {
      if (tab.type === 'model') {
        var parentTabGuid = parentTab.modelId || parentTab.model.guid;
        // logic that opens model tabs needs to be refactored to eliminate having to check multiple values
        if ((loadedModel.guid || loadedModel.reported_guid || loadedModel.id) === parentTabGuid && parentTab.active) {
          if (!vm.visited) {
            vm.visited = true;

            if (vm.gotModelData) {
              vm.errorLoadingModelData = false;
              var guid = vm.model.guid ? vm.model.guid.toLowerCase() : undefined;

              $timeout(function() {

                // use v2 if guid if one is present otherwise default to the legacy id version
                if (guid) {
                  vm.holdingsTreeWidth = jQuery('#model-overview-' + vm.model.guid).width();
                  buildTreev2(vm.holdings);
                } else {
                  toastr.error("Unable to get holdings due to missing model guid");
                  return false;
                }

              }, 1);
            } else {
              vm.errorLoadingModelData = true;
            }
          } else if (vm.model.currentStep === 1 && vm.model.tree.level === 0) {
            // only redraw if tree is at initial level
            $timeout(function() {
              vm.holdingsTreeWidth = jQuery('#model-overview-' + vm.model.guid).width();
              if (angular.isDefined(vm._holdings) && vm._holdings !== null) {
                  drawChart(vm._holdings);
              } else {
                drawChart(vm.holdings);
              }
            }, 100);

          } else if (vm.model.currentStep === 2) {

            // modelChartInstance is assigned to vm.model inside of the performanceChart.js directive once the chart is initialized
            if (angular.isDefined(vm.model.modelChartInstance)) {
              $timeout(function() {
                vm.model.modelChartInstance.reflow();
              }, 1);
            }
          }
        }
      }
    });

    vm.initModel = function() {
      var nid,
        guid;

      vm.errorLoadingModelData = false;

      if (typeof vm.model != 'undefined') {
        nid = vm.model.nid;
        guid = vm.model.reported_guid.toLowerCase();

        if (guid) {

          reportedModelApi.getHoldings(guid)
            .then(function(res) {
              vm.model.loadingTreeData = false;

              if (res.data.statusCode !== 200) {
                vm.gotModelData = false;
                toastr.error("Unable to get model holdings at this time.");
                // console.log(res.data.msg[0].description);
                return false;
              }

              // console.log("v2 holdings response for " + vm.model.name + ":", res);
              var holdings = res.data.data;

              // structure has been volatile between staging/prod
              vm.holdings = holdings.holdings || holdings;
              vm.model.loadingTreeData = false;

              if (!_.isEmpty(holdings)) {
                vm.gotModelData = true;
                vm.model.loadingTreeData = false;
                $timeout(function() {
                  buildTreev2(holdings);
                }, 100);
              } else {
                vm.gotModelData = false;
              }
            })
            .catch(function(err) {
              vm.model.loadingTreeData = false;
              vm.gotModelData = false;
              // console.error(err);
              // toastr.error(err.message);
              Dashboard.toastError(err.message, err);
            });
        }

        modelHasRecordedWebinar();
      }
    };

    vm.model.drillup = function(event) {
      if (vm.model.tree.level > 0) vm.model.tree.level--;
      if (!vm.model.tree.level) {
        vm.model.tree.drilled = false;
      }
      // jQuery('#tree-' + vm.model.nid).trigger({
      //   type: 'mousedown',
      //   which: 3
      // });
      vm.model.treeMap.goUpAndDraw();
    };

    var selectHandler = function() {

      var selectedItem = vm.model.treeMap.getSelection()[0];
      debugger;
      if (selectedItem) {
        var node = vm.model.treedata.getValue(selectedItem.row, 0);
        var nodeTitleWordCount = node.split(' ').length;

        if (vm.model.tree.level !== 1) {
          if (node === 'Long' || node === 'Short') {

            $scope.$apply(function() {
              vm.model.tree.level = 1;
              vm.model.tree.drilled = true;
            });

          } else if (node === '') { // starting state

            $scope.$apply(function() {
              vm.model.tree.level = 0;
              vm.model.tree.drilled = false;
            });
          }
        } else {
          //alert("we can drill no more");
          vm.model.tree.level = 2;
          vm.model.drillup();
        }
      }
    };

    var rollupHandler = function(row, event) {

      if (vm.model.tree.level > 0) {

        try {

          // this will throw an error if the rollup handler was called using the button
          // but angular needs to be notified in case rollup was called by a right click
          //http://www.bennadel.com/blog/2605-scope-evalasync-vs-timeout-in-angularjs.htm
          $scope.$evalAsync(function() {
            vm.model.tree.level--;
            if (!vm.model.tree.level) vm.model.tree.drilled = false;
          });


        } catch (e) {

          console.error(e);
          vm.model.tree.level = 0;
          vm.model.tree.drilled = false;
        }
      }

    };

    // makes the tree responsive by redrawing it as its container size changes
    redrawTree = _.debounce(function() {
      if (vm.model.treeMap && parentTab.active) {
        drawChart(vm._holdings);
      }
    }, 300);

    jQuery(window).resize(redrawTree);

    function buildTreev2(holdings) {

      holdings = holdings.holdings || (holdings || []);

      if (holdings.length) {

        holdings = holdings.filter(function(holding){
          return holding.symbol;
        });

        var treeData = [],
          treeSeries,
          treeOptions,
          shortColor = "#eeeeee",
          longColor = "#80d9eb",
          sector,
          sectorValue,
          holding,
          holdings,
          ticker,
          sectorData,
          symbols,
          topLevel;

        var modelName = vm.modelName;
        var longValue = 0;
        var shortValue = 0;
        var longHoldings = _.filter(holdings, function(holding) {
          return holding.quantity >= 0 || holding.percentOfPortfolio > 0;
        });
        var shortHoldings = _.filter(holdings, function(holding) {
          return holding.quantity < 0;
        });

        vm.longHoldings = _.chain(longHoldings)
          .filter(function(holding) {

            var isProbablyNotAnOption = holding.symbol.ticker.length <= 5;

            if (isProbablyNotAnOption) {
              return true;
            }
          })
          .sortBy('percentOfPortfolio')
          .value().reverse();

        vm.shortHoldings = _.chain(shortHoldings)
          .filter(function(holding) {

            var isProbablyNotAnOption = holding.symbol.ticker.length <= 5;

            if (isProbablyNotAnOption) {
              return true;
            }

          })
          .sortBy(function(item){
            return item.percentOfPortfolio;
          })
          .value();

        var totalValue = longValue + shortValue;

        var longIndustries = _.chain(longHoldings)
          .reduce(function(obj, holding) {
            holding.value = Number(holding.price);
            longValue += holding.value ? holding.value : holding.percentOfPortfolio;
            if (obj[holding.symbol.industry]) {
              obj[holding.symbol.industry] = obj[holding.symbol.industry] + holding.value ? holding.value : holding.percentOfPortfolio;
            } else {
              if (!angular.isDefined(holding.symbol.industry) || holding.symbol.industry === null || holding.symbol.industry === '') {
                holding.symbol.industry = 'Unknown';
              }
              obj[holding.symbol.industry] = holding.value ? holding.value : holding.percentOfPortfolio;
            }

            return obj;
          }, {})
          .pairs()
          .value();

        var shortIndustries = _.chain(shortHoldings)
          .reduce(function(obj, holding) {
            holding.value = Number(holding.price);
            shortValue += holding.value ? holding.value : holding.percentOfPortfolio;
            if (obj[holding.symbol.industry]) {
              obj[holding.symbol.industry] = obj[holding.symbol.industry] + holding.value ? holding.value : holding.percentOfPortfolio;
            } else {
              if (!angular.isDefined(holding.symbol.industry) || holding.symbol.industry === null || holding.symbol.industry === '') {
                holding.symbol.industry = 'Unknown';
              }
              obj[holding.symbol.industry] = holding.value ? holding.value : holding.percentOfPortfolio;
            }

            return obj;
          }, {})
          .pairs()
          .value();

        var totalValue = longValue + shortValue;
        var data = [
          ['Account Holdings', 'Parent', 'Holding Value'],
          [modelName, null, longValue + Math.abs(shortValue)],
          ['Long', modelName, longValue],
          ['Short', modelName, Math.abs(shortValue)]
        ];

        var holdingsData = {
          shortHoldings: shortHoldings,
          longHoldings: longHoldings,
          longValue: longValue,
          shortValue: shortValue,
          shortIndustries: shortIndustries,
          longIndustries: longIndustries
        };

        ['Long', 'Short'].forEach(function(direction, index) {
          holdingsData[direction.toLowerCase() + "Value"] = 0;

          if (holdingsData[direction.toLowerCase() + 'Industries'].length) {
            holdingsData[direction.toLowerCase() + 'Industries'].forEach(function(category) {

              var name = category[0]; // industry name
              var value = category[1]; // total value of industry holdings

              holdingsData[direction.toLowerCase() + "Value"] += value;
              var sectorData = {
                c: [{
                  v: name + ' ' + direction,
                  f: name
                }, {
                  v: direction
                }, {
                  v: Math.abs(value)
                }]
              };
              data.push(sectorData);
            });
          }
        });

        holdings = data;
        vm._holdings = angular.copy(holdings);

        $timeout(function() {
          vm.holdingsTreeWidth = jQuery('#model-overview-' + vm.model.guid).width(); // use id with unique model guid so that other model tabs aren't affected
          console.log("Holdings Tree Width: ", vm.holdingsTreeWidth);
          drawChart(vm._holdings);
        }, 300);

        vm.errorLoadingModelData = false;

      }
    }


    vm.buildTree = function() {

      var treeData = [],
        shortColor = "#eeeeee",
        longColor = "#80d9eb",
        sector,
        sectorValue,
        holding,
        holdings,
        ticker,
        sectorData,
        symbols,
        topLevel;

      var data = [
        ['Account Holdings', 'Parent', 'Holding Value'],
        [vm.model.name, null, 0],
        ['Long', vm.model.name, vm.longValue],
        ['Short', vm.model.name, Math.abs(vm.shortValue)]
      ];

      ['Long', 'Short'].forEach(function(direction, index) {
        vm[direction.toLowerCase() + "Value"] = 0;

        if (vm[direction.toLowerCase() + 'Categories'].length) {
          vm[direction.toLowerCase() + 'Categories'].forEach(function(category) {

            var name = category[0];
            var value = category[1];

            vm[direction.toLowerCase() + "Value"] += value;

            sectorData = [{
              v: name + ' ' + direction,
              f: name
            }, direction, Math.abs(value)];

            treeData.push(sectorData);
          });
        }

      });

      holdings = data.concat(treeData);
      vm._holdings = angular.copy(holdings);
      drawChart(vm._holdings);
      vm.errorLoadingModelData = false;
    };

    vm.buildChart = function buildChart(model, benchmark, updateGrowthChart) {
      var days = [],
        modelData = [],
        benchmarkData = [],
        startingBalance = 1000,
        startModel = 1000,
        startBenchmark = 1000,
        leverage = leverage ? leverage : 1;

      // using copies prevents the passed in objects original values from being modified
      // this prevents continously dividing the original values and throwing off the growth of 1000 chart data
      model = angular.copy(model);
      benchmark = angular.copy(benchmark);

      for (var day in model) {
        if (typeof model[day] !== 'undefined' && model[day] !== null && typeof benchmark[day] !== 'undefined' && benchmark[day] !== null) {

          model[day] = !isNaN(model[day]) ? model[day] / 100 : model[day] / 100;
          benchmark[day] = !isNaN(benchmark[day]) ? benchmark[day] / 100 : benchmark[day] / 100;
          var timestamp = day * 1000;
          var currentModel = (startModel + (model[day] * leverage) * startModel);
          var currentBenchmark = startBenchmark + (benchmark[day]) * startBenchmark;

          if (isNaN(currentModel)) {
            debugger;
          }
          modelData.push([timestamp, currentModel]);
          benchmarkData.push([timestamp, currentBenchmark]);

          startModel = currentModel;
          startBenchmark = currentBenchmark;
        }
      }

      if (modelData.length) {
        var firstModelPoint = modelData[0][0];
        var firstBenchmarkPoint = benchmarkData[0][0];

        var newFirstModelPoint = moment(firstModelPoint).subtract(1, 'month').valueOf();
        var newFirstBenchmarkPoint = moment(firstBenchmarkPoint).subtract(1, 'month').valueOf();

        modelData.unshift([newFirstModelPoint, startingBalance]);
        benchmarkData.unshift([newFirstBenchmarkPoint, startingBalance]);
      }

      vm.chartWillRender = (modelData.length && (modelData.length === benchmarkData.length));

      vm.model.name = vm.modelName || vm.model.name;

      var chartData = {
        modelData: modelData,
        benchmarkData: benchmarkData,
        benchmarkName: vm.benchmarkName,
        model: vm.model, // we attach the modelChartInstance property to this in the performanceChart directive
        modelNames: '',
        models: {},
        modelCumulativeReturn: (currentModel - startingBalance) / startingBalance
      };

      return chartData;
    };

    vm.buildPerformanceChart = function buildPerformanceChart(model, benchmark, leverage) {

      if (!vm.gotPerformanceData) {
        var rid = vm.model.data_id;
        var guid = vm.model.field_fund_model_guid.und ? vm.model.field_fund_model_guid.und["0"].value.toLowerCase() : undefined;

        vm.loadingPerformanceData = true;

        if (guid) {
          var modelData = {
            'model_ids': guid,
            'percentages': 100,
            'leverage': 1
          };

          Dashboard.getModelPerformanceByGUID(modelGuid)
            .then(function(result) {

              vm.loadingPerformanceData = false;

              if (_.isEmpty(result.data.models)) {
                toastr.error('Error getting performance data');
                vm.gotPerformanceData = false;
                return false;
              }

              var payload = result.data.models[guid];

              var monthlyPerformance = payload.monthly_performance;

              var monthlyModelPerformance = monthlyPerformance.simple_hist;
              var monthlyModelStatistics = payload.monthly_statistics;

              var monthlyBenchmarkPerformance = monthlyPerformance.benchmark_hist;
              var monthlyBenchmarkStatistics = payload.monthly_benchmark_statistics;

              vm.loadingPerformanceData = false;
              vm.gotPerformanceData = true;

              vm.model.performance = monthlyModelPerformance;
              vm.model.benchmark = monthlyBenchmarkPerformance;

              // process the time series and build the chart data object
              $q.when(vm.buildChart(monthlyModelPerformance, monthlyBenchmarkPerformance))
                .then(function(chartData) {
                  vm.performanceChartData = chartData; // performanceChartData gets passed in to the performanceChart.js directive
                });
            });
        } else {

          toastr.error("Unable to get performance due to missing model guid");
          vm.loadingPerformance = false;
          vm.gotPerformanceData = false;
          return false;
        }
      } else {

        // come here if we already have performance data and immediately start building the chart
        // should already have performance if the initial model performance call was successful
        // process the time series and build the chart data object

        $timeout(function() {
          $q.when(vm.buildChart(vm.model.performance, vm.model.benchmark))
            .then(function(chartData) {
              vm.performanceChartData = chartData; // performanceChartData gets passed in to the performanceChart.js directive
            });
        }, 100);
      }

    };

    vm.addTab = function(type, viewpoint) {
      if (type == 'viewpoint') {
        Models.currentViewpoint = viewpoint;
      }
      var tab = (typeof viewpoint != 'undefined' && viewpoint != null) ? Tabs.addTab(type, null, viewpoint) : Tabs.addTab(type);


      if (type === 'allocate') {

        console.log(vm.model);

        Models.selectedModel = loadedModel;
        Allocations.currentModel = loadedModel; // to pre-populate allocation form when opening tab from this view
        Dashboard.selectedAllocationModel = loadedModel;
        Dashboard.selectedAllocationAccount = Dashboard.getActiveAccount();
        Dashboard.notifyObservers('model');

      }

      if (tab) {
        $scope.$parent.$broadcast('tabSwitched', tab);
      }
    };

    // call this when we want to compare
    vm.getModelsPerformance = function() {

      var smartxModels = Models.getModelList();

      // console.log("Access Models: ", smartxModels);

      modelNames = {};
      var models = vm.models.filter(function(model) {
        return model.Selected || model.title == vm.model.name || model.name == vm.model.name;
      }).map(function(model) {

        // var smartxGuid = model.field_smartx_model_guid.und[0].value ? model.field_smartx_model_guid.und[0].value.toLowerCase() : null
        // var model = _.find(smartxModels, {id: smartxGuid });

        try {
          var guid = model.id;
          modelNames[guid] = model.name;
          return guid;
        } catch (e){
          toastr.error('Missing SmartX Model ID for ' + model.name);
          return null;
        }
      }).filter(function(modelId){
        return modelId != null;
      }).join(',');

      vm.dropdown.isOpen = false;
      vm.loadingPerformanceData = true;
      vm.gotPerformanceData = false;

      if (models.length) {

        var modelsArr = models.split(',');
        var modelData = {
          'model_ids': models,
          'percentages': modelsArr.map(function(model, index){
            return (1 / modelsArr.length) * 100;
          }).join(','),
          'leverages': modelsArr.map(function(model){
            return 1;
          }).join(',')
        };

        performanceApi.portfolioPerformance(modelData)
          .then(function(res) {
            // console.log(res);
            vm.loadingPerformanceData = false;
            vm.gotPerformanceData = true;
            var payload = res;

            var modelContributions = res.data.performance.payload.portfolios[modelData.percentages].windows['-1'].monthly_model.model_contributions;
            var portfolio = _.map(modelContributions, function(performance, id){
              var modelInformation = res.data.models[id].information;
              return {
                id: id,
                name: modelInformation.name,
                performance: performance
              };
            });
            
            // portfolio.push({
            //   information: { name: 'Benchmark'},
            //   performance: res.data.performance.payload.portfolio_benchmark.monthly_performance.stream
            // })

            // format into dictionary
            // payload.models = res.reduce(function(models, item){
            //   var info = item.model;
            //   var performance = item.performance;

            //   models[info.id] = performance;

            //   return models;
            // }, {})

            // console.log("Formatted models: ", payload.models);

            // var models = payload.models;

            // // find the first common month where every model has a return
            // var firstMonthsDict = {};
            // _.each(models, function(model, guid) {
            //   var firstMonth = model.range.start.timestamp;
            //   firstMonthsDict[guid] = Number(firstMonth);
            // });

            // var activeModel = vm.model;
            // var activeModelGuid = activeModel.node.field_fund_model_guid.und[0].value;
            // var activeModelPerf = vm.model.performance;
            // var activeModelFirstMonth = Number(Object.keys(activeModelPerf)[0]);

            // firstMonthsDict[activeModelGuid] = activeModelFirstMonth;

            // // http://www.jstips.co/en/calculate-the-max-min-value-from-an-array/
            // var mostRecentStartMonth = Math.max.apply(null, _.values(firstMonthsDict));

            // vm.firstMonthlyReturnDate = mostRecentStartMonth * 1000;

            // buildComparisonChart(payload.models, mostRecentStartMonth);
            buildComparisonChartV2(portfolio);
          });

      } else {
        vm.loadingPerformanceData = false;
        vm.gotPerformanceData = true;
        buildComparisonChart();
      }

    };

    var buildComparisonChartV2 = function(portfolio) {

      vm.comparisonChartSeries = [];
      vm.performanceChartData.models = {};

      angular.forEach(portfolio, function(model) {

        modelData = [];
        leverage = 1;
        startModel = 1000000;

        var guid = model.id;

        vm.performanceChartData.startDate = _.first(_.keys(model.performance));
        vm.performanceChartData.models[guid] = model.performance;
        vm.performanceChartData.showAllModels = true;
        vm.performanceChartData.initialModel = vm.model;
      });

      vm.performanceChartData.initialModel = vm.model;
      vm.performanceChartData.modelNames = modelNames;


      $scope.$apply();
    };

    vm.modelFilterOpts = [{
        name: 'Show All',
        selected: true
      },
      {
        name: 'Manager',
        selected: false
      },
      {
        name: 'Composite',
        selected: false
      }
    ];

    vm.activeFilter = 'Show All';

    vm.filterModels = function(model) {
      vm.modelFilterOpts.forEach(function(filter) {
        filter.selected = false;
      });

      model.selected = true;

      vm.activeFilter = model.name;

    };

    vm.clearSelection = function() {
      vm.models.forEach(function(model) {
        model.Selected = false;
      });
    };

    vm.requestMoreInfo = function() {
      $window.location = 'mailto:support@hedgecovest.com?subject=Requesting information about ' + vm.modelName;
    };

    // clean up any leftover chart instances when the tab is closed
    $scope.$on('$destroy', function() {
      vm.model.modelChart = undefined;
      vm.model.treeMap = undefined;
    });

    var treeSelectListener;
    var treeRollupListener;

    var drawChart = function drawChart(holdings, initGrid) {
      if (!_.isEmpty(holdings) && holdings.length) {

        try {
          vm.model.treedata = google.visualization.arrayToDataTable(holdings);
        } catch (e) {
          console.error(e);
        }
        
        var treeContainer = document.getElementById('tree-' + vm.model.nid);

        vm.model.treeMap = new google.visualization.TreeMap(treeContainer);

        treeSelectListener = google.visualization.events.addListener(vm.model.treeMap, 'select', selectHandler);
        treeRollupListener = google.visualization.events.addListener(vm.model.treeMap, 'rollup', rollupHandler);

        // console.log(vm.model.treeMap)

        vm.holdingsTreeWidth = jQuery('#model-overview-' + vm.model.guid).width();

        $timeout(function() {
          vm.model.treeMap.draw(vm.model.treedata, {
            minColor: '#97ccee',
            midColor: '#6291B7',
            maxColor: '#2c557f',
            headerHeight: 50,
            headerColor: 'rgb(246, 246, 246)',
            height: 300,
            width: '100%',
            fontColor: 'black',
            highlightOnMouseOver: true
          });
        }, 100);
      }
    };


    var buildComparisonChart = function(models, startDate) {

      vm.comparisonChartSeries = [];
      vm.performanceChartData.models = {};

      angular.forEach(models, function(model, guid) {

        modelData = [];
        leverage = 1;
        startModel = 1000000;

        vm.performanceChartData.startDate = startDate;
        vm.performanceChartData.models[guid] = model;
        vm.performanceChartData.showAllModels = true;
      });

      vm.performanceChartData.modelNames = modelNames;

      $scope.$apply();
    };

    vm.modelTypeFilter = function(model) {

      if (!model.type) {
        return;
      }

      switch (vm.activeFilter.toLowerCase()) {
        case 'show all':
          return model;
          break;
        case 'manager':
          if (model.type && model.type.toLowerCase() !== 'composite' /* || model.field_hedgecovest_product.und[0].value == 0 */) {

            return model;
          }
          break;
        case 'composite':
          if (model.type && model.type.toLowerCase() == 'composite' /* || model.field_hedgecovest_product.und[0].value == 1 */) {

            return model;
          }
          break;
      }
    };

    vm.togglePDFModal = function(viewpoint) {

      var url = viewpoint.file_s3.uri;
      url = vm.convertArticleLink(url);
      var modalInstance = $uibModal.open({
        animation: true,
        template: $templateCache.get('pdfViewerModal.tpl.html'),
        size: 'lg',
        resolve: {
          pdfURL: function() {
            return url;
          }
        },
        controller: 'PDFViewerCtrl',
        controllerAs: 'vm'
      });
    };

    vm.toggleCRDModal = function() {

      if (typeof vm.model.crd != 'undefined') {
        // var url = $sce.trustAsResourceUrl(window.location.protocol + "//www.adviserinfo.sec.gov/IAPD/IAPDFirmSummary.aspx?ORG_PK=" + vm.model.crd);
        var url = $sce.trustAsResourceUrl(window.location.protocol + "//www.adviserinfo.sec.gov/firm/summary/" + vm.model.crd);

        var modalInstance = $uibModal.open({
          animation: true,
          template: $templateCache.get('crdViewerModal.tpl.html'),
          size: 'lg',
          resolve: {
            crdURL: function() {
              return url;
            }
          },
          controller: 'CRDViewerCtrl',
          controllerAs: 'vm'
        });
      } else {
        // console.log('No crd');
        // debugger;
      }
    };

    vm.getMasonaryWide = function(viewpoint) {
      return (viewpoint.commentary_type == 'Webinar' || (typeof viewpoint.video !== 'undefined' && viewpoint.video.uri));
    };

    vm.getPersonnelImage = function(image) {
      // console.log('Personnel Image', image);
      var base = (image[0].raw.uri.indexOf('s3://') === 0) ? '//managerfiles.s3.amazonaws.com/' : vm.basePath.replace('styles/thumbnail/public/', '');
      var file = image[0].raw.uri.replace('public://', '').replace('s3://', '');
      return base + encodeURIComponent(file);
    };




  }])
  .filter('stripHTML', function($sce) {
    return function(val) {
      if (typeof val == 'undefined' || val == null) {
        return '';
      }
      if (typeof val == 'string') {
        val = val.replace("<span class='negative_num'>", '');
        val = val.replace('</span>', '');
      }

      return val;
    };
  })
  .filter('stripDoubleReturnsWithBR', function() {
    return function(val) {
      var rtn = val;
      //rtn = rtn.replace(/<div>&nbsp;<\/div>/g, '\n');
      rtn = rtn.replace(/\n\s*\n/g, '<br/>');
      rtn = rtn.replace(/<\/p><br><p>/g, '<\/p><p>');
      return rtn;
    };
  })
  .filter('replaceNewlineWithBR', function() {
    return function(val) {
      var text = val;

      text = text
        .replace(/\n+/g, '<br />');

      return text;
    };
  })
  .filter('trustAsResourceUrl', ['$sce', function($sce) {
    return function(val) {
      return $sce.trustAsResourceUrl(val);
    };
  }]);
