var Allocation = angular.module('AllocationCtrl', []);

Allocation.controller("AllocationCtrl", function (AcrDisclaimer,FeatureFlags, SmartXFactory, USER, $modal, HCV_storage, $timeout, SweetAlert, SearchFilters, $templateCache, $filter, $scope, $interpolate, $rootScope, Dashboard, Allocations, Tabs, $q, toastr, Models, $http) {

    var $ = jQuery;
    var allocationApi = SmartXFactory.getAllocationAPI();
    var accountApi = SmartXFactory.getAccountAPI();
    var modelApi = SmartXFactory.getModelAPI();
    var modelList = Models.getModelList();
    var performanceApi = SmartXFactory.getPerformanceAPI();
    var chartInstance;
    var mutualFundsData = [];

    $scope.clearSelectedAccount = clearSelectedAccount;
    $scope.noModelResults = false;

    console.log(AcrDisclaimer);

    function init() {

        // Deprecated - load last selected account and model
        // var lastAccount = HCV_storage[USER.username].AllocationLastSelectedAccount,
        //     lastModel = HCV_storage[USER.username].AllocationLastSelectedModel,
        //     currentAccount = lastAccount ? lastAccount : Allocations.currentAccount,
        //     currentModel = lastModel ? lastModel : Allocations.currentModel;
        console.log('USER GLOBALS');
        console.log(USER);

        // var currentAccount = Dashboard.currentAccount ? Dashboard.currentAccount : (Dashboard.getActiveAccount() || SmartXFactory.accounts[0]),
        //     currentModel = Allocations.currentModel;

        $scope.allocationMessage = '';
        $scope.showChart = false;
        $scope.hasError = false;
        $scope.trackingManagerLeverage = false;
        $scope.viewOnlyAccount = false;
        $scope.bulkOrder = {};
        $scope.disabled = false;
        $scope.gridOptions = {};
        $scope.multiAccount = {
            selectedAccounts: []
        };
        $scope.allocationAmounts = [
            { value: 5000, selected: false },
            { value: 10000, selected: false },
            { value: 15000, selected: false },
            { value: 50000, selected: false }
        ];
        $scope.leverageAmounts = [
            { value: 1, selected: true },
            { value: 2, selected: false },
            { value: 3, selected: false },
            { value: 4, selected: false }
        ];

        $scope.trackManagerLeverage = true;
        $scope.customAllocationSelected = false;
        $scope.customLeverageSelected = false;
        $scope.selectedAllocationAmount = '';
        $scope.selectedLeverageAmount = 1; // default
        $scope.customAllocationAmount = '';

        $scope.customAmount = {
            leverage: ''
        };

        $scope.chartLoaded = false;
        $scope.th_models = [];
        $scope.th_selected_model = undefined;

        $scope.leverageEnabled = !FeatureFlags.isEnabled('noModifyLeverage');

        $scope.accountCanUseLeverage = false;

        $scope.modelChart = {
            title: {
                text: null,
                stylye: {
                    display: 'none'
                }
            }
        };
        $scope.chartLoaded = false;

        $scope.errorHTML = '<div class="loading-msg col-xs-12" style="height: 250px;"><h3 class="text-center">No account found.</h3><br/></div>';

        var columnDefs = [
            { headerName: "Account", field: "accountName" },
            { headerName: "Available Cash", field: "availableCash" },
            { headerName: "Allocation Amount", field: "allocationAmount" },
            { headerName: "Model", field: "selectedModel" }
        ];

        var rowData = [];

        $scope.gridOptions = {
            columnDefs: columnDefs,
            rowData: rowData
        };

        var updateSelectedAccountCallback = {
            name: 'updateSelectedAccount',
            fn: updateSelectedAccount
        };

        // Register an observer that can be used to set the selected model or account from other tabs.
        // This acts as an alternative to broadcasting events.
        // http://stackoverflow.com/questions/12576798/angularjs-how-to-watch-service-variables
        Dashboard.registerObserverCallback(updateSelectedAccountCallback);

        $q.all([getModels(), getAccounts()])
            .then(function (res) {

                var model,
                    modelId,
                    modelGuid;

                // enter here when opening allocate tab from account performance page or model page
                if (Dashboard.selectedAllocationModel) {

                    // check to see if we're dealing with a fund profile from a model page
                    // if so extract the guid from the field
                    // otherwise use the guid from the replicator model object
                    if (Dashboard.selectedAllocationModel.field_smartx_model_guid) {

                        modelGuid = Dashboard.selectedAllocationModel.field_smartx_model_guid ? Dashboard.selectedAllocationModel.field_smartx_model_guid.und[0].value.toLowerCase() : undefined;

                    } else {

                        modelGuid = Dashboard.selectedAllocationModel.id || Dashboard.selectedAllocationModel.smartx_guid;
                    }

                    model = Models.getModelProfileById(modelGuid, $scope.hcv_models);

                    $scope.setModel(model);
                }

            })
            .catch(function (err) {
                console.error(err);
            });
    }

    $scope.$watch('selectedAccount', function(newVal, oldVal){
        if (angular.isDefined(newVal)){
            if (newVal.marginType == 'cash' || newVal.marginType == 'ira'){
                $scope.accountCanUseLeverage = false;

                // reset back to the defaults if the account is changed from one that can use leverage to one that can't
                $scope.trackManagerLeverage = true;
                $scope.leverageAmount = 1;
            } else {
                $scope.accountCanUseLeverage = true;
            }
        }
    });

    init();

    // figure out if we've selected a static model
    $scope.$watch('selectedModel', function(newVal, oldVal){
        if (angular.isDefined(newVal)) {
            var modelId = newVal.id;
            var model = _.findWhere(modelList, {id: modelId});
            var isStatic = model.isStatic;
            $scope.selectedModel.isStatic = isStatic;
        }
    });

    function getAccounts(){

        var accounts = SmartXFactory.accounts || [];
        var activeAccount;

        $scope.accounts = accounts;


        // if (!angular.isDefined(accounts)) {
        //     SmartXFactory.getAccounts()
        //         .then(function (res) {
        //             $scope.accounts = res.filter(function (account) {
        //                 return account.status === 'active' && account.permission ? account.permission == 'fullAccess' : true;
        //             });
        //             activeAccount = $scope.accounts[0];
        //         })
        //         .catch(function (err) {
        //             console.error(err);
        //             toastr.error(err.errorMessage);
        //         });
        // } else {

            activeAccount = Dashboard.getActiveAccount();

            // $scope.accounts = accounts.filter(function (account) { return account.status === 'active' && (account.permission ? account.permission == 'fullAccess' : true); });

            if (Dashboard.selectedAllocationAccount) {
                $scope.selectedAccount = Dashboard.selectedAllocationAccount; // accounts.filter(function (account) { return account.id == Dashboard.selectedAllocationAccount.id && (account.permission ? account.permission == 'fullAccess' : true); })[0];
            } else {
                $scope.selectedAccount = activeAccount && (activeAccount.permission ? activeAccount.permission == 'fullAccess' : true) ? activeAccount : accounts[0];
            }

            // if ($scope.selectedAccount === undefined) $scope.selectedAccount = $scope.accounts[0];

            if (typeof $scope.selectedAccount != 'undefined') {
                var accountID = $scope.selectedAccount.id;
                if ($scope.selectedAccount.cash) {
                    $scope.accountAvailableCash = $scope.selectedAccount.cash;
                }
                getAccountData(accountID);

            } /* else {
                $scope.hasError = true;
                $scope.errorHTML = '<div class="loading-msg col-xs-12" style="height: 250px;"><h3 class="text-center">No account found.</h3><br/></div>';
            } */
        // }
    }

    function getAccountData(guid) {

        $scope.loading = true;

        $q.all([accountApi.getAccountStatisticsForId(guid), allocationApi.getInflatedAllocations({ accountid: guid, status: 'open' })])
            .then(function (res) {

                console.log("getAccountByGuid response: ", res);
                var allocations = res[1].data;
                var statistics = res[0].data.statistics;
                var accounts = SmartXFactory.accounts || [];

                $scope.accountAllocations = allocations;
                console.log("Active allocations for account: ", $scope.accountAllocations);

                // $scope.selectedAccount = accounts.filter(function (account) { return account.id == guid && (account.permission ? account.permission === 'fullAccess' : true); })[0];
                $scope.accountAvailableCash = statistics.cash;

                if ($scope.selectedModel) {

                    $scope.minimumInvestment = Models.getMinimumById($scope.selectedModel.id) || $scope.selectedModel.minimumAllocation;
                    console.log("Model Minimum: ", $scope.minimumInvestment);

                    if ($scope.accountAvailableCash < $scope.minimumInvestment) {
                        $scope.hasEnoughCash = false;
                    } else if ($scope.accountAvailableCash >= $scope.minimumInvestment) {
                        $scope.hasEnoughCash = true;
                    }

                    $scope.hasExistingAllocationToModel = Allocations.hasExistingAllocation($scope.accountAllocations, $scope.selectedModel);
                    if ($scope.hasExistingAllocationToModel) {
                        var existingAllocation = _.find($scope.accountAllocations, function(allocation) {
                            return allocation.model && (allocation.model.id == $scope.selectedModel.id);
                        });

                        if (existingAllocation) $scope.trackManagerLeverage = existingAllocation.matchModelLeverage;
                    }

                    setMinimumInvestment();
                } else {
                    $scope.hasExistingAllocationToModel = false;
                }
            })
            .catch(function (res) {
                if (res.config) {
                    if (res.config.timedOut) {
                        $scope.timedOut = true;
                    }
                }
            })
            .finally(function () {
                $scope.loading = false;
            });
    }

    function fetchMutualFundsData() {
        return $http.get('https://analytics.gtw.qa.smartx.us/mutual-funds', {
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + SmartXFactory.getToken()
          }
        })
        .then(function(response) {
          mutualFundsData = response.data;
        })
        .catch(function(error) {
          console.error('Error fetching mutual funds data:', error);
          mutualFundsData = [];
        });
      }

    async function getModels() {

        var deferred = $q.defer();

        if (!$scope.th_models.length) {

            $scope.gettingModelList = true;

            var modelList = [];

            if (modelList.length) {
                $scope.gettingModelList = false;

                $scope.hcv_models = modelList;
                $scope.th_models = [];
                angular.forEach($scope.hcv_models, function (value, key) {
                    try {
                        $scope.th_models.push(value.name);
                    } catch (e) {
                        console.log("Missing information for model with guid: ", value.guid);
                    }
                });

                $scope.th_models = _.sortBy($scope.th_models, function (name) {
                    return name;
                });

                deferred.resolve();

            } else {

                modelApi.getAllModels({ type: 'web,file,composite' })
                    .then(async function (result) {
                        await fetchMutualFundsData();
                        if (result) {
                            console.log(mutualFundsData)
                            result = result.filter(item1 => !mutualFundsData.some(item2 => item2.ModelId === item1.id))
                            Allocations.setModelList(result);
                        }

                        $scope.gettingModelList = false;
                        $scope.hcv_models = result;
                        $scope.th_models = [];
                        angular.forEach($scope.hcv_models, function (value, key) {
                            try {
                                $scope.th_models.push(value.name);
                            } catch (e) {
                                console.log("Missing information for model with guid: ", value.id);
                            }
                        });

                        $scope.th_models = _.sortBy($scope.th_models, function (name) {
                            return name;
                        });

                        deferred.resolve();

                    })
                    .catch(function (err) {
                        $scope.gettingModelList = false;
                        $scope.hcv_models = [];
                        $scope.th_models = [];
                        deferred.reject(err);
                    });
            }
        }

        return deferred.promise;
    }

    $scope.toCurrency = function () {
        $scope.customAllocationAmount = $filter('currency')($scope.customAllocationAmount, '');
    };

    $scope.setAllocationAmount = function (val) {
        var amountSelected = false;

        $scope.selectedAllocationAmount = val.value;
        $scope.customAllocationSelected = false;

        angular.forEach($scope.allocationAmounts, function (amount, key) {
            if (amount.value == $scope.selectedAllocationAmount && !amountSelected) {
                amount.selected = true;

                // workaround for a bug that was causing multiple amounts to be selected when the minimum investment is a large percentage of the available cash
                // TODO: Handle this special case so that multiple choices with the same amount are not shown
                amountSelected = true;
            } else amount.selected = false;
        });

        if ($rootScope.showChart && !$scope.customAllocationSelected && !$scope.customLeverageSelected) {
            $rootScope.showChart = true;
        } else {
            $rootScope.showChart = false;
        }
    };

    $scope.setLeverage = function (leverage) {

        $scope.selectedLeverageAmount = leverage.value * ($scope.trackManagerLeverage && $scope.managerLeverage > 0 ? $scope.managerLeverage : 1);
        $scope.customLeverageSelected = false;
        $scope.customAmount.leverage = '';

        angular.forEach($scope.leverageAmounts, function (amount, key) {
            if (amount.value == $scope.selectedLeverageAmount / ($scope.trackManagerLeverage && $scope.managerLeverage > 0 ? $scope.managerLeverage : 1)) {
                amount.selected = true;
            } else {
                amount.selected = false;
            }
        });

        if ($rootScope.showChart && !$scope.customAllocationSelected && !$scope.customLeverageSelected) {
            $rootScope.showChart = true;
        } else {
            $rootScope.showChart = false;
        }

        plotModel();
    };

    // handle custom amounts for allocation or leverage
    $scope.selectCustomTypeFor = function (type) {

        if (type == 'allocate') {
            $scope.customAllocationSelected = true;
            angular.forEach($scope.allocationAmounts, function (amount, key) {
                amount.selected = false;
            });
        } else if (type == 'leverage') {

            $scope.customLeverageSelected = true;
            angular.forEach($scope.leverageAmounts, function (leverage, key) {
                leverage.selected = false;
            });

            if ($scope.customAmount.leverage !== undefined && $scope.customAmount.leverage !== '' && $scope.showChart) {
                if ($scope.customAmount.leverage >= 0.01 && $scope.customAmount.leverage <= 4) {
                    $scope.selectedLeverageAmount = $scope.trackManagerLeverage && $scope.managerLeverage > 0 ? $scope.customAmount.leverage * $scope.managerLeverage : $scope.customAmount.leverage;
                    plotModel();
                }
            }
        }

        if (!$scope.customAllocationSelected && !$scope.customLeverageSelected) {
            $rootScope.showChart = true;
        } else {
            $rootScope.showChart = false;
        }
    };

    $scope.$watch('customAmount.leverage', function(newVal, oldVal){
        console.log(newVal);
        console.log(oldVal);
    });

    $scope.updateChart = function () {

        if (!$scope.customLeverageSelected) {
            $scope.customLeverageSelected = true;
            angular.forEach($scope.leverageAmounts, function (leverage, key) {
                leverage.selected = false;
            });
        }

        if ($scope.customAmount.leverage !== '') {

            if (parseFloat($scope.customAmount.leverage) >= 0.01 && parseInt($scope.customAmount.leverage) <= 4) {
                if ($scope.showChart) {
                    $scope.selectedLeverageAmount = $scope.trackManagerLeverage && $scope.managerLeverage > 0 ? $scope.customAmount.leverage * $scope.managerLeverage : $scope.customAmount.leverage;
                    plotModel();
                }
            } else if (parseFloat($scope.customAmount.leverage) < 0.01) {
                $scope.customAmount.leverage = 0.01;
                //alert("please select a custom    leverage between 1 and 4");
            } else if (parseInt($scope.customAmount.leverage) > 4) {
                // $scope.showCustomLeverageTooltip = true;
                $scope.customAmount.leverage = 4.0;
                //alert("please select a custom    leverage between 1 and 4");
            }
        }
    };

    $scope.setAccount = function (account, guid) {
        $scope.selectedAccount = account;
        Dashboard.setActiveAccount($scope.selectedAccount);
        changeAccount();
    };

    $scope.setModel = function (model) {
        $scope.selectedModel = model;
        changeModel();
    };

    $scope.toggleChart = function () {
        if (!$scope.showChart) {
            $scope.modelChart = {
                title: {
                    text: null,
                    stylye: {
                        display: 'none'
                    }
                }
            };
            if ($scope.customLeverageSelected) {
                if ($scope.customAmount.leverage !== undefined && $scope.customAmount.leverage !== '') {
                    if ($scope.customAmount.leverage >= 0.01 && $scope.customAmount.leverage <= 4) {

                        $scope.showChart = !$scope.showChart;
                        plotModel();

                    } else {
                        //console.log($scope.customAmount.leverage);
                        SweetAlert.swal('please enter a custom leverage amount');
                        //alert("please enter a custom leverage amount");
                    }

                } else {
                    SweetAlert.swal('please enter a custom leverage amount');
                    //alert("please enter a custom leverage amount");
                }
            } else {
                $scope.showChart = !$scope.showChart;
                plotModel();
            }
        } else {
            $scope.showChart = !$scope.showChart;
            $scope.modelchart = {};
        }
    };

    $scope.toggleTrackManagerLeverage = function () {

        if ($scope.trackManagerLeverage){
          var modalInstance = $modal.open({
              animation: true,
              templateUrl: 'trackLeverageWarning.html',
              controller: function ($scope, $uibModalInstance, modelName) {
                $scope.modelName = modelName;
                $scope.ok = function () {
                  $uibModalInstance.close();
                };
              
                $scope.cancel = function () {
                  $uibModalInstance.dismiss('cancel');
                };
              },
              size: 'md',
              resolve: {
                modelName: function () {
                  return $scope.selectedModel.name;
                }
              }
            });
        
          modalInstance.result.then(function () {
            $scope.trackManagerLeverage = false;
          }, function () {
            $scope.trackManagerLeverage = true;
          });
        } else {
            $scope.trackManagerLeverage = !$scope.trackManagerLeverage;
        }
    };

    $scope.addTab = function (type) {
        Tabs.addTab(type);
    };

    function calculateTargetEquity (increaseAmount, existingAllocationValue) {
        console.log($scope.existingAllocation);
        return increaseAmount + existingAllocationValue;
    }

    $scope.allocateToModel = function () {

        if ($scope.selectedModel.type === 'sma'){
            SweetAlert.swal({
                title: "Allocation to SMA model is not allowed.",
                type: "warning"
            });

            return false;
        }

        if ($scope.customAllocationSelected && $scope.customAllocationAmount == null) {
            SweetAlert.swal({
                title: "Please enter a custom amount.",
                type: "warning"
            });
            return false;
        }

        $scope.allocationAmount = $scope.customAllocationSelected ? parseInt($scope.customAllocationAmount.replace('$', '').replace(/,/g, '')) : $scope.selectedAllocationAmount;
        $scope.leverageAmount = $scope.customLeverageSelected ? $scope.customAmount.leverage : $scope.selectedLeverageAmount;
        $scope.theTime = Date.now();
        $scope.keyword = 'Invest';

        // TODO: only show minimum warning if there is no existing allocation for the selected model
        if ($scope.allocationAmount == null || $scope.allocationAmount == undefined || $scope.allocationAmount == '') {
            SweetAlert.swal({
                title: "An allocation amount is required.",
                type: "warning"
            });
        } else if ($scope.allocationAmount > $scope.accountAvailableCash) {

            SweetAlert.swal({
                title: "This allocation amount is more than your available funds.",
                type: "warning"
            });
        } else if (!$scope.hasExistingAllocationToModel && $scope.allocationAmount < parseInt($scope.minimumInvestment)) {

            SweetAlert.swal({
                title: "This allocation amount is below this fund's minimum investment.",
                type: "warning"
            });
        } else if ($scope.leverageAmount > 0 && $scope.leverageAmount <= 4) {

            // get template for allocation rundown that will be shown in the alert
            var confirmAllocation = $templateCache.get('PostNewAllocation.html');
            // var allocationRundown = $templateCache.get('AllocateConfirmation.html');

            // var data = {
            //         "accountId": $scope.selectedAccount.id,
            //         "modelId": $scope.selectedModel.id,
            //         "trackManagerLeverage": $scope.trackManagerLeverage,
            //         "leverage": $scope.leverageAmount,
            //         "amount": $scope.allocationAmount,
            //         //"redeemPercentage": 0
            //     }

            $scope.allocationData = {
                account: $scope.selectedAccount.name,
                model: $scope.selectedModel.name,
                allocationAmount: $scope.allocationAmount,
                leverageAmount: $scope.leverageAmount,
                theTime: Date.now(),
                commissionCost: 'N/A',
                numOfSymbols: undefined,
                trackingManagerLeverage: $scope.trackManagerLeverage ? 'Yes' : 'No',
                description: 'N/A',
            };

            if ($scope.hasExistingAllocationToModel) {
                var existingAllocation = $scope.accountAllocations.filter(function (allocation) {
                    if (!angular.isDefined(allocation.model)) return false;
                    return allocation.model.id == $scope.selectedModel.id;
                })[0];
                
                var currentValue = _.property(['statistics','value'])(existingAllocation);
                var targetEquity = calculateTargetEquity($scope.allocationAmount, currentValue); // $scope.allocationAmount,

                $scope.allocationData.currentValue = currentValue;
                $scope.allocationData.targetEquity = targetEquity;

            }

            SweetAlert.swal({
                title: $interpolate(confirmAllocation)($scope.allocationData),
                text: "<p>" + AcrDisclaimer + "</p><br/>To confirm the purchase of " + $scope.selectedModel.name + " type '<b>" + $scope.keyword + "</b>' into the box below",
                html: true,
                type: "input",
                showCancelButton: true,
                confirmButtonColor: "#8cb843",
                confirmButtonText: "Confirm",
                closeOnConfirm: false,
                closeOnCancel: true,
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                inputPlaceholder: ""
            }, function (inputValue) {

                if (inputValue === false) {
                    jQuery('.sweet-alert').remove();
                    return false;
                }
                if (inputValue !== $scope.keyword) {
                    swal.showInputError("Input does not match. Type in '" + $scope.keyword + "' to complete this allocation.");
                    return false;
                }

                if ($scope.hasExistingAllocationToModel) {
                    var existingAllocation = $scope.accountAllocations.filter(function (allocation) {
                        if (!angular.isDefined(allocation.model)) return false;
                        return allocation.model.id == $scope.selectedModel.id;
                    })[0];

                    var targetEquity = calculateTargetEquity($scope.allocationAmount, _.property(['statistics','value'])(existingAllocation)); // $scope.allocationAmount,

                    var data = {
                        // "accountId": $scope.selectedAccount.id,
                        // "modelId": $scope.selectedModel.id,
                        "trackManagerLeverage": $scope.trackManagerLeverage,
                        "leverage": $scope.leverageAmount,
                        //"redeemPercentage": 0
                    };

                    // update existing allocation
                    allocationApi.updateTargetEquity(existingAllocation.id, targetEquity, data)
                    // allocationApi.increase(existingAllocation.id, data)
                        .then(function (res) {
                            if (res.statusCode !== 200) {
                                var msg = "Allocation failed for model " + ($scope.selectedModel.name || $scope.selectedModel.title || $scope.selectedModel.name) + ".";
                                SweetAlert.swal({
                                    title: msg,
                                    type: "warning"
                                });
                            } else {
                                if (res.statusCode === 200) {
                                    var msg = "Allocation to " + ($scope.selectedModel.name || $scope.selectedModel.title || $scope.selectedModel.name) + " has been submitted and is pending approval.";
                                    clearPreviousSelections();
                                    $scope.th_selected_model = undefined;
                                    $scope.selectedModel = undefined;

                                    SweetAlert.swal({
                                        title: msg,
                                        type: "success"
                                    });
                                }
                            }
                        })
                        .catch(function (err) {
                            SweetAlert.swal({
                                title: '',
                                text: err.message,
                                type: "warning"
                            });
                        });

                } else {

                    var data = {
                        "accountId": $scope.selectedAccount.id,
                        "modelId": $scope.selectedModel.id,
                        "trackManagerLeverage": $scope.trackManagerLeverage,
                        "leverage": $scope.leverageAmount,
                        "amount": $scope.allocationAmount,
                        //"redeemPercentage": 0
                    };

                    allocationApi.create(data)
                        .then(function (res) {

                            console.log("Allocation response: ", res);

                            var errors = [];

                            if (res.data.statusCode !== 200) {
                                var msg = "Allocation failed for model " + ($scope.selectedModel.name || $scope.selectedModel.title || $scope.selectedModel.name) + ".";
                                if (errors.length > 0) {
                                    msg = msg + ' ' + errors.join();
                                }
                                SweetAlert.swal({
                                    title: msg,
                                    type: "warning"
                                });

                                console.log(res.data.msg[0].description);
                            } else {

                                if (res.data.statusCode === 200) {

                                    var msg = "Allocation to " + ($scope.selectedModel.name || $scope.selectedModel.title || $scope.selectedModel.name) + " has been submitted and is pending approval.";
                                    clearPreviousSelections();
                                    $scope.th_selected_model = undefined;
                                    $scope.selectedModel = undefined;

                                    SweetAlert.swal({
                                        title: msg,
                                        type: "success"
                                    });
                                }
                            }
                        })
                        .catch(function (err) {
                            console.error(err);
                            var errors = [];
                            var msg = err.message;
                            if (errors.length > 0) {
                                msg = msg + ' ' + errors.join();
                            }
                            SweetAlert.swal({
                                title: '',
                                text: msg,
                                type: "warning"
                            });
                        });
                }
            });

        } else if ($scope.customAllocationSelected && $scope.customAllocationAmount == null) {
            //alert('please select valid allocation options');
            SweetAlert.swal("Error", "Please enter a custom allocation amount.", "error");
        } else if ($scope.customLeverageSelected && ($scope.customAmount.leverage == null || $scope.customAmount.leverage == '')) {
            //alert('please select valid allocation options');
            SweetAlert.swal("Error", "Please enter a custom leverage amount.", "error");
        } else {
            //alert('please select valid allocation options');
            SweetAlert.swal("Error", "Please select valid allocation amount.", "error");
        }
    };

    function clearSelectedAccount () {
        $scope.selectedAccount = undefined;
        $scope.accountAvailableCash = undefined;
    }

    /* events */

    $scope.$on('$destroy', function () {
        var callbacks = Dashboard.getObserverCallbacks();

        Allocations.currentAccount = undefined;
        Allocations.currentModel = undefined;
        Allocations.modelID = undefined;
        Dashboard.selectedAllocationModel = undefined;
        Dashboard.selectedAllocationAccount = undefined;

        // We must unregister any functions that were pushed into the observer callback array.
        // Otherwise the function is added to the array with every new tab instance
        // which leads to calling the registered function multiple times.
        // We could also check to see if the function is in the array before it is registered.
        // That would prevent registering the same function multiple times.
        callbacks.forEach(function (cb) {
            console.log(cb);
            if (cb.name === 'updateSelectedAccount' || cb.name === 'updateSelectedModel') {
                Dashboard.removeObserverCallback(cb);
            }
        });
    });

    $scope.$parent.$on('tabSwitched::allocate', function (event, tab) {

        if (tab.type === 'allocate') {
            console.log('switched to allocation tab', tab);
            var chart = $('#modelChart').highcharts();
            jQuery(window).resize();

            $timeout(function () {
                chart.reflow();
                if ($scope.modelChart === {}) {
                    console.log("no chart defined");
                    $scope.modelChart = $scope.chartConfig;

                }
                console.log('allocation view');
            }, 1);
        }
    });


    $scope.th_clear_model = function ($event) {
        $scope.th_selected_model = $scope.selectedModel = $scope.minimumInvestment = undefined;
        $scope.showChart = false;
        angular.element('th-model-input').focus();
    };

    $scope.th_focus_input = function ($event, $viewValue) {

        var typeAheadInput = angular.element('th-model-input');
        ev = $.Event("keydown");
        ev.keyCode = ev.which = 0;
        typeAheadInput.trigger(ev);
        return true;
    };

    $scope.th_blur_input = function ($event, $viewValue) {
        console.log($event);
        console.log($viewValue);
    };

    $scope.th_should_select = function ($event) { };

    /* internal methods */

    $scope.th_select_model = function ($item, $model, $label, $event) {
        var selected,
            item;

        selected = _.find($scope.hcv_models, function (model) {

            try {
                if (model.name == $item) {
                    return true;
                } else {
                    return false;
                }
            } catch (e) { }

        });

        //$scope.accountAvailableCash = 1000000 // for testing

        if (typeof selected !== undefined) {

            $scope.setModel(selected);
        } else {
            console.log("model not found in list: ", $model);
            toastr.error("Model not found.");
        }
    };

    function clearPreviousSelections() {
        $scope.customAllocationSelected = $scope.customLeverageSelected = false;
        $scope.customAllocationAmount = null;
        $scope.customAmount.leverage = '';
    }

    //http://stackoverflow.com/questions/12576798/angularjs-how-to-watch-service-variables
    function updateSelectedModel(origin) {
        console.log(origin);

        if (origin === 'search') {
            $scope.selectedModel = SearchFilters.selectedRows[0].data.node;
            changeModel();

        } else if (origin === 'model') {

            var selectedModel = Models.selectedModel;

            if (selectedModel.field_fund_model_guid || selectedModel.id) {
                var guid = selectedModel.id || (selectedModel.field_fund_model_guid.und ? selectedModel.field_fund_model_guid.und[0].value.toLowerCase() : undefined);

                if (guid) {
                    $scope.selectedModel = selectedModel; // Models.getModelProfileById(guid, $scope.hcv_models);

                    if ($scope.selectedModel) {
                        changeModel(origin);
                    }
                } else {
                    toastr.error("Unable to find model.");
                    console.log(selectedModel);
                }
            } else {
                toastr.error("This model has no guid");
                console.log(selectedModel);
            }

        } else if (origin === 'main' || origin === 'fromMain') {

            if (Dashboard.selectedAllocationModel) {
                var modelId = Dashboard.selectedAllocationModel.id;
                $scope.selectedModel = Models.getModelProfileById(modelId, $scope.hcv_models);

                if (angular.isDefined($scope.selectedModel)) {
                    changeModel();
                }
            }

        } else if (origin === 'accountSummary') {

            // clear out model related fields when coming from summary page
            $scope.selectedModel = undefined;
            $scope.th_selected_model = undefined;
            $scope.minimumInvestment = undefined;
            $scope.loading = false;

        } else if (origin === 'search-allocate') {

            $scope.selectedModel = Dashboard.selectedAllocationModel;
            changeModel();
        }
    }

    // Only runs if the allocate tab is already open and callback has been registered
    function updateSelectedAccount(origin) {

        console.log("allocation account update initialized from ", origin);

        if (origin === 'main' ||
            origin === 'fromMain' ||
            origin === 'search' ||
            origin === 'model' ||
            origin === 'accountSummary') {

            var activeAccount = Dashboard.selectedAllocationAccount || Dashboard.getActiveAccount();

            if ($scope.accounts.length) $scope.selectedAccount = $scope.accounts.filter(function (account) { return account.id == activeAccount.id && (account.permission ? account.permission === 'fullAccess' : true); })[0];
            else $scope.selectedAccount = activeAccount;
            // $scope.accountAvailableCash = $scope.selectedAccount.statistics.cash;
            // if selected account doesn't match selected account from main
            // set it and then update the model
            // otherwise update the model only
            // if (activeAccount && $scope.selectedAccount.name != activeAccount.name) {
            if (activeAccount && $scope.selectedAccount) {
                $scope.selectedAccount = Dashboard.selectedAllocationAccount || Dashboard.getActiveAccount() || $scope.defaultAccount || $scope.accounts[0];
                if ($scope.selectedAccount) {
                    $q.when($scope.setAccount($scope.selectedAccount, $scope.selectedAccount.guid || $scope.selectedAccount.id))
                    .then(function () {
                        updateSelectedModel(origin);
                    });
                }
            } else {
                updateSelectedModel(origin);

            }
        } else {
            updateSelectedModel(origin);
        }

    }

    function changeAccount(updateModel) {

        var accountID;


        clearPreviousSelections();
        $scope.selectedAllocationAmount = '';
        $scope.customAmount.leverage = '';

        if (updateModel) {

            $scope.selectedAccount = Dashboard.selectedAllocationAccount || Dashboard.getActiveAccount() || $scope.defaultAccount;

            if (!$scope.th_selected_model) {
                $scope.th_selected_model = $scope.selectedModel.name;
            }

            accountID = $scope.selectedAccount.id || Dashboard.selectedAllocationAccount.id;

            return;
        } else {
            try {
                accountID = $scope.selectedAccount.id || Dashboard.selectedAllocationAccount.id;
            } catch (e) {
                console.log(e);
                $scope.selectedAccount = $scope.accounts[0];
                accountID = $scope.selectedAccount ? $scope.selectedAccount.id : null;
            }
        }

        if ($scope.selectedAccount) {
            Allocations.currentAccount = $scope.selectedAccount;
            $scope.accountAvailableCash = $scope.selectedAccount.availableCash || $scope.selectedAccount.cash || undefined;
            getAccountData(accountID);
        }
        if ($scope.selectedModel) setMinimumInvestment();
    }

    function changeModel(origin) {

        $scope.chartLoaded = false;
        $scope.showChart = false;

        if (origin === 'fromMain' || origin === 'model') {
            $scope.selectedModel = Dashboard.selectedAllocationModel ? Dashboard.selectedAllocationModel : $scope.selectedModel;
            $scope.th_selected_model = $scope.selectedModel.name || $scope.selectedModel.title;
        } else {
            if (angular.isDefined($scope.selectedModel)) {
                $scope.th_selected_model = $scope.selectedModel.name || $scope.selectedModel.title;
            } else {
                $scope.selectedModel = Allocations.currentModel;
            }
        }
        // for mocking purposes
        //$scope.hasEnoughCash = true;
        //
        if ($scope.selectedModel) {

            $scope.hasExistingAllocationToModel = Allocations.hasExistingAllocation($scope.accountAllocations, $scope.selectedModel);

            clearPreviousSelections();
            var model_index = $scope.th_models.indexOf($scope.th_selected_model),
                modelData = {
                    index: model_index,
                    model: {
                        title: $scope.selectedModel.name,
                        guid: $scope.selectedModel.id
                    }
                };

            Allocations.currentModel = $scope.selectedModel;
            Allocations.modelID = $scope.selectedModel.id;
            $scope.managerLeverage = 1;

            //localStorage.setItem('AllocationLastSelectedModel', JSON.stringify($scope.selectedModel));
            //HCV_storage[USER.username].AllocationLastSelectedModel = $scope.selectedModel;
            if ($scope.showChart) {
                plotModel($scope.selectedModel.id);
            }
            setMinimumInvestment();
        }
    }

    function setMinimumInvestment() {

        debugger;

        $scope.minimumInvestment = Models.getMinimumById($scope.selectedModel.id) || $scope.selectedModel.minimumAllocation || $scope.selectedModel.minimum_allocation || null;

        if ($scope.hasExistingAllocationToModel) {
            $scope.minimumInvestment = 1;
        }

        if (!angular.isDefined($scope.selectedAccount)) return;

        if (typeof $scope.selectedModel != 'undefined' && $scope.minimumInvestment !== null && $scope.minimumInvestment !== '') {

            if (Number($scope.minimumInvestment) <= Number($scope.accountAvailableCash)) {

                $scope.hasEnoughCash = true;
                var amountDiff = parseFloat($scope.accountAvailableCash) - parseFloat($scope.minimumInvestment);

                // If difference if greater than $30,000
                // Round to nearest $10,000
                // Else round to nearest $1,000
                var multiplier = (amountDiff > 30000) ? 10000 : 1000;


                $scope.allocationAmounts = [
                    {
                        value: parseFloat($scope.minimumInvestment),
                        selected: false,
                        subHeader: 'Minimum Investment'
                    },
                    {
                        value: Math.round((parseFloat(amountDiff) * 0.333 + parseFloat($scope.minimumInvestment)) / multiplier) * multiplier == 0 ? (Math.round((parseFloat(amountDiff) * 0.666 + parseFloat($scope.minimumInvestment)) / multiplier) * multiplier) / 2 : Math.round((parseFloat(amountDiff) * 0.333 + parseFloat($scope.minimumInvestment)) / multiplier) * multiplier,
                        selected: false,
                        subHeader: ''
                    },
                    {
                        value: Math.round((parseFloat(amountDiff) * 0.666 + parseFloat($scope.minimumInvestment)) / multiplier) * multiplier,
                        selected: false,
                        subHeader: ''
                    },
                    {
                        value: parseFloat($scope.accountAvailableCash),
                        selected: false,
                        subHeader: 'Available Cash'
                    }
                ];

            } else {
                $scope.hasEnoughCash = false;
            }

        } else {
            $scope.minimumInvestment = undefined;
        }
    }

    function handlePerformanceResponse(res) {

        $timeout(function () {

            var modelPerformanceStream = res.data.performance.payload.portfolios[100].windows["-1"].monthly_model.performance;
            var benchmarkPerformanceStream = res.data.performance.payload.portfolios[100].windows["-1"].monthly_model.benchmark_stream;

            //var performanceStream = res.performanceStream.performance;
            $scope.selectedModel.performanceStream = modelPerformanceStream;
            var benchmarkGuid = res.data.models[$scope.selectedModel.id].information.benchmarks["0"].guid;
            var benchmarkName = res.data.models[$scope.selectedModel.id].information.benchmarks["0"].name;

            if (!_.keys(modelPerformanceStream).length)
                $scope.showChart = false;
            else {

                var benchmark = [];
                var leverage = $scope.customLeverageSelected ? $scope.customAmount.leverage : $scope.selectedLeverageAmount;
                $scope.chartLoaded = true;
                $scope.selectedLeverageAmount = leverage;
                $scope.modelPerformanceResponse = res; // cache the response so we don't have to make the performance call each time the leverage changes
                buildChart(modelPerformanceStream, benchmarkName, benchmarkPerformanceStream, leverage);
            }
        });
    }

    function plotModel() {

        // use this as a max-width to prevent chart from running out of its container
        $scope.maxChartWidth = jQuery('.allocation-amount-selection-wrap').width() + 'px';
        if ($scope.showChart) {
            try {

                // use the cached model performance response if we have it, otherwise make the performance call
                if ($scope.selectedModel.performanceStream) {
                    handlePerformanceResponse($scope.modelPerformanceResponse);
                } else {
                    $scope.chartLoaded = false;

                    //$scope.selectedModel.getPerformance({grouping: 'm'})

                    var payload = {
                        model_ids: $scope.selectedModel.id,
                        leverages: $scope.customLeverageSelected ? $scope.customAmount.leverage : $scope.selectedLeverageAmount,
                        percentages: 100
                    };
                    performanceApi.portfolioPerformance(payload)
                        .then(handlePerformanceResponse)
                        .catch(function (err) {
                            // console.error(err);
                            // toastr.error(err.errorMessage);
                            Dashboard.toastError(err.errorMessage, err);
                            $scope.chartLoaded = false;
                            $scope.showChart = false;
                        });
                }
            } catch (e) {
                $scope.chartLoaded = true;
                $scope.showChart = false;
                toastr.error("Unable to load performance for " + $scope.selectedModel.name);
                console.log("unable to get model performance");
            }
        }

    }

    function buildChart(model, benchmarkName, benchmark, leverage) {
        var days = [],
            modelData = [],
            benchmarkData = [],
            startModel = 1000,
            startBenchmark = 1000,
            startingBalance = 1000,
            leverage = leverage ? leverage : 1,
            modelName = $scope.selectedModel.name || $scope.selectedModel.title || $scope.selectedModel.name;


        var modelDays = _.keys(model);

        // TODO: Refactor this once the benchmark stream is available
        // var benchmarkDays = _.keys(benchmark);

        // var commonDays = _.intersection(modelDays, benchmarkDays);

        // console.log("Common days: ", commonDays);

        // 12/19/16: JB added this to reconcile timestamp differences in benchmark stream
        // shouldn't be necessary once benchmark timestamp issue is fixed
        // if (!commonDays.length){
        //     var _benchmark = {};
        //     _.each(benchmark, function(val, date){
        //         var timestamp = (date * 1) + 3600;
        //         _benchmark[timestamp] = val;
        //     });

        //     benchmark = _benchmark;
        // }

        // _.pairs(model).forEach(function(point){
        //     var timestamp = point.range.start.timestamp * 1000;
        //     var currentModel = (startModel + ( point.pnl.percent * leverage  ) * startModel);
        //     modelData.push( [timestamp, currentModel] );
        //     startModel = currentModel;
        // })


        for (var day in model) {

            if (typeof model[day] !== 'undefined' && model[day] !== null && typeof benchmark[day] !== 'undefined' && benchmark[day] !== null) {
                var timestamp = day * 1000,
                    currentModel = (startModel + (parseFloat(model[day] * leverage) / 100) * (startModel));
                    currentBenchmark = startBenchmark + (parseFloat(benchmark[day]) / 100) * startBenchmark;
    
                modelData.push({
                    x: timestamp,
                    y: currentModel,
                    value: model[day]
                });

                benchmarkData.push({
                    x: timestamp,
                    y: currentBenchmark,
                    value: benchmark[day]
                });
    
                startModel = currentModel;
                startBenchmark = currentBenchmark;
            }
        }
        
        if (modelData.length) {

            // add starting point to beginning of the series'
            if (modelData.length){
                var firstModelPoint = modelData[0].x;
                var firstBenchmarkPoint = benchmarkData[0].x;

                var newFirstModelPoint = moment(firstModelPoint).subtract(1, 'month').valueOf();
                var newFirstBenchmarkPoint = moment(firstBenchmarkPoint).subtract(1, 'month').valueOf();

                modelData.unshift([newFirstModelPoint, startingBalance]);
                benchmarkData.unshift([newFirstBenchmarkPoint, startingBalance]);
            }

            $scope.noPoints = false;

            $scope.modelChart = {
                
                chart: {
                    type: 'area',
                    events: {
                        load: function (event) {
                            var _this = this;
                            $timeout(function () {
                                //console.log("should be resizing here");
                                var chart = _this;
                                $scope.chartLoaded = true;
                                if (angular.isFunction(chart.reflow)) chart.reflow();
                                chartInstance = chart;
                                //chart.setSize(width, 350);
                                //The below is an event that will trigger all instances of charts to reflow
                                //$scope.$parent.$broadcast('highchartsng.reflow');
                            }, 0);
                        }
                    }
                },
                title: {
                    text: 'Simulated Model Performance'
                },
                credits: {
                    enabled: false
                },
                style: {
                    width: '100%',
                    height: 300
                },
                yAxis: {
                    title: {
                        text: null
                    },
                    labels: {
                        align: 'right',
                        format: '${value:,.0f}',
                        formatter: function () {
                            return '$' + Highcharts.numberFormat(this.value, 0, '.', ',');
                        }
                    },
                },
                tooltip: {

                    formatter: function () {
                        //console.log(this);
                        var point = this,
                            balance = "$" + Highcharts.numberFormat(point.y, 2, '.', ','),
                            date = Highcharts.dateFormat('%B %e, %Y', point.x);

                        return '<span style="color:#166AAB">Date: </span><b>' + date + '</b><br/><span style="color:#166AAB">Balance</span>: <b>' + balance + '</b><br/>';
                    }
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                title: {
                    text: null,
                    style: {
                        display: 'none'
                    }
                },
                xAxis: {
                    tickWidth: 0,
                    gridLineWidth: 1,
                    type: 'datetime',
                    // labels:{
                    //   format: '{value: %d}',
                    //   align: 'left',
                    //   rotation: 45
                    // },
                    tickAmount: 390,
                },

                legend: {
                    enabled: true
                },

                exporting: {
                    enabled: false
                },
                series: [
                    {
                        id: 'Model Data',
                        name: modelName,
                        type: 'line',
                        lineWidth: 2,
                        connectNulls: true,
                        //marker: { radius: 2 },
                        data: modelData,
                        color: '#D85B08',
                    },
                    {
                        id: 'Benchmark',
                        name: benchmarkName,
                        type:'line',
                        lineWidth: 2,
                        connectNulls: true,
                        //marker: { radius: 2 },
                        data: benchmarkData,
                        color:'#2c557f',
                    }
                ],
                func: function (chart) {
                    //console.log(chart);
                    $timeout(function () {
                        //console.log("should be resizing here");
                        $scope.chartLoaded = true;
                        chart.reflow();
                        chartInstance = chart;
                        //chart.setSize(width, 350);
                        //The below is an event that will trigger all instances of charts to reflow
                        //$scope.$parent.$broadcast('highchartsng.reflow');
                    }, 0);
                }
            };
        } else {
            $scope.chartLoaded = true;
            $scope.noPoints = true;
            $scope.showChart = false;
            toastr.error("Historical returns chart is currently unavailable for this model.");
            console.log("Model: ", model);
            console.log("Benchmark: ", benchmark);
        }

    }

    function resetForm() {
        //console.log('resetForm');
        angular.forEach($scope.allocationAmounts, function (amount, key) {
            amount.selected = false;
        });
        angular.forEach($scope.leverageAmounts, function (amount, key) {
            amount.selected = false;
        });
        $scope.customAllocationSelected = false;
        $scope.customLeverageSelected = false;
        $scope.customAllocationAmount = '';
        $scope.customAmount.leverage = '';
        $scope.trackManagerLeverage = true;
        $rootScope.showChart = false;
    }

    function lockViewOnlyAccounts() {

        $scope.viewOnlyAccount = false;
        if (typeof $scope.selectedAccount.accountInfo.field_is_viewer.und != 'undefined' && parseInt($scope.selectedAccount.accountInfo.field_is_viewer.und[0].value) === 1) {
            console.log('warning!! view only account', $scope.selectedAccount.accountInfo.field_is_viewer.und);

            $scope.loading = false;
            $scope.viewOnlyAccount = true;

            return true;
        }
        return false;
    }

    function compareAccount(a, b) {
        if (a.accountInfo.title.trim() < b.accountInfo.title.trim())
            return -1;
        if (a.accountInfo.title.trim() > b.accountInfo.title.trim())
            return 1;
        return 0;
    }


    function compareTitle(a, b) {
        if (a.title.trim() < b.title.trim())
            return -1;
        if (a.title.trim() > b.title.trim())
            return 1;
        return 0;
    }
})
    .directive('typeaheadFocus', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attr, ctrl) {
                element.bind('click', function () {
                    if (typeof ctrl.$viewValue == 'undefined') {
                        ctrl.$setViewValue(' ');
                    }
                });
                element.bind('blur', function () {
                    if (typeof ctrl.$viewValue == 'undefined' || typeof scope.th_selected_model == 'undefined') {
                        ctrl.$setViewValue(undefined);
                    }
                });
            }
        };
    });
