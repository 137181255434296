angular.module('MainCtrl', ['CsvGenerator', 'RedemptionModal', 'treeGrid', 'ReportingFactory'])
  .controller('main', function($q, Allocations, FeatureFlags, ExclusionsFactory, $scope, $filter, $rootScope, $timeout, toastr, Tabs, Dashboard, Billing, TargetWeightFactory, Models, USER, EDITOR_ENABLED, Dashboard_Config, SweetAlert, $uibModal, $modal, $templateCache, SmartXFactory) {

    var parentTab = $scope.$parent.tab;
    var lastSelectedAccount = parentTab.selectedAccount;
    var tablist = Tabs;
    var tabType = $scope.tab.type;
    var account;
    var accountAPI = SmartXFactory.getAccountAPI();
    var allocationAPI = (typeof allocationAPI !== 'undefined') ? allocationAPI : SmartXFactory.getAllocationAPI();

    var billingTypeMapping = {
      "monthlyInAdvance": "Monthly in Advance",
      "monthlyInArrears": "Monthly in Arrears",
      "quarterlyInAdvance": "Quarterly in Advance",
      "quarterlyInArrears": "Quarterly in Arrears",
      "annualInArrears": "Annual in Arrears"
    };
    
    // edit account name
    $scope.accountEditMode = false;
    $scope.updatingAccountName = false;
    $scope.saveAccountName = saveAccountName;
    $scope.toggleEditMode = toggleEditMode;
    $scope.cancelEdit = cancelEdit;

    $scope.mainTabLoadingHTML = '<div class="loading-msg col-xs-12"><p class="text-center">Please Wait<p><h3 class="text-center">Loading Your Account...</h3></div>';
    $scope.tabIndex = parentTab.index;
    $scope.refreshing = false;
    $scope.isNewAccount = false;
    $scope.errorLoadingAccount = false;
    $rootScope.showingWelcome = false;
    $scope.multiAccount = {
      selectedAccounts: []
    };
    $scope.isTreeExpanded = false;
    $scope.parentTab = parentTab;
    $scope.chartLoaded = false;
    $scope.disabled = false;
    $scope.isFlat = true;
    $scope.is_ria = USER.is_ria ? true : false;
    $scope.editorEnabled = EDITOR_ENABLED == 'on' ? true : false;
    $scope.accountSnapshotDisabled = USER.accountSnapshotDisabled;
    $scope.accessGroups = [];
    $scope.accessAccounts = [];

    $scope.th_accounts = [];
    $scope.th_account = {
      selected: undefined
    };

    $scope.accessAllocations = [];
    $scope.pendingAllocationRequests = [];
    $scope.accessModelsAllocatedTo = [];
    $scope.guidPayload = {};

    $scope.dashboardConfig = Dashboard_Config;
    $scope.hasPortfolios = false;

    $scope.deleteDetails = deleteDetails;

    if (USER.completed_profile != '0') {
      $scope.hasPortfolios = true;
    }

    $scope.performanceInfoPopover = Dashboard.performanceInfoPopover;
    $scope.showingAllocationPerformanceInfo = false;

    $scope.showAllocationPerformanceInfo = function() {
      $scope.showingAllocationPerformanceInfo = !$scope.showingAllocationPerformanceInfo;
    };

    $scope.updateShouldRebalance = updateShouldRebalance;

    $scope.isEnabled = FeatureFlags.isEnabled;
    $scope.features = Dashboard.getUserFeatures();

    var accounts = Dashboard.accounts;

    $scope.$parent.tab.lastActive = new Date().getTime() / 1000;
    $scope.smartX = true;

    $scope.reportOptions = {
      'showAccountHoldings': false,
      'showAccountPerformance': true,
      'showToday': false,
      'showMTD': false,
      'showAllocationHoldings': true,
      'showAllocationPercentage': true,
      'customStart': moment().subtract(1, 'quarter').startOf('quarter').format("MM/DD/YYYY"),
      'customEnd': moment().subtract(1, 'quarter').endOf('quarter').format("MM/DD/YYYY")
    };

    $scope.my_tree_handler = function(branch) {};
    $scope.my_tree = tree = {};
    $scope.tree_data = [];
    $scope.expanding_property = {
      field: "Name",
    };
    $scope.col_defs = [{
      field: "Percent",
      cellTemplate: "<span>{{row.branch[col.field] * 100 | number : 2 }}%</span>"
    }];

    // report period date picker
    $scope.formats = ['MM/dd/yyyy', 'shortDate', 'dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy'];
    $scope.format = $scope.formats[0];
    $scope.opened = {
      start: false,
      end: false
    };
    $scope.minDate = '';
    $scope.maxDate = moment().format();
    $scope.dateOptions = {
      formatYear: 'yy',
      startingDay: 7
    };

    $scope.canManageExclusions = canManageExclusions;

    // this prevents the dropdown from closing when clicking inside the date picker
    $scope.$watch('opened', function(newVal){
      if (angular.isDefined(newVal)){
        if (newVal.start || newVal.end) {
          $scope.autoClose = "disabled";
        } else {
          $timeout(function() {
            $scope.autoClose = "outsideClick";
          });
        }
      }
    }, true);

    function isDateRangeValid() {
      var start = $scope.reportOptions.customStart;
      var end = $scope.reportOptions.customEnd;
      var startDateValid = moment(start).isValid();
      var endDateValid = moment(end).isValid();

      if (startDateValid && endDateValid) {
        var startTs = moment(start).unix();
        var endTs = moment(end).unix();
        if (startTs > endTs || endTs < startTs) return false;
        else return true;
      }
    }

    // $scope.$watch(isDateRangeValid, function(newVal, oldVal){
    //   console.log('New Val: ', newVal);
    //   console.log('Old Val: ', oldVal);
    // });

    $scope.pendingAllocations = [];
    $scope.filledAllocations = [];

    var activeTab = Tabs.getActiveTab();
    Tabs.setActiveTab(activeTab);
    Tabs.setLastActive(activeTab);
    $scope.lastRefresh = new Date().getTime() / 1000;

    // when a new instance of this controller is created
    // setLastDashboard to this
    Tabs.setLastDashboard(parentTab);

    $scope.$on('refresh-account-details', function(e, accountId) {
      if (accountId === $scope.selectedAccount.id) {
        console.log('Refreshing account details for ', accountId);
        $scope.refresh();
      }
    });

    $scope.$on('update-targets', function(event, args) {
      if (!angular.isDefined($scope.selectedAccount)) {
        return;
      }
      var targetId = $scope.selectedAccount.targetWeightId;
      loadDefaultTargetDetails(targetId);
    });

    $scope.$on('allocationRequestComplete', function(event, newRequest){
      $scope.pendingAllocationRequests.unshift(newRequest);
    });

    $scope.$watch('selectedAccount', function(newVal, oldVal){
      if (newVal && newVal.permission){
        $scope.canEdit = (newVal.permission === 'fullAccess');
      }
    });

    $scope.$on('chartError', function(event, msg) {

      if (parentTab.active) {
        $scope.loading = false;
        $scope.errorLoadingAccount = false;
        $scope.chartError = true;
        // $scope.killTimer();
      } else {
        $scope.chartError = true;
      }

    });

    var dashboardAcctAccessListener = $rootScope.$on('dashboard-account-access-set', function(event, args) {

      // $scope.accessAccounts = args.accounts;

      // angular.forEach($scope.accessAccounts, function(account){
      //     try {
      //         $scope.th_accounts.push(account.name);
      //     } catch (e){
      //         console.error("Missing information for model with guid: ", account.id);
      //     }
      // });
      // console.log("access accounts: ", $scope.accessAccounts);

      var guid = $scope.selectedAccount.id || $scope.selectedAccount.guid;
      loadAccountById(guid);
    });

    $scope.th_clear_account = function($event){
      $scope.th_account.selected = '';
      angular.element('#th-account-input').focus();
    };

    $scope.on_tree_click = function(branch) {
      if (branch.id) {
        var targets = branch.originalTarget || $scope.defaultTarget;
        TargetWeightFactory.setActiveTarget(targets);
        $scope.addTab('targetEdit');
      }
    };

    $scope.toggleTreeData = function() {
      $scope.isFlat = !$scope.isFlat;
      if (!$scope.isFlat) {

        $timeout(function() {
          $scope.my_tree.expand_all();
        }, 1);

      }
    };

    $scope.editDefaultTarget = function() {
      if ($scope.selectedAccount.internalType === 'sma') {
        // show the assignment dialog
        $scope.assignDefaultTarget(true);
      } else {
        var targets = $scope.defaultTarget;
        targets.isDefaultTarget = true;
        targets.accountNumber = $filter('sanitizeSmartXAccountName')($scope.selectedAccount.brokerageAccountNumber, 'number');
        targets.brokerage = $scope.selectedAccount.custodian ? $scope.selectedAccount.custodian.name : $scope.selectedAccount.brokerage;
        targets.isApm = $scope.selectedAccount.isApm;
        // disable editing controls when landing on the edit target page
        if ($scope.selectedAccount.permission != 'fullAccess'){
          targets.accessLevel = $scope.selectedAccount.permission;
        }
        
        TargetWeightFactory.setActiveTarget(targets);
        $scope.addTab('targetEdit');
      }
    };

    $scope.assignSmaModel = function() {
      
      var modalInstance = $modal.open({
        animation: true,
        template: $templateCache.get('assignModelToAccountModal.tpl.html'),
        resolve: {
          selectedAccount: function () {
            return $scope.selectedAccount;
          }
        },
        controller: 'AssignModelToAccountModalCtrl',
        controllerAs: 'vm'
      });

      modalInstance.result.then(function(payload) {

        var data = {
          "accountId": $scope.selectedAccount.id,
          "modelId": payload.selectedModel.id,
          "trackManagerLeverage": true,
          "leverage": 1
        };

        $scope.creatingSma = true;

        console.log("Model Assign Payload: ", data);

        $q.when(Allocations.createSmaAllocation(data))
        .then(function() {

          // refresh
          try {
            toastr.success("SMA allocation created");
            init();
          } catch (e) {
            console.error(e);
          }
        })
        .catch(function(err) {
          Dashboard.toastError(err.message, err);
        })
        .finally(function(){
          $scope.creatingSma = false;
        });
      });
    };

    $scope.assignDefaultTarget = function(editingSmaModel) {

      var target = $scope.defaultTarget;
      target.isDefault = true;
      var modalInstance = $modal.open({
        animation: true,
        template: $templateCache.get('assignTargetToAccountModal.tpl.html'),
        resolve: {
          target: function() {
            return target;
          },
          accounts: function() {
            return [];
          },
          selectedAccount: function () {
            return $scope.selectedAccount;
          },
          editingSmaModel: function () {
            return editingSmaModel;
          }
        },
        controller: 'AssignTargetToAccountModalCtrl',
        controllerAs: 'vm'
      });

      modalInstance.result.then(function(payload) {

        $rootScope.$broadcast('dashboard-check-rebalance');
        var extraCash = payload.extraCash;
        if (extraCash) {
          extraCash = extraCash.replace(/[%,]/g, '');
          extraCash = parseFloat(extraCash);
        }
        payload = payload.targetsToAdd[0];
        var updatePayload = [];
        const targetPercent = Number(((payload.percentTarget - (extraCash || 0)) / 100).toFixed(14))
        var payload = {
          "type": payload.type,
          "target": {
            "id": payload.id || target.twgId,
            "leverage": 1,
            "matchModelLeverage": true
          },
          "percent": targetPercent,
          "precision": {
            "upper": payload.percentUpperPrecision,
            "lower": payload.percentLowerPrecision
          },
          "threshold": {
            "upper": payload.percentUpperThreshold,
            "lower": payload.percentLowerThreshold
          },
          "action": 'create'
        };

        updatePayload.push(payload);

        var cashDetail = _.find($scope.defaultTarget.details, function (detail){
          return detail.target.type === 'cash';
        });

        // delete existing sma detail if we are adding a new one
        if ($scope.selectedAccount.internalType === 'sma') {
          var existingSmaModelDetail = _.find($scope.defaultTarget.details, function (detail){
            return detail.target.type === 'model' && detail.target.id !== payload.target.id;
          });

          // skip if they somehow try to switch to the model that's already in use
          if (existingSmaModelDetail && payload.target.id !== existingSmaModelDetail.target.id) {
            var deleteExistingSmaModelPayload = {
              "type": "model",
              "id": existingSmaModelDetail.id,
              "target": {
                "id": existingSmaModelDetail.target.id,
                "matchModelLeverage": true,
                "leverage": 1
              },
              "action": 'delete',
              "percent": 0,
              "precision": $scope.defaultTarget.details[0].precision,
              "threshold": $scope.defaultTarget.details[0].threshold
            };

            updatePayload.push(deleteExistingSmaModelPayload);
          }
        }

        var cashUpdatePayload = {
          "type": 'cash',
          "target": {
            "id": null,
          },
          "percent": extraCash ? math.format((extraCash / 100), {precision: 14}) : 0,
          "precision": $scope.defaultTarget.details[0].precision,
          "threshold": $scope.defaultTarget.details[0].threshold
        };

        if (cashDetail) {
          cashUpdatePayload.id = cashDetail.id;
          cashUpdatePayload.action = 'modify';
        } else {
          cashUpdatePayload.action = 'create';
        }

        updatePayload.push(cashUpdatePayload);

        $scope.tree_data = [];
        $scope.loadingTargets = true;

        console.log("Target Assign Payload: ", updatePayload);

        //Promise.all(reqs)
        TargetWeightFactory.updateDetails($scope.defaultTarget.id, updatePayload)
        .then(function(response) {

          loadDefaultTargetDetails($scope.defaultTarget.id);

          // only ask to rebalance if the account is actually funded / can trade
          if ($scope.selectedAccount.status == 'active' && $scope.selectedAccount.internalType !== 'sma'){

            if ($scope.selectedAccount.shouldRebalance == false){
              // $rootScope.$broadcast('update-targets');
              SweetAlert.swal({
                  title: "Next Step",
                  text: "Do you want to turn rebalancing on for this account?",
                  type: "success",
                  showCancelButton: true,
                  confirmButtonColor: "#DD6B55",
                  confirmButtonText: "Yes",
                  cancelButtonText: "No",
                  closeOnConfirm: false,
                  closeOnCancel: true,
                  allowEscapeKey: true,
                  allowOutsideClick: true,
                  showLoaderOnConfirm: true
                },
                function(isConfirm) {
                  if (isConfirm) {

                    // turn on shouldRebalance for the account
                    updateShouldRebalance($scope.selectedAccount)
                    .then(function(res){
                      SweetAlert.swal({
                        title: "Next Step",
                        text: "Do you want to rebalance this new account?",
                        type: "success",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                        closeOnConfirm: true,
                        closeOnCancel: true,
                        allowEscapeKey: true,
                      },
                      function(isConfirm) {
                        if (isConfirm) {
    
                          Tabs.addTab('rebalanceAccountsListByInvestmentV2');
    
                        } else {
                          jQuery('.sweet-alert').remove();
                        }
                      });
                    })
                    .catch(function(err){
                      console.error(err);
                      SweetAlert.swal({
                        title: 'Error turning rebalancing ON',
                        type: "warning"
                      });
                    });

                  } else {
                    jQuery('.sweet-alert').remove();
                  }
                });
            } else {
              $rootScope.$broadcast('update-targets');
              SweetAlert.swal({
                  title: "Next Step",
                  text: "Do you want to rebalance this new account?",
                  type: "success",
                  showCancelButton: true,
                  confirmButtonColor: "#DD6B55",
                  confirmButtonText: "Yes",
                  cancelButtonText: "No",
                  closeOnConfirm: true,
                  closeOnCancel: true,
                  allowEscapeKey: true,
                },
                function(isConfirm) {
                  if (isConfirm) {

                    Tabs.addTab('rebalanceAccountsListByInvestmentV2');

                  } else {
                    jQuery('.sweet-alert').remove();
                  }
                });
            }

          }

          return null;
        })
        .catch(function(err) {
          Dashboard.toastError('Error assigning target', err);
          $scope.loadingTargets = false;
        });
      });
    };

    $scope.requestWithdrawal = function() {

      //Show pop-up where user fills out their redemption amount
      var modalInstance = $uibModal.open({
        animation: true,
        template: $templateCache.get('redemptionModal.html'),
        controller: 'RedemptionModalCtrl',
        resolve: {
          account: function() {
            return $scope.selectedAccount;
          }
        },
        controllerAs: 'vm'
      });

      modalInstance.result
        .then(function(data) {

          var amount = data.amount;
          var notes = data.notes;
          var user = USER;
          var globals = Drupal.settings;
          var profile = globals.user_profile;
          var riaFirm = globals.ria_firm ? globals.ria_firm : null;
          var identity = IdentityFactory.getActiveUser();
          
          debugger;

          var payload = {
            "amount": amount ? amount.toString().replace(/,/g, '').replace('$', '') : '',
            "notes": notes,
            "profile": profile,
            "availableCash": $scope.selectedAccount.statistics.cash,
            "account": $scope.selectedAccount.name,
            "brokerage": $scope.selectedAccount.brokerageName,
            "brokerageAccountNumber": $scope.selectedAccount.brokerageAccountNumber,
            "riaFirm": riaFirm,
            "email": (identity && identity.profile) ? identity.profile.email : null,
            "username": (identity && identity.profile) ? identity.profile.preferred_username : null
          };

          Dashboard.requestWithdrawal(payload)
            .then(function(res) {
              // console.log("redemption request response: ", res);
              if (res.data.msg_result) {
                toastr.success("Your withdrawal request has been successfully submitted and is pending approval.");
              } else {
                var msg = "There was an error while processing your request.  If this issue persists, don\'t hesitate to contact us directly at (561) 835-8690";
                Dashboard.toastError(msg, res.data);
              }
            });
        });
    };

    $scope.open = function open(picker){
      $scope.opened[picker] = true;
    };

    $scope.disabled = function disabled(date, mode) {
      return ( mode === 'day' && ( date.getDay() === 0 || date.getDay() === 6 ) );
    };

    $scope.changeAccount = function(refreshing) {

      if (typeof $scope.selectedAccount == 'undefined') {
        return false;
      }

      var guid = $scope.selectedAccount.id;
      var title = $scope.selectedAccount.name;
      // var acc_index = $scope.accounts[guid];

      // // kill any leftover timers
      // if (parentTab.updates) {
      //   $scope.killTimer();
      // }

      // determine whether we got here from within the refresh function or an account switch
      $scope.refreshing = refreshing;
      // if it was called from a refresh, don't show the loading screen while it fetches new data
      $scope.loading = refreshing ? false : true;

      // reset the allocation list when changing accounts
      if ($scope.loading) {
        $scope.pendingAllocations = [];
        $scope.filledAllocations = [];
      }

      $scope.$parent.tab.selectedAccount = $scope.selectedAccount;

      // $scope.$parent.tab.title = title;

      $scope.$parent.tab.title = $filter('sanitizeSmartXAccountName')(title, 'name');

      Dashboard.setActiveAccount($scope.selectedAccount);

      var activeTab = Tabs.getActiveTab();
      var tab = $scope.tab;
      if (tab.active || refreshing) {
        loadAccountById(guid);
        if ($scope.selectedAccount && $scope.selectedAccount.targetWeightId) loadDefaultTargetDetails($scope.selectedAccount.targetWeightId);
      }
    };

    $scope.toggleExpandedTree = function() {
      $scope.isTreeExpanded = !$scope.isTreeExpanded;

      if ($scope.isTreeExpanded) {
        $scope.my_tree.expand_all();
      } else {
        $scope.my_tree.collapse_all();
      }
    };

    $scope.refresh = function() {
      $scope.changeAccount(true);

    };

    $scope.addTab = function(type, allocation, target) {

      if (type === 'alerts-manager'){
        var tab = Tabs.addTab(type);
        return;
      }

      if (type === 'cash-manager'){
        var tab = Tabs.addTab(type, $scope.selectedAccount.id, null, $scope.selectedAccount);
        return;
      }

      if (type == 'targetEdit') {
        var tab = Tabs.addTab(type);
      } else {
        var tab = Tabs.addTab(type, $scope.selectedAccount.guid, null, $scope.selectedAccount);

      }

      if (type == 'accountHistory') {
        Dashboard.setActiveAccount(this.selectedAccount);
        Dashboard.currentAccount = Dashboard.getActiveAccount();
      }



      if (type === 'holdings') {
        Dashboard.setActiveAccount(this.selectedAccount);
        Dashboard.currentAccount = Dashboard.getActiveAccount();
        Dashboard.getObserverCallbacks();
        Dashboard.notifyObservers('main-holdings');
        return;
      } else if (type === 'liquidate') {
        Dashboard.selectedLiquidationModel = allocation;
        Dashboard.selectedLiquidationAccount = $scope.selectedAccount;
        Dashboard.notifyObservers('main-liquidate', $scope.models);

      } else if (type === 'allocate') {
        Dashboard.selectedAllocationModel = allocation.model || $scope.allocation.information.modelId;
        Dashboard.selectedAllocationAccount = $scope.selectedAccount;
        Dashboard.notifyObservers('fromMain');
      } else if (type === 'search') {
        if (tab.exists)
          $rootScope.$broadcast('tabSwitched::search', tab);
      }

    };

    $scope.setAccount = function(account) {
      $scope.averageFeePercent = null;
      $scope.selectedAccount = account;
      $scope.selectedAccount.guid = account.id;
      resetEditMode();
      $scope.changeAccount();
    };

    $scope.generatePdf = function () {

      var start = moment($scope.reportOptions.customStart).unix();
      var end = moment($scope.reportOptions.customEnd).unix();

      if (start > end || end < start) {
        return alert('Invalid date range.  The period start date must be before the period end date.');
      }

      $scope.generatingPdf = true;

      // filter allocations based on start & end
      // this will prevent fetching data for allocations that won't be included in the report
      var filteredAllocations = $scope.selectedAccount.allocations.filter(function(allocation){

        if (allocation.type === 'funding') return false;
          
        // exclude allocations that were closed before the report start date
        if (allocation.closeTimestamp) {
          return allocation.closeTimestamp.timestamp > start;
        } else 
        // exclude allocations that were opened after the report end date
        {
          return allocation.openTimestamp.timestamp < end;
        }
      });

      // Get Allocation Holdings & Performance Data
      var reqs = filteredAllocations.map(function(allocation) {

        return $scope.reportOptions.showAllocationHoldings ? [
          allocationAPI.getAllocationHoldings(allocation.id, { start: end }),
          allocationAPI.getAllocationPerformancesById(allocation.id, { grouping: "m", start: start, end: end })
        ] : [ allocationAPI.getAllocationPerformancesById(allocation.id, { grouping: "m", start: start, end: end }) ];
      });

      if ($scope.reportOptions.showAccountHoldings) reqs.push(accountAPI.getAccountHoldings($scope.selectedAccount.id, { start: end })); // get holdings as of the last day of the reporting period
      reqs.unshift(accountAPI.getAccountPerformanceForId($scope.selectedAccount.id, { grouping: "m", start: start, end: end }));

      Promise.all(_.flatten(reqs))
      .then(function(res){
        // console.log("Got allocation data: ", res)

        addDataToAllocations(filteredAllocations, _.pluck(res, 'data'));

        var accountPerformanceStream = _.first(res).data.performance.performance;

        if (_.first(accountPerformanceStream).summary.beginningMarketValue == 0) {
          _.first(res).data.performance.performance[0].summary.beginningMarketValue = _.first(accountPerformanceStream).summary.endingMarketValue - (_.first(accountPerformanceStream).summary.increaseByAmount + _.first(accountPerformanceStream).summary.decreaseByAmount);
        }
      
        var pdfData = {
          account: {
            name: $scope.selectedAccount.name,
            brokerage: $scope.selectedAccount.custodian.name,
            number: $scope.selectedAccount.brokerageAccountNumber,
            statistics: $scope.selectedAccount.statistics,
            allocations: filteredAllocations, // $scope.selectedAccount.allocations, // includes statistics, performanceSeries, and holdings,
            periodStart: moment(start*1000).format('MM/DD/YYYY'), // _.first(accountPerformanceStream).range.start.friendly,
            periodEnd: moment(end*1000).format('MM/DD/YYYY'), // _.last(accountPerformanceStream).range.end.friendly,
            beginningValue: _.first(accountPerformanceStream).summary.beginningMarketValue,
            endingValue: _.last(accountPerformanceStream).summary.endingMarketValue,
            inflows: calculateCashFlows('increase', _.first(res).data.performance.performance),
            outflows: calculateCashFlows('decrease', _.first(res).data.performance.performance),
            holdings: _.sortBy(_.last(res).data, function(position){ return -position.percentOfPortfolio; }),
            performanceStream:_.first(res).data.performance.performance/* .filter(function(period){ // exclude periods with no values
              return period.summary.beginningMarketValue != 0 && period.summary.endingMarketValue != 0;
            }) */
          },
          templateName: 'account-snapshot.twig',
          templateHeader: 'blend-report-header.twig',
          templateFooter: 'model-report-footer.twig',
          logoPath: (jQuery('#smartx-pdf-logo').length >= 1) ? jQuery('#smartx-pdf-logo').attr('src') : jQuery('#logo').attr('src'),
          includeCover: false,
          user: Drupal.settings.fullName,
          riaFirm: Drupal.settings.ria_firm || $scope.selectedAccount.enterprise.name,
          basePath: window.location.origin,
          options: $scope.reportOptions
        };

        Models.makePDF(pdfData)
        .then(function(res) {
          var blob = new Blob([res.data], {
            type: "application/pdf"
          });
          saveAs(blob, $scope.selectedAccount.brokerageAccountNumber + '_account_snapshot.pdf');
        })
        .catch(function(err) {
          // console.error(err);
          // toastr.error(err.message);
          Dashboard.toastError(err.message, err);
        })
        .finally(function(){
          $scope.generatingPdf = false;
        });
      })
      .catch(function(err){
        // console.error(err);
        // toastr.error('Error generating report. Unable to get additional allocation data.');
        Dashboard.toastError("Failed to generate report. Unable to get additional allocation data.", err);
        $scope.generatingPdf = false;
      });
    };

    function saveAccountName (account) {
      accountAPI = (typeof accountAPI !== 'undefined') ? accountAPI : SmartXFactory.getAccountAPI();

      // no change
      if (account.name === $scope.currentText) {
        $scope.accountEditMode = false;
        return;
      }

      $scope.updatingAccountName = true;

      accountAPI.updateAccountById(account.id, {
        name: account.name
      })
      .then(function(res){
        toastr.success("Name updated");
      })
      .catch(function(err){
        // console.error(err);
        // toastr.error("Error updating account");
        Dashboard.toastError("Failed to update account", err);

      })
      .finally(function(){
        $scope.updatingAccountName = false;
        $scope.accountEditMode = false;
      });
    }

    function toggleEditMode () {
      $scope.currentText = angular.copy($scope.selectedAccount.name);
      $scope.accountEditMode = !$scope.accountEditMode;
    }

    function cancelEdit () {
      $scope.selectedAccount.name = $scope.currentText;
      $scope.accountEditMode = false;
    }

    function updateShouldRebalance(account){

      // accountAPI = (typeof accountAPI !== 'undefined') ? accountAPI : SmartXFactory.getAccountAPI();
      $scope.updatingShouldRebalance = true;

      return accountAPI.updateAccountById(account.id, {
        shouldRebalance: !account.shouldRebalance
      })
      .then(function(result){
        account.shouldRebalance = !account.shouldRebalance;
        return Promise.resolve();
      })
      .catch(function(err){
        // console.error(err);
        // toastr.error(err.message);
        Dashboard.toastError(err.message, err);
        return Promise.reject();
      })
      .finally(function(){
        $scope.updatingShouldRebalance = false;
        $scope.$apply();
      });

    }

    function getDefaultAlloc(allocations) {
      // var matches = _.filter(allocations, function(allocation) {
      //   return allocation.isApmAllocation || !angular.isDefined(allocation.model);
      // });
      // if (matches.length) {
      //   return matches[0];
      // } else {
      //   return null;
      // }
      return _.find(allocations, function(allocation) { return allocation.type === 'account';});
    }

    function getAccountExclusions(id) {
      ExclusionsFactory.getAccountExclusions(id)
      .then(function(response){
        var exclusionsList = response.data.data;
        $scope.exclusionsList = exclusionsList;
      })
      .catch(function(err){
        // console.error(err);
        // toastr.error("Error getting account exclusions");
        Dashboard.toastError(err.message, err);
      });
    }

    // $scope.th_select_account = function($item, $model, $label, $event){
    //     var selected,
    //         item;

    //     selected = _.find($scope.accessAccounts, function(account){

    //         try {
    //             if (account.name == $item){
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         } catch(e) {}

    //     });

    //     //$scope.accountAvailableCash = 1000000 // for testing

    //     if(typeof selected !== undefined){

    //         $scope.setAccount(selected);
    //         $scope.th_account.selected = selected.name;
    //     } else {
    //         toastr.error("Account not found.");
    //     }
    // };

    // this will delete each non-cash detail in the default target and modify the cash detail weight to 100%
    function deleteDetails(defaultTarget, details){

      var verbiage = {
        uma: {
          title: "Removing Target Portfolio",
          text: "You are about to remove this account's target portfolio. This will cause the account's portfolio to return to 100% cash the next time it is rebalanced unless another target portfolio is selected.  Click 'Yes' to confirm."
        },
        sma: {
          title: "Removing SMA Model",
          text: "You are about to remove this account's SMA model.\n\nThe update submitted here is not communicated to the manager. Please make sure to inform the portfolio manager of this change request.\n\nClick 'Yes' to confirm.",
          altText:"You are about to remove this account's SMA model. /*This will cause the account's portfolio to return to 100% cash.*/ Click 'Yes' to confirm.",
        }
      };

      SweetAlert.swal({
        title: $scope.selectedAccount.internalType === 'sma' ? verbiage.sma.title : verbiage.uma.title,
        text: $scope.selectedAccount.internalType === 'sma' ? verbiage.sma.text : verbiage.uma.text,
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        closeOnConfirm: false,
        closeOnCancel: true,
        allowEscapeKey: true,
        showLoaderOnConfirm: true
      },
      function(isConfirm) {
        if (isConfirm) {
          $scope.deletingDetails = true;
  
          var newPayload = defaultTarget.details.map(function(detail){
            if (detail.target.type == 'cash') {
              detail.percent = 1;
              detail.type = 'cash';
              detail.target = {
                id: null,
                leverage: 1,
                matchModelLeverage: true
              };
              detail.action = 'modify';
            } else {
              detail.percent = 0;
              detail.action = 'delete';
            }
            return detail;
          });

          console.log("Remove target payload: ", newPayload);

          //Promise.all(reqs)
          TargetWeightFactory.updateDetails(defaultTarget.id, newPayload)
          .then(function(res){
            SweetAlert.swal({
                title : "Process Complete",
                type : "success"
            });
          })
          .catch(function(err){
            SweetAlert.swal({
                title : err.message,
                type : "error"
            });
          })
          .finally(function(){
            $scope.deletingDetails = false;
            loadDefaultTargetDetails(defaultTarget.id);
          });
        } else {
          jQuery('.sweet-alert').remove();
        }
      });

      
    }

    function canManageExclusions() {
      return $scope.selectedAccount && _.contains(['fullAccess', 'accountMaintenance'], $scope.selectedAccount.permission);
    }

    function addDetails(target, parentObj) {

      var groups = _.groupBy(target.details, function(detail){
        return detail.target.type;
      });
      _.each(groups, function(detail, key) {

        var detailsObj = {};
        detailsObj['Name'] = $filter('formatStatus')(key.capitalize());
        detailsObj['Percent'] = getTotalPercentageForDetail(detail);

        detailsObj.children = [];

        // cash will always have one detail
        if (key == 'cash') detail = detail[0];

        if (detail != null && detail.length) {

          detail.forEach(function(item) {
            var obj = {};
            obj['Name'] = item.target.type == 'symbol' ? item.target.ticker : item.target.name;
            obj['Percent'] = item.percent;
            if (key == 'targets') {
              obj['id'] = item.id;
              obj['originalTarget'] = item;
            }
            obj.icons = {
              iconLeaf: 'glyphicon'
            };
            obj.children = [];

            if (item.details) {
              addDetails(item, obj);
            }
            detailsObj.children.push(obj);
          });

          parentObj.children.push(detailsObj);

        } else {
          if (detail && detail.percent) {
            var obj = {
              "Name": key.capitalize(),
              "Percent": detail.percent
            };

            if (key == 'cash') {
              obj.icons = {
                iconLeaf: 'glyphicon glyphicon-usd'
              };
            }
            parentObj.children.push(obj);
          }
        }

      });

      return parentObj.children;
    }

    function loadDefaultTargetDetails(targetId) {

      if ($scope.selectedAccount && $scope.selectedAccount.internalType === 'sma') {
        $scope.group_data = []; // this still needs to be set for the quick assign section to be shown
        return;
      } else {
        $scope.loadingTargets = true;

        Promise.all([TargetWeightFactory.getTargetById(targetId), TargetWeightFactory.getTargetDetailByGUID(targetId)])
          .then(function(response) {

            var target = response[0].data.data;
            var targetDetails = response[1].data;

            $scope.defaultTarget = target;
            $scope.group_data = [];
            target.details = targetDetails;

            // console.log("Default target details: ", response);

            // if (!target.details.length){
            //   throw new Error("No target details found");
            // }

            if (target && target.details != undefined && target.details.length){

              var tree_data = [];

              var flattenedDetails = TargetWeightFactory.flattenDetails(angular.copy(target.details));            

              if (flattenedDetails) {
                // group by detail type and order each group by percent weight
                var groups = _.chain(flattenedDetails)
                              .groupBy(function(detail){ return detail.target.type; })
                              .mapObject(function(group){
                                return _.sortBy(group, function(item) {
                                  return -item.percent;
                                });
                              })
                              .value();

                if (groups.symbol) {
                  var symbols = _.chain(groups.symbol)
                                  .groupBy(function(symbol){
                                    return symbol.target.ticker;
                                  })
                                  .mapObject(function(symbols){
                                    symbols = _.reduce(symbols, function(total, symbol){
                                      if (total.percent){
                                        total.percent += symbol.percent;
                                        return total;
                                      } else {
                                        total = symbol;
                                        return total;
                                      }
                                    }, {});

                                    return symbols;
                                  })
                                  .value();
                  
                  // return to array format
                  groups.symbol = _.values(symbols);
                }

                if (groups.model){
                  var models = _.chain(groups.model)
                                  .groupBy(function(model){
                                    return model.target.name;
                                  })
                                  .mapObject(function(models){
                                    models = _.reduce(models, function(total, model){
                                      if (total.percent){
                                        total.percent += model.percent;
                                        return total;
                                      } else {
                                        total = model;
                                        return total;
                                      }
                                    }, {});

                                    return models;
                                  })
                                  .value();
                  
                  // return to array format
                  groups.model = _.values(models);
                }

                var modelTotal = _.reduce(groups.model, function(total, entry){ return total += entry.percent; }, 0);
                var symbolTotal = _.reduce(groups.symbol, function(total, entry){ return total += entry.percent; }, 0);
                var targetsTotal = _.reduce(groups.targetWeightGroup, function(total, entry){ 
                  return total += entry.percent; 
                }, 0);
                
                var totalAllocated = Number(targetsTotal.toPrecision(14)) + Number(modelTotal.toPrecision(14)) + Number(symbolTotal.toPrecision(14));

                var cashTotal = 1 - ( totalAllocated ) >= 0 
                                ? 1 - ( totalAllocated ) 
                                : 0;

                if (groups.cash) groups.cash[0].percent = cashTotal;

                $scope.group_data = [].concat(groups.model, groups.symbol, groups.targetWeightGroup, groups.cash ? groups.cash[0] : groups.cash).filter(function(group){ return angular.isDefined(group);});
                // console.log($scope.group_data)

                var cash = $scope.group_data.filter(function(elem, i, array) {
                  return elem.target.type.toLowerCase() == 'cash';
                });
                var oldIdx = $scope.group_data.indexOf(cash[0]);
                $scope.group_data.move(oldIdx, $scope.group_data.length - 1);

                var topLevelDetails = addDetails(target, {
                  children: []
                });

                // place cash at the end
                var cashDetailIndex = _.findIndex(topLevelDetails, function(item) { return item.Name == 'Cash'; });
                if (cashDetailIndex !== -1) {
                  topLevelDetails.move(cashDetailIndex, topLevelDetails.length - 1);
                }

                topLevelDetails.forEach(function(detail) {
                  tree_data.push(detail);
                });
              }

              $scope.tree_data = tree_data;
            }
          })
          .catch(function(err) {
            // console.error(err);
            // toastr.error(err.message);
            Dashboard.toastError(err.message, err);
          })
          .finally(function(){
            $scope.loadingTargets = false;
            $scope.$apply();
          });
      }
    }

    function getTotalPercentageForDetail(items) {

      if (items && items.length) {
        var total = items.reduce(function(totPercent, item) {
          return totPercent += item.percent;
        }, 0);

        return total.toFixed(3) * 1;
      } else {
        return null;
      }
    }

    function loadAccountById(guid) {

      // accountAPI = (typeof accountAPI !== 'undefined') ? accountAPI : SmartXFactory.getAccountAPI();

      accountAPI.getAccountById(guid)
        .then(function(res) {

          $scope.selectedAccount.shouldRebalance = res.data.shouldRebalance;
          loadAccountStatisticsForAccountId(guid, res.data);
          getAdvisoryFee($scope.selectedAccount.id);

        })
        .catch(function(err){
          Dashboard.toastError(err.message, err);
          $scope.loading = false;
          $scope.errorLoadingAccount = true;
          $scope.refreshing = false;
          $scope.$apply();
        })
        .finally(function(){});
    }

    function loadAccountStatisticsForAccountId(guid, selectedAccount) {

      // accountAPI = (typeof accountAPI !== 'undefined') ? accountAPI : SmartXFactory.getAccountAPI();

      $scope.loadingAccountStatistics = true;

      accountAPI.getAccountStatisticsForId(guid)
        .then(function(res) {

          if (_.isEmpty(res)) {

            if (res.data.msg) {
              toastr.error('No account data.');
            } else {
              toastr.error("An unexpected error occurred.");
            }

            console.error(res);
            $scope.errorLoadingAccount = true;
            return false;
          }

          $scope.errorLoadingAccount = false;

          $scope.selectedAccount = _.extend(selectedAccount, res.data);
          $scope.lastUpdate = Date.now();

          $scope.$parent.tab.title = $filter('sanitizeSmartXAccountName')($scope.selectedAccount.name, 'name');

          if (_.isEmpty($scope.selectedAccount.intradayPerformance)) {
            $scope.noIntradayPerformance = true;
          } else {
            $scope.noIntradayPerformance = false;
            $scope.$broadcast('accountLoadedWithAllocations', $scope.selectedAccount.intradayPerformance);
          }

          if ($scope.selectedAccount.status == 'active') loadAccountAllocationsForAccountId(guid);
          else {
            $scope.loading = $scope.refreshing = false;
          }
        })
        .catch(function(err) {
          
          Dashboard.toastError('Failed to load statistics', err);
          $scope.loading = false;
          $scope.errorLoadingAccount = true;
          $scope.refreshing = false;
        })
        .finally(function(){
          $scope.loadingAccountStatistics = false;
          $scope.$apply();
        });
    }

    function getProtectedAlloc(allocations){
      return _.find(allocations, function(allocation) { return allocation.type === 'protected';});
    }

    function canCancelPendingACR (accountPermission, allocation) {
      return accountPermission == 'fullAccess' 
              || (accountPermission == 'accountMaintenance' && allocation.changeRequestType == 'modify');
    }
    
    function getAccountProgramFees(params) {
      return new Promise(function(resolve) {
        Billing.getAccountProgramFees(params)
          .then(function(res) {
            resolve(res.data.data);
          })
          .catch(function(err) {
            console.log(err);
            resolve(null);
          });
      });
    }
    
    function getSmaAlloc (allocations) {
      return _.find(allocations, function(allocation) {
        return allocation.type === 'sma' && allocation.status === 'open';
      });
    }

    function loadAccountAllocationsForAccountId(guid) {

      allocationAPI.requests.get(undefined, {accountId: $scope.selectedAccount.id, requestStatus: "pending"})
      .then(function(res){
        try{
          $scope.pendingAllocationRequests = res.data.data.map(function(request){
            request.canCancel = canCancelPendingACR($scope.selectedAccount.permission, request);
            return request;
          });
        } catch (e) {
          console.error(e);
          $scope.pendingAllocationRequests = [];
        }      })
      .catch(function(err){
        Dashboard.toastError(err.message, err);
      });

      $q.when(getAccountProgramFees({accountIds: [guid], pageSize: 100, pageNumber: 1}))
      .then(function(programFees) {
        $q.when(allocationAPI.getInflatedAllocations({accountId: guid, status: 'open,close'}))
        .then(function(res) {
          if(programFees) {
            res.data = res.data.map(function(data) {
             var programFee = programFees.find(function(programFee) {
              return programFee.sleeveId == data.id;
             });
             if(programFee) {
              data.programFee = programFee.programFee;
             }
             return data;
            });
          }                              
          var allocations = formatAllocationsList($scope.selectedAccount, res.data);

          $scope.accessAllocations = [];
          $scope.selectedAccount.allocations = allocations;
          $scope.accessAllocations = allocations;
          $scope.noAllocations = false;

          $scope.defaultAllocation = getDefaultAlloc($scope.selectedAccount.allocations);
          $scope.protectedAllocation = getProtectedAlloc($scope.selectedAccount.allocations);
          $scope.smaAllocation = getSmaAlloc($scope.selectedAccount.allocations);

          var isApm = $scope.selectedAccount.isApm;
          var isSma = $scope.selectedAccount.internalType === 'sma';

          $scope.hasActiveAllocations = $scope.selectedAccount.allocations ? $scope.selectedAccount.allocations.filter(function (item) {

            // sma accounts will have isApm set to 'false' but we want the sleeve to appear in the UI
            if (item.type == 'account' || item.type == 'managedPortfolio') return (isApm || isSma);
            else if (item.type == 'sma' && item.status == 'open') return true;
            else return item.status == 'open' && item.statistics.value > 0;     
          }).length : 0;

          if ($scope.hasActiveAllocations) {

            $scope.noAllocations = false;
            $scope.isNewAccount = false;

            _.each($scope.accessAllocations, function(allocation, _guid) {
              allocation.guid = _guid;
              $scope.accessModelsAllocatedTo.push(allocation);
              $scope.models = $scope.accessModelsAllocatedTo;
            });

            var accountValue = _.property(['statistics', 'value'])($scope.selectedAccount);

            // calculate the weights
            if ($scope.selectedAccount.allocations && $scope.selectedAccount.allocations[0].statistics){
              $scope.selectedAccount.allocations = $scope.selectedAccount.allocations.map(function(allocation){
                var allocationValue = allocation.statistics.value;
                allocation.weight = accountValue ? (allocationValue / accountValue) * 100 : '--';

                return allocation;
              });
            }            
          }

          if (!$scope.hasActiveAllocations && !$scope.hasPendingAllocations) { 
            $scope.noAllocations = true;
          }

          if (isApm){
            // PortfolioEditorFactory.getPortfolio(guid)
            // .then(function(res){
            //   console.log("APM Holdings: ", res.data.data)
            //   $scope.apmPositions = res.data.data;
            // })
            // .catch(function(err){
            //   console.error(err.message);
            //   // toastr.error("Error getting APM positions")
            // })

            // TODO: Get APM Holding
          }
        })
        .catch(function(err) {
          $scope.errorLoadingAccount = true;
          Dashboard.toastError(err.message, err);
        })
        .finally(function(){
          $timeout(function() {
            $scope.loading = false;
            $scope.refreshing = false;
          });
        });
      });
    }

    function formatAllocationsList(account, allocations){
      var grouped = _.groupBy(allocations, 'type');
      var types = ['funding', 'protected', 'account', 'transition', 'managedPortfolio', 'sma', 'model'];
      return _.reduce(types, function(list, type) {
        return grouped[type] ? list.concat(grouped[type]) : list;
      }, []);
    }

    function getAdvisoryFee(account) {
        if (!FeatureFlags.isEnabled('billing')) return;
        $scope.advisoryFeeLoading = true;

        $q.when(Billing.getAccountEffectiveFee({accountId: account, feeType: 'advisoryFee'}))
          .then(function(res) {
            if (!res.data) {
              return;
            }
            if (res.data.effectiveFees) {
              var effectiveTierData = res.data.effectiveFees[0].effectiveFee || undefined;
              if (effectiveTierData && effectiveTierData.billingTierType === "percent") {
                $scope.averageFeePercent = effectiveTierData.rateOrAmount;
              }
              $scope.billingType = billingTypeMapping[res.data.billingType] || res.data.billingType;
              $scope.endOfDate = res.data.aumEndDate;
            }
          })
          .catch(function(err) {
            Dashboard.toastError(err.message, err);
          })
          .finally(function() {
            $scope.advisoryFeeLoading = false;
          });       
    }

    init();

    // fetch all data for the selected account
    function init() {

      $scope.loading = true;
      $scope.errorLoadingAccount = false;
      $scope.lastUpdate = Date.now();

      if ($scope.tab.selectedAccountGuid && !$scope.tab.selectedAccount){

        // load the account by id, set selected account, and re-init
        $q.when(accountAPI.getAccountById($scope.tab.selectedAccountGuid))
        .then(function(res){
          $scope.tab.selectedAccount = res.data;
          
          // so we don't enter this path during the re-init
          delete $scope.tab.selectedAccountGuid;
          init();
        })
        .catch(function(err){
          Dashboard.toastError(err.message, err);
          $scope.loading = false;
          $scope.errorLoadingAccount = true;
          $scope.refreshing = false;
        });
      } else if ($scope.tab.jumptoAccountNumber) {

        $q.when(SmartXFactory.getAccountsWithMetadata({
          acctNumber: $scope.tab.jumptoAccountNumber
        }))
        .then(function(res){
          var account = res.accounts ? res.accounts[0] : null;

          if (!account) {
            toastr.error('Account not found');
            $scope.loading = false;
          } else {
            $scope.tab.selectedAccount = account;
          
            // so we don't enter this path during the re-init
            delete $scope.tab.jumptoAccountNumber;
            init();
          }
        })
        .catch(function(err){
          Dashboard.toastError(err.message, err);
          $scope.loading = false;
          $scope.errorLoadingAccount = true;
          $scope.refreshing = false;
        });

      } else {

        if ($scope.tab.selectedAccountGuid) {
          var selectedAccountGuid = $scope.tab.selectedAccountGuid;
          var tabAccountData = $scope.tab.data;
          $scope.selectedAccount = tabAccountData;/*  || $scope.accessAccounts.filter(function(item) {
            return item.id === selectedAccountGuid;
          }); */
          
          if (!angular.isDefined($scope.selectedAccount)) {
            getPendingAccount(selectedAccountGuid);
            return;
          }
          // might need this so that it doesn't load this one from local storage or can check for last selected before this block
          delete $scope.tab.selectedAccountGuid; //$scope.tab.selectedAccountIndex;
        } else {
          
          $scope.selectedAccount = $scope.tab.selectedAccount || $scope.tab.data;
          
          //$scope.selectedAccount = $scope.selectedAccount.length ? $scope.selectedAccount[0] : undefined;

        }

        if (!$scope.selectedAccount) {
          $scope.loading = false;
          toastr.error('No Account Set');
          return;
        }

        if ($scope.selectedAccount && (!$scope.selectedAccount.statistics || ($scope.selectedAccount.statistics && $scope.selectedAccount.statistics.returns.today === 0))) {
          loadAccountStatisticsForAccountId($scope.selectedAccount.id, $scope.selectedAccount);
        } 
        
        if ($scope.selectedAccount && $scope.selectedAccount.targetWeightId) loadDefaultTargetDetails($scope.selectedAccount.targetWeightId);
        
        // loads data for accountAllocationsTable
        loadAccountAllocationsForAccountId($scope.selectedAccount.id);
        getAccountExclusions($scope.selectedAccount.id);
        getAdvisoryFee($scope.selectedAccount.id);

        $scope.loading = true; // gets set to false in ChartCtrl after the chart data is loaded
        $scope.errorLoadingAccount = false;

        Dashboard.setActiveAccount($scope.selectedAccount);
      }
    }

    // function getAccountsFromFactory () {
    //   return new Promise(function(resolve, reject) {
    //     if(typeof SmartXFactory.getToken() !== 'undefined') {

    //       SmartXFactory.getAccounts('active')
    //       .then(function (resp) {
    //         $scope.accessAccounts = resp;

    //         // populate typeahead items
    //         angular.forEach($scope.accessAccounts, function(account){
    //             try {
    //                 $scope.th_accounts.push(account.name);
    //             } catch (e){
    //                 console.error("Missing information for model with guid: ", account.id);
    //             }
    //         });

    //         $rootScope.$broadcast('HasActiveAccount', $scope.accessAccounts.length);
    //         resolve(resp);
    //       }, function(err) {
		// 				console.error(err.message);
		// 				reject(Error(err.message));
    //       });

    //     }else{
    //       reject(Error('No Access Token'));
    //     }
  	// 	});

    // }

    function getPendingAccount(guid) {

      SmartXFactory.getAccounts('pending')
      .then(function (resp) {
        $scope.selectedAccount = resp.filter(function (item) { return item.id === guid; });
        $scope.selectedAccount = $scope.selectedAccount.length ? $scope.selectedAccount[0] : undefined;
        $scope.lastUpdate = Date.now();
        loadAccountStatisticsForAccountId(guid, $scope.selectedAccount);
        Dashboard.setActiveAccount($scope.selectedAccount);

      }, function(err) {
        console.error(err.message);
      });
    }

    function resetEditMode () {
      $scope.currentText = '';
      $scope.accountEditMode = false;
    }  

    Object.size = function(obj) {
      var size = 0,
        key;
      for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
      }
      return size;
    };

    function addDataToAllocations(allocations, additionalData) {      
      // find the items in the response that match up to the account allocations
      var allocationsWithData = allocations.map(function(allocation){
        var holdings = _.find(additionalData, function(item) {
          return item.ownerId && (item.ownerId === allocation.id);
        });

        var performance = _.find(additionalData, function(item) {
          return item.allocation && (item.allocation.id === allocation.id);
        });

        allocation.totalInflows = 0;
        allocation.totalOutflows = 0;

        performance.performance.performance.forEach(function(entry){
          allocation.totalInflows += entry.summary.increaseByAmount;
          allocation.totalOutflows += entry.summary.decreaseByAmount;
        });

        if (holdings) holdings.positions = _.sortBy(holdings.positions, function(position){ return -position.value; });

        return _.extend(allocation, { 
          performanceStream: performance.performance.performance, 
          holdings: holdings
        });
      });

      return allocationsWithData;
    }

    function calculateCashFlows(direction, performanceStream){
      return _.reduce(performanceStream, function(total, period){
        return total += period.summary[direction + 'ByAmount'];
      }, 0);
    }

  })
  .controller('CashManager', function($scope, FeatureFlags, Dashboard, $timeout, SMX_API_URL){
    console.log($scope.tab.selectedAccountGuid);

    $scope.isEnabled = FeatureFlags.isEnabled;

    if ($scope.isEnabled('cmadefaultrules') === true) {
      Cookies.set('cma_default_rules', 'enabled');
    } else {
      Cookies.set('cma_default_rules', 'disabled');
    }

    if ($scope.tab.selectedAccountGuid) {
      Cookies.set('account_id', $scope.tab.selectedAccountGuid);
    }
    
    Cookies.set('cma_app_cookie', SmartX.Router.getToken());
    Cookies.set('client_api_url', SMX_API_URL);

    if (Drupal.settings.api_version) {
      Cookies.set('client_api_version', Drupal.settings.api_version);
    }

    if (Drupal.settings.ids_url) {
      Cookies.set('client_ids_url', Drupal.settings.ids_url);
    }
    
    console.log('api_url:', Drupal.settings.api_url);
    console.log('api_version:', Drupal.settings.api_version);
  })
  .filter('bytype', function() {

    // format allocation value based on allocation type
    return function(input, type) {

      if (type === 'AllocateFixedDollar') {
        return "$" + input;
      }

      if (type === 'RedeemPercentageOfFund') {
        return input + "%";
      }
    };
  })
  .filter('unsafe', function($sce) {
    return function(val) {
      return $sce.trustAsHtml(val);
    };
  })
  .filter('propsFilter', function() {
    return function(items, props) {
      var out = [];

      if (angular.isArray(items)) {
        items.forEach(function(item) {
          var itemMatches = false;

          var keys = Object.keys(props);
          for (var i = 0; i < keys.length; i++) {
            var prop = keys[i];
            var text = props[prop].toLowerCase();
            if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
              itemMatches = true;
              break;
            }
          }

          if (itemMatches) {
            out.push(item);
          }
        });
      } else {
        // Let the output be the input untouched
        out = items;
      }

      return out;
    };
  })
  .filter('orderObjectBy', function() {
    return function(input, attribute) {
      if (!angular.isObject(input)) return input;

      var array = [];
      for (var objectKey in input) {
        array.push(input[objectKey]);
      }

      array.sort(function(a, b) {
        a = parseInt(a[attribute]);
        b = parseInt(b[attribute]);
        return a - b;
      });
      return array;
    };
  })
  .filter('filled', function() {
    return function(models, model) {
      return angular.isDefined(model.pendingAllocations);
    };
  });


angular.module("LoaderCtrl", ["ngAnimate"])
  .controller('loader', function($scope) {
    $scope.showLoader = true;

    setTimeout(function() {
      $scope.showLoader = false;
    }, (2 * 1000));
  })
  .directive(
    "mAppLoading",
    function($animate) {
      // Return the directive configuration.
      return ({
        link: link,
        restrict: "C"
      });

      function link(scope, element, attributes) {
        $animate.leave(element.children().eq(1)).then(
          function cleanupAfterAnimation() {
            element.remove();
            scope = element = attributes = null;
          }
        );
      }
    }
  );
