// angular.module('AccountAllocations', [])
angular.module('Directives')
.directive('smartxAccountAllocationsTable', ['$templateCache', 'Tabs', 'Models', 'Dashboard', '$rootScope', '$uibModal', '$modal', 'FeatureFlags', function($templateCache, Tabs, Models, Dashboard, $rootScope, $uibModal, $modal, FeatureFlags){
	// Runs during compile
	return {
		restrict: 'E',
		scope: {
			selectedAccount: "=account",
			editorEnabled: "=editorEnabled"
		},
		template: $templateCache.get('accountAllocationsTable.tpl.html'),
		link: function($scope, iElm, iAttrs, controller) {

			$scope.isEnabled = FeatureFlags.isEnabled;
			$scope.sleeveFilter = function (sleeve){
				var isOpen = sleeve.status === 'open';
				var hasValue = sleeve.statistics && sleeve.statistics.value > 0;
				var isSmaSleeve = sleeve.type === 'sma';
				var isApmSleeve = sleeve.type === 'managedPortfolio';
				var isProtectedSleeve = sleeve.type === 'protected';
				var isSmaAccount = $scope.selectedAccount.internalType === 'sma';
				var isTransitionSleeve = sleeve.type === 'transition';

				if (!isOpen) return false;

				if (isSmaAccount) {
					if (sleeve.type === 'funding') return hasValue;
					else return isApmSleeve === false; // don't show managed portfolio sleeve in sma account
				} else {
					if (!hasValue) {
						// show the managed portfolio sleeve in UMAs even if its value is 0
						// BUG-2780: show protected sleeve even when it has no value
						return isApmSleeve || isProtectedSleeve; 
					} else {
						return true; // show every sleeve with a value if we're in a UMA account 
					}
				}				
			};

			// funding sleeve mock
			// $scope.selectedAccount.allocations.push({
			// 	type: 'funding',
			// 	statistics: {
			// 		returns: {
			// 			qtd: {
			// 				dollar: 0,
			// 				percent: 0
			// 			},
			// 			ytd: {
			// 				dollar: 0,
			// 				percent: 0
			// 			},
			// 			mtd: {
			// 				dollar: 0,
			// 				percent: 0
			// 			}
			// 		},
			// 		value: 10000
			// 	}
			// })
			// protected sleeve mock
			// $scope.selectedAccount.allocations.push({
			// 	type: 'protected',
			// 	statistics: {
			// 		returns: {
			// 			qtd: {
			// 				dollar: 0,
			// 				percent: 0
			// 			},
			// 			ytd: {
			// 				dollar: 0,
			// 				percent: 0
			// 			},
			// 			mtd: {
			// 				dollar: 0,
			// 				percent: 0
			// 			}
			// 		},
			// 		value: 10000
			// 	}
			// })

			$scope.getNameByType = getNameByType;
			$scope.canAllocate = canAllocate();
			$scope.canLiquidate = canLiquidate();

		  // cache for performance calls so that it doesn't make the request more than once for each allocation
			$scope.allocationPerformance = {};

			function getNameByType(type){
				switch(type){
					case 'account':
					case 'managedPortfolio':
						return 'Managed Portfolio';
					case 'protected':
						return 'Protected Positions';
					case 'funding':
						return 'Account Funding';
					case 'transition':
						return 'Transition Sleeve';
					default:
						return type;
				}
			}

			function canAllocate(){
				return _.includes(['fullAccess', 'accountMaintenance'], $scope.selectedAccount.permission);
			}

			function canLiquidate() {
				return _.includes(['fullAccess', 'accountMaintenance'], $scope.selectedAccount.permission);
			}
			
			$scope.showTransactionForm = function(action, allocation) {

				var modalInstance = $modal.open({
					animation: true,
					template: $templateCache.get('transactionForm.tpl.html'),
					resolve: {
						allocation: function() {
							return allocation;
						},
						action: function() {
							return action;
						},
						account: function () {
							return $scope.selectedAccount;
						}
					},
					controller: function($scope, allocation, action, account, $uibModalInstance, $timeout, $templateCache, $interpolate, SweetAlert) {

						$scope = _.extend($scope, {
							account: account,
							allocation: allocation,
							action: action,
							customLeverage: '',
							amount: '',
							matchModelLeverage: allocation.matchModelLeverage,
							leverage: allocation.leverage
						});

						$scope.setLeverage = function(leverage) {
							$scope.leverage = leverage;
						};

						$scope.setAmount = function(amount) {
							$scope.amount = amount;
						};

						$scope.ok = function () {

							$scope.loading = false;

							var data = {
								"action": action,
								"payload": {
									"amount": $scope.amount,
									"leverage": $scope.leverage,
									"trackManagerLeverage": $scope.matchModelLeverage,
								}
							};

							// $uibModalInstance.close(data);

							var req;

							switch (data.action) {
								case 'allocate':
									$scope.allocationData = {
										account: $scope.account.name,
										model: $scope.allocation.model.name,
										allocationAmount: data.payload.amount,
										leverageAmount: data.payload.leverage,
										theTime: Date.now(),
										commissionCost: 'N/A',
										numOfSymbols: undefined,
										trackingManagerLeverage: data.payload.trackManagerLeverage ? 'Yes' : 'No',
										description: 'N/A'
									};

									$scope.keyword = 'Invest';

									var confirmAllocation = $templateCache.get('PostNewAllocation.html');
			
									SweetAlert.swal({
											title: $interpolate(confirmAllocation)($scope.allocationData),
											text: "To confirm the purchase of " + $scope.allocationData.model + " type '<b>" + $scope.keyword + "</b>' into the box below",
											html: true,
											type: "input",
											showCancelButton: true,
											confirmButtonColor: "#8cb843",
											confirmButtonText: "Confirm",
											closeOnConfirm: false,
											closeOnCancel: true,
											showLoaderOnConfirm: true,
											allowOutsideClick: false,
											inputPlaceholder: ""
									}, function (inputValue) {
		
										if (inputValue === false) {
												jQuery('.sweet-alert').remove();
												return false;
										}
										if (inputValue !== $scope.keyword) {
												swal.showInputError("Input does not match. Type in '" + $scope.keyword + "' to complete this allocation.");
												return false;
										}

										new SmartX.AllocationApi().increase(allocation.id, data.payload)
										.then(function(result){
											console.log("Result: ", result);
											var msg = "Allocation to " + $scope.allocationData.model + " has been submitted and is pending approval.";
											SweetAlert.swal({
												title: msg,
												type: "success"
											}, function(){
												$uibModalInstance.close(result.data);
											});
										})
										.catch(function(err){
											SweetAlert.swal({
												title: err.message,
												type: "warning"
											});
										})
										.finally(function(){
											$timeout(function(){
												$scope.loading = false;
											});
										});
									});

									break;
								case 'liquidate':

									data.payload.amount = data.payload.amount / 100;

									SweetAlert.swal({
										title: "Are you sure you want to liquidate "+ (data.payload.amount * 100) +"% from "+ allocation.model.name +"?",
										text: "You will not be able to reverse this action.",
										type: "warning",
										showCancelButton: true,
										confirmButtonColor: "#DD6B55",
										confirmButtonText: "Yes",
										cancelButtonText: "No",
										closeOnConfirm: false,
										closeOnCancel: true,
										showLoaderOnConfirm: true,
										allowEscapeKey: true,
									}, function(isConfirm){
										if (isConfirm){
											new SmartX.AllocationApi().redeem(allocation.id, data.payload)
											.then(function(result){
												console.log("Result: ", result);
												SweetAlert.swal({
													title : (data.payload.amount * 100) +"% was liquidated from your allocation to " + allocation.model.name +".",
													type : "success"
												}, function(){
													$uibModalInstance.close(result.data);
												});
											})
											.catch(function(err){
												SweetAlert.swal({
													title: err.message,
													type: "warning"
												});
											})
											.finally(function(){
												$timeout(function(){
													$scope.loading = false;
												});
											});
										} else {
											jQuery('.sweet-alert').remove();
										}
										});
									break;
							}
						};
				
						$scope.cancel = function () {
								$uibModalInstance.dismiss('cancel');
						};
					}
				});

				modalInstance.result
				.then(function(data) {
					$scope.$emit('allocationRequestComplete', data);
				});
	
			};

			$scope.getAllocationPerformance = function(allocation) {

				var allocationId = allocation.id;
				var allocationAPI = new SmartX.AllocationApi();

				allocationAPI.getAllocationPerformancesById(allocationId)
				.then(function(res) {
				// debugger
				$scope.allocationPerformance[allocationId] = res.data || [];
				var response = res.data;
				var allocations = response.performance.performance;

				allocations = allocations.map(function(allocation) {

		          return {
		            pnlPercentage: allocation.pnl.percent,
		            pnl: allocation.pnl.dollar,
		            beginningMarketValue: allocation.summary.beginningMarketValue,
		            endingMarketValue: allocation.summary.endingMarketValue,
		            increaseByAmount: allocation.summary.increaseByAmount,
		            decreaseByAmount: allocation.summary.decreaseByAmount,
		            performanceDate: allocation.range.start.timestamp
		          };
		        });

				// $timeout(function(){
					$scope.allocationPerformance[allocationId] = allocations;
					$scope.$apply();
				// })
				})
				.catch(function(res) {
				console.log("Error getting allocation performance: ", res);
				allocation.expanded = !allocation.expanded;
				});
			};

			$scope.addTabForModel = function(model) {

		      if (typeof model != 'undefined') {
		        var id = model.reportedModelId || model.id;
		        console.log('addTabForModel', id);

				Models.currentModel = model;
				Models.currentModel.guid = model.reportedModelId;
				Tabs.addTab('model');

				} else {
				console.log('Allocation missing model');
				}
			};

			$scope.addTab = function(type, allocation, target) {

		      if (type == 'targetEdit') {
		        var tab = Tabs.addTab(type, null, null, target);
		      } else if (type == 'protected-positions' || type == 'allocation-holdings') {
						allocation.account = angular.copy($scope.selectedAccount);
						if (type == 'allocation-holdings'){
							var tabs = Tabs.getTabs();
							var tabForAccount = _.find(tabs, function(tab){
								if (tab.type == 'allocation-holdings'){
									var allocation = tab.data.allocation;
									if (allocation){
										var account = allocation.account;
										return account.id == $scope.selectedAccount.id;
									}
								}
								return false;
							});

							if (tabForAccount) {
								console.log(tabForAccount);
								Tabs.setActiveTab(tabForAccount);
								$rootScope.$broadcast('update-allocation-'+$scope.selectedAccount.id, allocation);
								return;
							}
						}
						var tab = Tabs.addTab(type, null, null, {allocation: allocation});
		        } else {
		        var tab = Tabs.addTab(type, $scope.selectedAccount.guid || $scope.selectedAccount.id, null, $scope.selectedAccount);

				}

				if (type == 'accountHistory') {
				Dashboard.setActiveAccount(this.selectedAccount);
				Dashboard.currentAccount = Dashboard.getActiveAccount();
				}



				if (type === 'holdings') {

				Dashboard.setActiveAccount(this.selectedAccount);
				Dashboard.currentAccount = Dashboard.getActiveAccount();

				Dashboard.getObserverCallbacks();
				Dashboard.notifyObservers('main-holdings');
				return;

				} else if (type === 'liquidate') {
				//console.log(this);
				Dashboard.selectedLiquidationModel = allocation;
				Dashboard.selectedLiquidationAccount = $scope.selectedAccount;
				Dashboard.notifyObservers('main-liquidate', $scope.models);

				} else if (type === 'allocate') {


				// debugger;
				Dashboard.selectedAllocationModel = allocation.model || $scope.allocation.information.modelId;
				Dashboard.selectedAllocationAccount = $scope.selectedAccount;

				Dashboard.notifyObservers('fromMain');

				} else if (type === 'search') {
				if (tab.exists)
					$rootScope.$broadcast('tabSwitched::search', tab);
				}

			};
		}
	};
}]);