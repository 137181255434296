angular
	.module('TaxTransitionController', [])
	.controller('TaxTransitionController', function ($scope, Tabs, Models, IdentityFactory, SmartXFactory, FeatureFlags) {
		const activeTab = Tabs.getActiveTab()
		$scope.taxtransitionviewadvisortargetsenabled = FeatureFlags.isEnabled('taxtransitionviewadvisortargetsenabled')
		$scope.taxtransitionaddtotargetenabled = FeatureFlags.isEnabled('taxtransitionaddtotargetenabled')
		$scope.taxtransitionfindmodelsenabled = FeatureFlags.isEnabled('taxtransitionfindmodelsenabled')

		var performanceApi = SmartXFactory.getPerformanceAPI()
		$scope.platformId = performanceApi.platform

		$scope.$on('smx-token-updated', function (e, token) {
			$scope.smartx_token = token
		})

		IdentityFactory.getUser().then(function (res) {
			$scope.smartx_user_guid = res.profile.smartx_user_guid
			$scope.smartx_token = res.access_token
		})

		document.addEventListener('_tax-transition-timeline-cancel', function () {
			console.log('tax transition timeline cancel')
			const transitionTab = Tabs.getTabs().find(tab => tab.type === 'taxTransition')
			console.log('transitionTab', transitionTab)
			Tabs.removeTab(transitionTab.index)
			$scope.$apply()
		})
		document.addEventListener('_AssetShopOpenModelInfo', function (event) {
			Models.currentModel = event.detail.backup
			tab = Tabs.addTab('model')
			if (tab) {
				$scope.$apply()
				$scope.$parent.$broadcast('tabSwitched::' + tab.type, tab)
			}
		})
	})
